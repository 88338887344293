<template>
    <div class="hidden">
    </div>
</template>

<script>
export default {
  props: ['jobListingId', 'userAppliedJob'],
  mounted() {
    this.trackView();
  },
  methods: {
    trackView: function() {
      const _this = this;
      // For production (SSL)
      $.getJSON( 'https://ipapi.co/json/' )
          .then(
              function success( response ) {
                // console.log( response );
                let appliedJob = '';
                if ( _this.userAppliedJob ) {
                  appliedJob = _this.userAppliedJob;
                }
                axios.post( '/api/track_listing_view', {
                  params: {
                    data: response,
                    id: _this.jobListingId,
                    userAppliedJob: appliedJob,
                  },
                } )
                    .then( ( response ) => {
                      // console.log( response );
                    } ).catch( ( error ) => {
                      // console.log( error );
                    } );
              },
              function fail( data, status ) {
                console.log( 'Request failed.  Returned status of', status );
              }
          );
    },
  },
};
</script>
