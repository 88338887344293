<template>
    <div>
      <div class="find-jobs__filter_checkbox_group_label">Industry</div>
      <div class="find-jobs__filter_checkbox_group">
        <div class="" v-for="(industryFilter, industryFilterIndex) in industries" :key="industryFilterIndex">
          <input v-model="industry" :id="'industry_'+industryFilterIndex" :name="'industry_'+industryFilterIndex" :value="industryFilter.id" type="checkbox"/>
          <label :for="'industry_'+industryFilterIndex">{{industryFilter.name}}</label>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['industries'],
  data() {
    return {
      industry: [],
      year: new Date().getFullYear(),
    };
  },
  mounted() {

  },
  methods: {

  },
  watch: {
    industry( ) {
      this.$emit( 'update', this.industry );
    },
  },
};
</script>
