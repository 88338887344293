<template>
    <div>
      <div class="find-jobs__filter_checkbox_group_label">Listing Company</div>
      <div class="find-jobs__filter_checkbox_group">
        <div class="w-full" v-for="(uniqueCompany, uniqueCompanyIndex) in uniqueJobListings" :key="uniqueCompanyIndex.company">
          <input v-model="companies" :id="'companies_'+uniqueCompanyIndex" :name="'companies_'+uniqueCompanyIndex" :value="uniqueCompany.company_id" type="checkbox"/>
          <label :for="'companies_'+uniqueCompanyIndex">{{uniqueCompany.company_name}}</label>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['activeJobListings', 'companyNames'],
  data() {
    return {
      year: new Date().getFullYear(),
      companies: [],
    };
  },
  mounted() {

  },
  computed: {
    uniqueJobListings() {
      return _.uniqBy( this.companyNames, 'company_name' );
    },
  },
  methods: {

  },
  watch: {
    companies( ) {
      this.$emit( 'update', this.companies );
    },
  },
};
</script>
