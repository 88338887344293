/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require( './bootstrap' );

window.Vue = require( 'vue' );
window.Chart = require( 'chart.js' );

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

Vue.component( 'wysiwyg', require( './components/partials/Wysiwyg.vue' ).default );

Vue.component( 'billing-setup', require( './components/account/BillingSetup.vue' ).default );
Vue.component( 'trial-setup', require( './components/account/TrialSetup.vue' ).default );
Vue.component( 'user-personal-information', require( './components/account/PersonalInformation.vue' ).default );
Vue.component( 'user-subscription-billing', require( './components/account/SubscriptionBilling.vue' ).default );
Vue.component( 'user-password', require( './components/account/Password.vue' ).default );

Vue.component( 'profile-education', require( './components/profile/partials/Education.vue' ).default );
Vue.component( 'profile-instructions', require( './components/profile/partials/FirstTimePopup.vue' ).default );
Vue.component( 'profile-job-preferences', require( './components/profile/partials/JobPreferences.vue' ).default );
Vue.component( 'edit-profile', require( './components/profile/EditProfile.vue' ).default );
Vue.component( 'profile-work-experience', require( './components/profile/partials/WorkExperience.vue' ).default );
Vue.component( 'profile-values', require( './components/profile/partials/Values.vue' ).default );
Vue.component( 'profile-social', require( './components/profile/partials/Social.vue' ).default );
Vue.component( 'photo', require( './components/profile/partials/Photo.vue' ).default );
Vue.component( 'profile-company-details', require( './components/profile/partials/CompanyDetails.vue' ).default );
Vue.component( 'profile-company-videos', require( './components/profile/partials/CompanyVideos.vue' ).default );

Vue.component( 'my-resumes', require( './components/resumes/MyResumes.vue' ).default );
Vue.component( 'my-active-resumes', require( './components/resumes/MyActiveResumes.vue' ).default );
Vue.component( 'resume-create-or-edit', require( './components/resumes/CreateOrEdit.vue' ).default );
Vue.component( 'view-tracker', require( './components/resumes/ViewTracker.vue' ).default );
Vue.component( 'share-resume', require( './components/resumes/Share.vue' ).default );
Vue.component( 'resume-social', require( './components/resumes/partials/Social.vue' ).default );
Vue.component( 'resume-values', require( './components/resumes/partials/Values.vue' ).default );
Vue.component( 'resume-attachment', require( './components/resumes/partials/Attachment.vue' ).default );
Vue.component( 'resume-summary', require( './components/resumes/partials/Summary.vue' ).default );
Vue.component( 'resume-work-experience', require( './components/resumes/partials/WorkExperience.vue' ).default );
Vue.component( 'resume-education', require( './components/resumes/partials/Education.vue' ).default );
Vue.component( 'resume-additional', require( './components/resumes/partials/Additional.vue' ).default );
Vue.component( 'resume-portfolio', require( './components/resumes/partials/Portfolio.vue' ).default );
Vue.component( 'resume-video', require( './components/resumes/partials/Video.vue' ).default );

Vue.component( 'chart', require( './components/Chart.vue' ).default );

Vue.component( 'analytics', require( './components/analytics/Analytics.vue' ).default );

Vue.component( 'my-cover-letters', require( './components/cover_letters/MyCoverLetters.vue' ).default );
Vue.component( 'cover-letter-create-or-edit', require( './components/cover_letters/CreateOrEdit.vue' ).default );
Vue.component( 'preview-cover-letter', require( './components/cover_letters/partials/Preview.vue' ).default );

Vue.component( 'my-jobs', require( './components/job_listings/MyJobs.vue' ).default );
Vue.component( 'active-job-listings', require( './components/job_listings/ActiveJobListings.vue' ).default );
Vue.component( 'job-listing-create-or-edit', require( './components/job_listings/CreateOrEdit.vue' ).default );
Vue.component( 'job-listing-details', require( './components/job_listings/partials/Details.vue' ).default );
Vue.component( 'job-listing-benefits', require( './components/job_listings/partials/Benefits.vue' ).default );
Vue.component( 'job-listing-videos', require( './components/job_listings/partials/Video.vue' ).default );
Vue.component( 'job-listing-pre-interview-questions', require( './components/job_listings/partials/PreInterviewQuestions.vue' ).default );
Vue.component( 'job-listing-next-steps-email', require( './components/job_listings/partials/NextStepsEmail.vue' ).default );

Vue.component( 'search-filter-company', require( './components/job_listings/partials/search_filter/Company.vue' ).default );
Vue.component( 'search-filter-industry', require( './components/job_listings/partials/search_filter/Industry.vue' ).default );
Vue.component( 'search-filter-job-type', require( './components/job_listings/partials/search_filter/JobType.vue' ).default );
Vue.component( 'search-filter-job-level', require( './components/job_listings/partials/search_filter/JobLevel.vue' ).default );
Vue.component( 'search-filter-salary-range', require( './components/job_listings/partials/search_filter/Salary.vue' ).default );
Vue.component( 'search-filter-location', require( './components/job_listings/partials/search_filter/Location.vue' ).default );
Vue.component( 'search-filter-willing-to-relocate', require( './components/job_listings/partials/search_filter/WillingToRelocate.vue' ).default );

Vue.component( 'user-job-listings', require( './components/job_listings/UserJobListings.vue' ).default );

Vue.component( 'my-applications', require( './components/applications/MyApplications.vue' ).default );
Vue.component( 'apply-now', require( './components/applications/ApplyNow.vue' ).default );
Vue.component( 'submissions', require( './components/job_listings/Submissions.vue' ).default );

Vue.component( 'match-maker', require( './components/job_listings/MatchMaker.vue' ).default );
Vue.component( 'find-candidates', require( './components/candidates/Candidates.vue' ).default );
Vue.component( 'listing-view-tracker', require( './components/job_listings/ViewTracker.vue' ).default );

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue( {
  el: '#app',
} );

$( document ).ready( function() {
  function rotateImages() {
    let index = 0;
    setInterval( function() {
      const urls = ['#', 'https://www.stjosephantiques.com/'];
      const images = ['/img/renew-crew-horiz-ad.png', '/img/The-Music-Exchange-Banner-Ad.png'];
      document.getElementById( 'ad-image' ).src = images[index];
      document.getElementById( 'ad-link' ).href = urls[index];
      index++;
      if ( index >= images.length ) {
        index = 0;
      }
    }, 30000 );
  }
  if ( ( document.getElementById( 'ad-image' ) !== null && document.getElementById( 'ad-image' ) !== undefined ) ) {
    rotateImages();
  }

  $( '.trigger-login' ).on( 'click', function( e ) {
    $( '.login-btn' ).click();
  } );
  const searchParams = new URLSearchParams( window.location.search );
  if ( searchParams.get( 'mode' ) === 'applicant' ) {
    $( '#role-applicant' ).click();
  }
  if ( searchParams.get( 'mode' ) === 'employer' ) {
    $( '#role-employer' ).click();
  }

  const registerForm = document.querySelector( 'form.registration' );
  const companyName = document.querySelector( '#company_name' );
  const firstName = document.querySelector( '#first_name' );
  const lastName = document.querySelector( '#last_name' );

  if ( registerForm ) {
    const url = new URL( window.location.href );

    if ( $( 'input[name=user_role]' ).val() == 'applicant' ) {
      $( '.employer-form-group' ).hide();
      $( '.applicant-form-group' ).show();
    } else {
      $( '.applicant-form-group' ).hide();
      $( '.employer-form-group' ).show();
    }

    $( registerForm ).on( 'change', function( e ) {
      if ( e.target.value === 'applicant' ) {
        $( registerForm ).trigger( 'reset' );
        $( '.employer-form-group' ).hide();
        $( '.applicant-form-group' ).show();
        url.searchParams.delete( 'r' );
      } else if ( e.target.value === 'employer' ) {
        $( '.employer-form-group' ).show();
        $( '.applicant-form-group' ).hide();
        url.searchParams.set( 'r', 'employer' );
      } else {
        // $( '.employer-form-group' ).hide();
      }
      window.history.replaceState( null, null, url ); // or pushState
    } );

    $( companyName ).on( 'blur', function( e ) {
      firstName.value = e.target.value;
      lastName.value = 'emp';
    } );
  }


  $( document ).on( 'click', '.js-init-popup', function( e ) {
    e.preventDefault();
    const popupId = $( this ).data( 'modal' );

    if ( popupId !== '' ) {
      $.magnificPopup.open( {
        items: {
          src: popupId,
        },
        type: 'inline',
        fixedContentPos: true,
        fixedBgPos: true,
        overflowY: 'auto',
        closeBtnInside: true,
        showCloseBtn: false,
        preloader: false,
        midClick: true,
        removalDelay: 300,
      }, 0 );
    }
  } );

  if ( $( '.popup-gallery' ).length ) {
    $( '.popup-gallery' ).each( function() {
      $( this ).magnificPopup( {
        delegate: 'a',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
        },
        image: {
          tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
          titleSrc: function( item ) {
            return item.el.attr( 'title' ) + '<small>' + item.el.attr( 'caption' ) + '</small>';
          },
        },
      } );
    } );
  }

  $( '.js-close-popup' ).on( 'click', function() {
    $.magnificPopup.close();
  } );

  // Select all links with hashes
  $( 'a[href*="#"]' )
      // Remove links that don't actually link to anything
      .not( '[href="#"]' )
      .not( '[href="#0"]' )
      .click( function( event ) {
        // On-page links
        if (
          location.pathname.replace( /^\//, '' ) == this.pathname.replace( /^\//, '' )
          &&
          location.hostname == this.hostname
        ) {
          // Figure out element to scroll to
          let target = $( this.hash );
          target = target.length ? target : $( '[name=' + this.hash.slice( 1 ) + ']' );
          // Does a scroll target exist?
          if ( target.length ) {
            // Only prevent default if animation is actually gonna happen
            event.preventDefault();
            $( 'html, body' ).animate( {
              scrollTop: target.offset().top - $( 'header.header' ).height(),
            }, 1000 );
          }
        }
      } );

  $( '.form-group__input .icon-visibility' ).click( function() {
    if ( $( this ).hasClass( 'is-visible' ) ) {
      $( this ).removeClass( 'is-visible' );
      $( this ).parent().find( 'input' ).attr( 'type', 'password' );
    } else {
      $( this ).addClass( 'is-visible' );
      $( this ).parent().find( 'input' ).attr( 'type', 'text' );
    }
  } );

  $( '.bar' ).each( function( index, element ) {
    const value = parseInt( $( element ).data( 'value' ) );
    const max = parseInt( $( element ).data( 'max' ) );
    let height = value / max;
    height = height * 100;
    console.log( height );
    $( this ).find( 'span' ).css( 'height', height + '%' );
  } );

  window.toggleGridOverlay = function() {
    const template = `<div id="gridOverlay" class="fixed inset-0 z-50 opacity-25 pointer-events-none">
        <div class="container h-full">
          <div class="row h-full items-stretch">
            <div class="w-1/12 col">
              <div class="h-full" style="background-color: #fc8181;"></div>
            </div>
            <div class="w-1/12 col">
              <div class="h-full" style="background-color: #fc8181;"></div>
            </div>
            <div class="w-1/12 col">
              <div class="h-full" style="background-color: #fc8181;"></div>
            </div>
            <div class="w-1/12 col">
              <div class="h-full" style="background-color: #fc8181;"></div>
            </div>
            <div class="w-1/12 col">
              <div class="h-full" style="background-color: #fc8181;"></div>
            </div>
            <div class="w-1/12 col">
              <div class="h-full" style="background-color: #fc8181;"></div>
            </div>
            <div class="w-1/12 col">
              <div class="h-full" style="background-color: #fc8181;"></div>
            </div>
            <div class="w-1/12 col">
              <div class="h-full" style="background-color: #fc8181;"></div>
            </div>
            <div class="w-1/12 col">
              <div class="h-full" style="background-color: #fc8181;"></div>
            </div>
            <div class="w-1/12 col">
              <div class="h-full" style="background-color: #fc8181;"></div>
            </div>
            <div class="w-1/12 col">
              <div class="h-full" style="background-color: #fc8181;"></div>
            </div>
            <div class="w-1/12 col">
              <div class="h-full" style="background-color: #fc8181;"></div>
            </div>
          </div>
        </div>
      </div>`;
    if ( document.getElementById( 'gridOverlay' ) ) {
      document.getElementById( 'gridOverlay' ).remove();
    } else {
      document.body.insertAdjacentHTML( 'beforeend', template );
    }
  };
  const screenWidth = window.innerWidth;
  const currentUrl = window.location.href;
  const doNotShowAgain = document.cookie.includes( 'introjs-dontShowAgain=true' );

  if ( window.Common != undefined ) {
    if ( screenWidth <= 1024 && currentUrl === window.Common.url ) {
      if ( doNotShowAgain ) {
        console.log( 'cookie is set, do not need to show' );
      } else {
        $( 'button[name=mobile-menu' ).click();
        if ( window.App.user.user_role == 'applicant' ) {
          const introApp = introJs().setOptions( {
            steps: [{
              element: document.querySelector( '#app-mobile-1' ),
              title: 'Welcome to Top Applicant!',
              intro: 'Start by creating your <a href="/profile">profile</a>. Fill out every field to be matched with jobs youre qualified for.',
            },
            {
              title: 'Create Your Resumes',
              element: document.querySelector( '#app-mobile-2' ),
              intro: 'Create and perfect your <a class="underline text-blue" href="/resumes">digital resume</a> to share with employers.',
            },
            {
              title: 'Create Your Cover Letters',
              element: document.querySelector( '#app-mobile-3' ),
              intro: 'Next create your <a class="underline text-blue" href="/cover-letters">cover letter</a>. You can create multiple to make them custom to positions or industries you’re interested in.',
            },
            {
              title: 'Hunt for Jobs',
              element: document.querySelector( '#app-mobile-4' ),
              intro: 'Browse through our <a class="underline text-blue" href="/active-job-listings">job listings</a> and apply for positions or companies that fit you best.',
            }],
            dontShowAgain: true,
            scrollToElement: false,
          } ).start();
        }

        if ( window.App.user.user_role == 'employer' ) {
          const introApp = introJs().setOptions( {
            steps: [{
              element: document.querySelector( '#app-mobile-1' ),
              title: 'Welcome to Top Applicant!',
              intro: 'Start by creating your <a class="underline text-blue" href="/profile">profile</a>. Fill out every field to be matched with the best-qualified candidates.',
            },
            {
              title: 'List Your Jobs',
              element: document.querySelector( '#emp-mobile-2' ),
              intro: 'Create public <a class="underline text-blue" href="/job-listings">job listings</a> for the positions you\'re looking to fill.',
            },
            {
              title: 'Browse Candidates',
              element: document.querySelector( '#emp-mobile-3' ),
              intro: 'Browse through <a class="underline text-blue" href="/candidates">potential candidates</a> based on the job listings you\'ve created.',
            }],
            dontShowAgain: true,
            scrollToElement: false,
          } ).start();
        }
      }
    }

    if ( screenWidth >= 1024 && currentUrl === window.Common.url ) {
      if ( doNotShowAgain ) {
        console.log( 'cookie is set, do not need to show' );
      } else {
        if ( window.App.user.user_role == 'applicant' ) {
          const introApp = introJs().setOptions( {
            steps: [{
              element: document.querySelector( '#app-1' ),
              title: 'Welcome to Top Applicant!',
              intro: 'Start by creating your <a class="underline text-blue" href="/profile">profile</a>. Fill out every field to be matched with jobs youre qualified for.',
            },
            {
              title: 'Create Your Resumes',
              element: document.querySelector( '#app-2' ),
              intro: 'Create and perfect your <a class="underline text-blue" href="/resumes">digital resume</a> to share with employers.',
            },
            {
              title: 'Create Your Cover Letters',
              element: document.querySelector( '#app-3' ),
              intro: 'Next create your <a class="underline text-blue" href="/cover-letters">cover letter</a>. You can create multiple to make them custom to positions or industries you’re interested in.',
            },
            {
              title: 'Hunt for Jobs',
              element: document.querySelector( '#app-4' ),
              intro: 'Browse through our <a class="underline text-blue" href="/active-job-listings">job listings</a> and apply for positions or companies that fit you best.',
            }],
            dontShowAgain: true,
            scrollToElement: false,
          } ).start();
        }

        if ( window.App.user.user_role == 'employer' ) {
          const introApp = introJs().setOptions( {
            steps: [{
              element: document.querySelector( '#app-1' ),
              title: 'Welcome to Top Applicant!',
              intro: 'Start by creating your <a class="underline text-blue" href="/profile">profile</a>. Fill out every field to be matched with the best-qualified candidates.',
            },
            {
              title: 'List Your Jobs',
              element: document.querySelector( '#emp-2' ),
              intro: 'Create public <a class="underline text-blue" href="/job-listings">job listings</a> for the positions you\'re looking to fill.',
            },
            {
              title: 'Browse Candidates',
              element: document.querySelector( '#emp-3' ),
              intro: 'Browse through <a class="underline text-blue" href="/candidates">potential candidates</a> based on the job listings you\'ve created.',
            }],
            dontShowAgain: true,
            scrollToElement: false,
          } ).start();
        }
      }
    }
  }
  // introApp.onafterchange( function( targetElement ) {
  //   console.log( targetElement.id );
  //   switch ( targetElement.id ) {
  //     case 'b1':
  //       setTimeout( function() {
  //         const element = document.getElementsByClassName( 'introjs-tooltip' );
  //         const boxArrow = document.getElementsByClassName( 'introjs-arrow top' );
  //         const numberLayer = document.getElementsByClassName( 'introjs-helperNumberLayer' );
  //         element.item( 0 ).setAttribute( 'style', 'top:210px;' );
  //         boxArrow.item( 0 ).setAttribute( 'style', 'display: block' );
  //         numberLayer.item( 0 ).setAttribute( 'style', 'left: 0; top:0;' );
  //       }, 600 );
  //   }
  // } );
} );
