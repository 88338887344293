<template>
    <div>


        <div class="row mb-4 hidden md:flex">
            <div class=" active-resumes__table-col-company pl-8 w-3/12 my-auto">
                <span class="active-resumes__table-heading">Company</span>
            </div>
            <div class=" active-resumes__table-col-recipient w-3/12 my-auto">
                <span class="active-resumes__table-heading">Job Title</span>
            </div>
            <div class=" active-resumes__table-col-cover-letter pl-4 w-1/12 my-auto">
                <span class="active-resumes__table-heading">Date</span>
            </div>
            <div class=" active-resumes__table-col-date flex flex-col pl-16 w-3/12 my-auto">
                <span class="active-resumes__table-heading">Status</span>
            </div>
            <div class=" active-resumes__table-col-status w-2/12 my-auto">
                <span class="active-resumes__table-heading">View</span>
            </div>
        </div>

        <div v-for="(jobApplication, jobApplicationIndex) in jobApplications" :key="jobApplicationIndex"  class="block bg-white rounded-sm mb-3 py-4 hover:shadow-xxl">
            <div class="row hidden md:flex">
                <div class=" active-resumes__table-col-company pl-8 w-3/12 my-auto">
                    <span target="_blank" class="inline-block pl-5 text-xs text-gray-500 underline"><a :href="'/u/'+jobApplication.lister">{{jobApplication.company}}</a></span>
                </div>
                <div class=" active-resumes__table-col-recipient w-3/12 my-auto">
                    <a :href="'/apply-now/'+jobApplication.job_listing_id" class="inline-block text-xs text-gray-500 font-medium underline">{{jobApplication.listing_title}}</a>
                </div>
                <div class=" active-resumes__table-col-cover-letter pl-4 w-1/12 my-auto">
                    <span  class="text-xs font-medium text-gray-500 capitalize">Sent</span><br/>
                    <p class="inline-block text-xs text-gray-500 font-medium">{{ formatDate( jobApplication.created_at ) }}</p>
                </div>
                <div class=" active-resumes__table-col-date flex flex-col pl-16 w-3/12 my-auto">
                    <span class="text-xs font-medium text-gray-500">Most Recent View</span>
                    <span v-if="jobApplication.recent_view !== null" class="text-xs text-green">{{ formatDate( jobApplication.recent_view.created_at ) }}</span>
                    <span v-if="jobApplication.recent_view === null" class="text-xs text-error">Not Yet Viewed</span>
                </div>
                <div class=" active-resumes__table-col-status w-2/12 my-auto">
                    <a :href="'/view-application/'+ jobApplication.job_listing_id+'/'+jobApplication.user_id" class="text-xs text-gray-500 underline">View Submission</a>
                </div>
            </div>

            <div class="row md:hidden">
                <div class="col w-full">
                    <div class=" active-resumes__table-col-company w-full my-auto pl-5 flex flex-col mb-5">
                        <span class="active-resumes__table-heading">Company</span>
                        <a :href="'/applicant/'" target="_blank" class="inline-block text-xs text-gray-500 underline">{{jobApplication.company}}</a>
                    </div>
                    <div class=" active-resumes__table-col-recipient w-full my-auto pl-5 flex flex-col mb-5">
                        <span class="active-resumes__table-heading">Job Title</span>
                        <span class="inline-block text-xs text-gray-500 font-medium underline">{{jobApplication.listing_title}}</span>
                    </div>
                    <div class=" active-resumes__table-col-cover-letter -full my-auto pl-5 flex flex-col mb-5">
                        <span class="active-resumes__table-heading">Date</span>
                        <span  class="text-xs font-medium text-gray-500 capitalize">Sent</span>
                        <p class="inline-block text-xs text-gray-500 font-medium ">{{ formatDate( jobApplication.created_at ) }}</p>
                    </div>
                    <div class=" active-resumes__table-col-date w-full my-auto pl-5 flex flex-col mb-5">
                        <span class="active-resumes__table-heading">Status</span>
                        <span class="text-xs font-medium text-gray-500">Most Recent View</span>
                        <span v-if="jobApplication.recent_view !== null" class="text-xs text-green">{{ formatDate( jobApplication.recent_view.created_at ) }}</span>
                        <span v-if="jobApplication.recent_view === null" class="text-xs text-error">Not Yet Viewed</span>
                    </div>
                    <div class=" active-resumes__table-col-status w-full my-auto pl-5 flex flex-col">
                        <span class="active-resumes__table-heading">View</span>
                        <a :href="'/view-application/'+ jobApplication.job_listing_id+'/'+jobApplication.user_id" class="text-xs text-gray-500 underline">View Submission</a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import * as moment from 'moment-timezone';
export default {
  props: ['jobApplications', 'assetPath'],
  data() {
    return {

    };
  },
  methods: {
    formatDate: function( date ) {
      return moment( date ).format( 'MM/DD/YYYY' );
    },
  },
};
</script>
