<template>
    <div>
        <div class="mb-8 change-sub">
            <div v-if="! changePlan">
                <h2 class="hdg-5">Your Plan</h2>

                <div v-if="onTrial">
                    You are currently using a free trial of Top Applicant. Your trial expires {{ getTrialEndingString( data.user.trial_ends_at ) }}.
                </div>

                <div v-if="( selectedPlan || extras )">
                    <ul>
                        <li v-if="selectedPlan === data.plans.single" class="py-8">
                            <div class="account-billing__radio" for="single_plan">
                                <span class="account-billing__details-col">
                                    <span class="block mb-2 leading-tight"><span class="text-xl font-bold">$25.00</span> <span class="inline-block">/ month</span></span>
                                    <span class="block leading-tight paragraph-default">1 active job listing</span>
                                    <span v-if="data.current_subscription.ends_at" class="block leading-tight paragraph-default text-error">{{ getSubscriptionEndingString( data.current_subscription.ends_at ) }}</span>
                                </span>
                                <span class="account-billing__button-col">
                                    <span class="account-billing__button-checked">
                                        Current Plan <svg class="flex-shrink-0 ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                                    </span>
                                </span>
                            </div>
                        </li>
                        <li v-if="selectedPlan === data.plans.basic" class="py-8">
                            <div class="account-billing__radio" for="basic_plan">
                                <span class="account-billing__details-col">
                                    <span class="block mb-2 leading-tight"><span class="text-xl font-bold">$50.00</span> <span class="inline-block">/ month</span></span>
                                    <span class="block leading-tight paragraph-default">Up to 5 active job listings</span>
                                    <span v-if="data.current_subscription.ends_at" class="block leading-tight paragraph-default text-error">{{ getSubscriptionEndingString( data.current_subscription.ends_at ) }}</span>
                                </span>
                                <span class="account-billing__button-col">
                                    <span class="account-billing__button-checked">
                                        Current Plan <svg class="flex-shrink-0 ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                                    </span>
                                </span>
                            </div>
                        </li>
                        <li v-if="selectedPlan === data.plans.standard" class="py-8">
                            <div class="account-billing__radio" for="standard_plan">
                                <span class="account-billing__details-col">
                                    <span class="block mb-2 leading-tight"><span class="text-xl font-bold">$150.00</span> <span class="inline-block">/ month</span></span>
                                    <span class="block leading-tight paragraph-default">Up to 10 active job listings</span>
                                    <span v-if="data.current_subscription.ends_at" class="block leading-tight paragraph-default text-error">{{ getSubscriptionEndingString( data.current_subscription.ends_at ) }}</span>
                                </span>
                                <span class="account-billing__button-col">
                                    <span class="account-billing__button-checked">
                                        Current Plan <svg class="flex-shrink-0 ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                                    </span>
                                </span>
                            </div>
                        </li>
                        <li v-if="selectedPlan === data.plans.pro" class="py-8">
                            <div class="account-billing__radio" for="pro_plan">
                                <span class="account-billing__details-col">
                                    <span class="block mb-2 leading-tight"><span class="text-xl font-bold">$250.00</span> <span class="inline-block">/ month</span></span>
                                    <span class="block leading-tight paragraph-default">10+ active job listings</span>
                                    <span v-if="data.current_subscription.ends_at" class="block leading-tight paragraph-default text-error">{{ getSubscriptionEndingString( data.current_subscription.ends_at ) }}</span>
                                </span>
                                <span class="account-billing__button-col">
                                    <span class="account-billing__button-checked">
                                        Current Plan <svg class="flex-shrink-0 ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                                    </span>
                                </span>
                            </div>
                        </li>

                    </ul>
                </div>

                <div v-else class="">
                    <template v-if="!data.sponsored_tier">
                        You are not currently subscribed to a plan.
                    </template>
                    <template v-else>
                        <div v-if="data.sponsored_listing_subscription.ends_at">
                            Your subscription will end on {{ getTrialEndingString( data.sponsored_listing_subscription.ends_at ) }}
                        </div>
                        <div v-else>
                            You are subscribed to a Sponsored Listing plan.
                            <div v-if="data.sponsored_tier === 'tier_1'">
                                1 sponsored listing at $50.00 / month.
                            </div>
                            <div v-if="data.sponsored_tier === 'tier_2'">
                                Up to 5 sponsored listings at $100.00 / month.
                            </div>
                            <div v-if="data.sponsored_tier === 'tier_3'">
                                Up to 10 sponsored listings at $250.00 / month.
                            </div>
                            <div v-if="data.sponsored_tier === 'tier_4'">
                                Unlimited $500.00 / month.
                            </div>
                        </div>
                    </template>
                </div>

                <div class="mt-5 row">
                    <div class="w-full mb-5 col sm:w-1/2 sm:mb-0">
                        <button @click="changePlan = ! changePlan" class="w-full btn-green-sm" type="button">
                            <span v-if="selectedPlan">Change Plan</span>
                            <span v-else>Select Plan</span>
                        </button>
                    </div>
                    <div class="w-full col sm:w-1/2">
                        <button @click="cancelSubscriptionPlans" class="w-full btn-sm" type="button">Cancel Plan</button>
                    </div>
                </div>
            </div>

            <div v-else>
                <h2 class="hdg-5">Select Your Plan</h2>

                <div v-if="changePlanError" class="mt-5 mb-0 alert alert-error">
                    {{ changePlanError }}
                </div>

                <ul>

                    <!-- <li class="py-8 border-b border-gray-300">
                        <label class="account-billing__radio" for="single_plan">
                            <span class="account-billing__details-col">
                                <span class="block mb-2 leading-tight"><span class="text-xl font-bold">$25.00</span> <span class="inline-block">/ month</span></span>
                                <span class="block leading-tight paragraph-default">1 active job listing</span>
                            </span>
                            <span class="account-billing__button-col">
                                <span v-if="newSelectedPlan === data.plans.single" class="account-billing__button-checked">
                                    Current Plan <svg class="flex-shrink-0 ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                                </span>
                                <span v-else class="account-billing__button-unchecked">
                                    Select Plan
                                </span>
                            </span>
                        </label>
                        <input v-model="newSelectedPlan" class="hidden" id="single_plan" type="radio" name="plan" :value="data.plans.single">
                    </li>
                    <li class="py-8 border-b border-gray-300">
                        <label class="account-billing__radio" for="basic_plan">
                            <span class="account-billing__details-col">
                                <span class="block mb-2 leading-tight"><span class="text-xl font-bold">$50.00</span> <span class="inline-block">/ month</span></span>
                                <span class="block leading-tight paragraph-default">Up to 5 active job listings</span>
                            </span>
                            <span class="account-billing__button-col">
                                <span v-if="newSelectedPlan === data.plans.basic" class="account-billing__button-checked">
                                    Current Plan <svg class="flex-shrink-0 ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                                </span>
                                <span v-else class="account-billing__button-unchecked">
                                    Select Plan
                                </span>
                            </span>
                        </label>
                        <input v-model="newSelectedPlan" class="hidden" id="basic_plan" type="radio" name="plan" :value="data.plans.basic">
                    </li>
                    <li class="py-8 border-b border-gray-300">
                        <label class="account-billing__radio" for="standard_plan">
                            <span class="account-billing__details-col">
                                <span class="block mb-2 leading-tight"><span class="text-xl font-bold">$150.00</span> <span class="inline-block">/ month</span></span>
                                <span class="block leading-tight paragraph-default">Up to 10 active job listings</span>
                            </span>
                            <span class="account-billing__button-col">
                                <span v-if="newSelectedPlan === data.plans.standard" class="account-billing__button-checked">
                                    Current Plan <svg class="flex-shrink-0 ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                                </span>
                                <span v-else class="account-billing__button-unchecked">
                                    Select Plan
                                </span>
                            </span>
                        </label>
                        <input v-model="newSelectedPlan" class="hidden" id="standard_plan" type="radio" name="plan" :value="data.plans.standard">
                    </li>
                    <li class="py-8">
                        <label class="account-billing__radio" for="pro_plan">
                            <span class="account-billing__details-col">
                                <span class="block mb-2 leading-tight"><span class="text-xl font-bold">$250.00</span> <span class="inline-block">/ month</span></span>
                                <span class="block leading-tight paragraph-default">10+ active job listings</span>
                                <ul>
                                    <li class="flex items-center justify-start my-3 leading-none">
                                        <svg class="flex-shrink-0 mr-4 icon icon-checkmark text-green"><use xlink:href="#icon-checkmark"></use></svg>
                                        <span class="text-xs text-gray-500">
                                            Up to 3 Resumes
                                        </span>
                                    </li>
                                    <li class="flex items-center justify-start my-3 leading-none">
                                        <svg class="flex-shrink-0 mr-4 icon icon-checkmark text-green"><use xlink:href="#icon-checkmark"></use></svg>
                                        <span class="text-xs text-gray-500">
                                            Up to 9 cover letters
                                        </span>
                                    </li>
                                    <li class="flex items-center justify-start my-3 leading-none">
                                        <svg class="flex-shrink-0 mr-4 icon icon-checkmark text-green"><use xlink:href="#icon-checkmark"></use></svg>
                                        <span class="text-xs text-gray-500">
                                            Basic analytics included
                                        </span>
                                    </li>
                                    <li class="flex items-center justify-start my-3 leading-none">
                                        <svg class="flex-shrink-0 mr-4 icon icon-checkmark text-green"><use xlink:href="#icon-checkmark"></use></svg>
                                        <span class="text-xs text-gray-500">
                                            Portfolio gallery
                                        </span>
                                    </li>
                                    <li class="flex items-center justify-start my-3 leading-none">
                                        <svg class="flex-shrink-0 mr-4 icon icon-checkmark text-green"><use xlink:href="#icon-checkmark"></use></svg>
                                        <span class="text-xs text-gray-500">
                                            Video gallery
                                        </span>
                                    </li>
                                </ul>
                            </span>
                            <span class="account-billing__button-col">
                                <span v-if="newSelectedPlan === data.plans.pro" class="account-billing__button-checked">
                                    Current Plan <svg class="flex-shrink-0 ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                                </span>
                                <span v-else class="account-billing__button-unchecked">
                                    Select Plan
                                </span>
                            </span>
                        </label>
                        <input v-model="newSelectedPlan" class="hidden" id="pro_plan" type="radio" name="plan" :value="data.plans.pro">
                    </li> -->

                    <li>
                        <!-- <h2 class="hdg-5">Add Sponsored Listings to Any Plan</h2> -->
                        <span class="block mb-5 leading-tight paragraph-default">Sponsor listings for top-of-page visibility</span>

                        <div class="border border-gray-300 p-gutter">
                            <label class="account-billing__checkbox" for="tier_1">
                                <span class="account-billing__details-col">
                                    <span class="block mb-2 leading-tight"><span class="text-xl font-bold">+$50.00</span> <span class="inline-block">/ month</span></span>
                                    <span class="block leading-tight paragraph-default">1 sponsored listing</span>
                                </span>
                                <span class="account-billing__button-col">
                                    <span v-if="newSelectedTier === data.plans.tier_1" class="account-billing__button-checked">
                                        Added <svg class="flex-shrink-0 ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                                    </span>
                                    <span v-else class="account-billing__button-unchecked">
                                        Add to Plan
                                    </span>
                                </span>
                            </label>
                            <span class="flex justify-end"  v-if="newSelectedTier === this.data.plans.tier_1">
                                <button v-on:click="removeSponsoredPlan()">
                                    Undo
                                </button>
                            </span>
                            <input v-model="newSelectedTier" class="hidden" id="tier_1" type="radio" name="tier" :value="data.plans.tier_1">
                        </div>
                        <div class="border border-gray-300 p-gutter">
                            <label class="account-billing__checkbox" for="tier_2">
                                <span class="account-billing__details-col">
                                    <span class="block mb-2 leading-tight"><span class="text-xl font-bold">+$100.00</span> <span class="inline-block">/ month</span></span>
                                    <span class="block leading-tight paragraph-default">2-5 sponsored listings</span>
                                </span>
                                <span class="account-billing__button-col">
                                    <span v-if="newSelectedTier === data.plans.tier_2" class="account-billing__button-checked">
                                        Added <svg class="flex-shrink-0 ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                                    </span>
                                    <span v-else class="account-billing__button-unchecked">
                                        Add to Plan
                                    </span>
                                </span>
                            </label>
                            <span class="flex justify-end"  v-if="newSelectedTier === this.data.plans.tier_2">
                                <button v-on:click="removeSponsoredPlan()">
                                    Undo
                                </button>
                            </span>
                            <input v-model="newSelectedTier" class="hidden" id="tier_2" type="radio" name="tier" :value="data.plans.tier_2">
                        </div>
                        <div class="border border-gray-300 p-gutter">
                            <label class="account-billing__checkbox" for="tier_3">
                                <span class="account-billing__details-col">
                                    <span class="block mb-2 leading-tight"><span class="text-xl font-bold">+$250.00</span> <span class="inline-block">/ month</span></span>
                                    <span class="block leading-tight paragraph-default">6-10 sponsored listings</span>
                                </span>
                                <span class="account-billing__button-col">
                                    <span v-if="newSelectedTier === data.plans.tier_3" class="account-billing__button-checked">
                                        Added <svg class="flex-shrink-0 ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                                    </span>
                                    <span v-else class="account-billing__button-unchecked">
                                        Add to Plan
                                    </span>
                                </span>
                            </label>
                            <span class="flex justify-end"  v-if="newSelectedTier === this.data.plans.tier_3">
                                <button v-on:click="removeSponsoredPlan()">
                                    Undo
                                </button>
                            </span>
                            <input v-model="newSelectedTier" class="hidden" id="tier_3" type="radio" name="tier" :value="data.plans.tier_3">
                        </div>
                        <div class="border border-gray-300 p-gutter">
                            <label class="account-billing__checkbox" for="tier_4">
                                <span class="account-billing__details-col">
                                    <span class="block mb-2 leading-tight"><span class="text-xl font-bold">+$500.00</span> <span class="inline-block">/ month</span></span>
                                    <span class="block leading-tight paragraph-default">10+ sponsored listings</span>
                                </span>
                                <span class="account-billing__button-col">
                                    <span v-if="newSelectedTier === data.plans.tier_4" class="account-billing__button-checked">
                                        Added <svg class="flex-shrink-0 ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                                    </span>
                                    <span v-else class="account-billing__button-unchecked">
                                        Add to Plan
                                    </span>
                                </span>
                            </label>
                            <span class="flex justify-end"  v-if="newSelectedTier === this.data.plans.tier_4">
                                <button v-on:click="removeSponsoredPlan()">
                                    Undo
                                </button>
                            </span>
                            <input v-model="newSelectedTier" class="hidden" id="tier_4" type="radio" name="tier" :value="data.plans.tier_4">
                        </div>
                    </li>
                </ul>

                <div class="mt-5 row">
                    <div class="w-full mb-5 col sm:w-1/2 sm:mb-0">
                        <button @click="undoPlanChanges" class="w-full btn-sm" type="button">Cancel</button>
                    </div>
                    <div class="w-full col sm:w-1/2">
                        <button @click="updateSubscriptionPlan" class="w-full btn-green-sm" type="button">Save</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-12 change-sub">
            <div v-if="!addPaymentMethod">
                <h2 class="mb-5 hdg-5">Payment Method</h2>

                <div v-show="paymentMethodLoadStatus == 2
                    && ! paymentMethod"
                    class="">
                        No payment method on file, please add a payment method.
                </div>

                <div v-show="paymentMethodLoadStatus == 2
                        && paymentMethod">
                    <div class="items-center row">
                        <div class="col">
                            <svg class="mr-4 text-2xl icon icon-mastercard"><use xlink:href="#icon-mastercard"></use></svg>
                            &bull;&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&bull;&nbsp;&bull;&bull;&bull;&bull;&nbsp;{{ paymentMethod.last_four }}
                        </div>
                        <div class="col">
                            <button class="px-4 py-1 font-bold text-white text-3xs bg-error rounded-xs hover:bg-redhover" type="button" v-on:click.stop="removePaymentMethod( paymentMethod.id )">Remove</button>
                        </div>
                    </div>
                </div>

                <div class="mt-5 row">
                    <div class="w-full col sm:w-1/2">
                        <button @click="addPaymentMethod = !addPaymentMethod" class="w-full btn-sm" type="button">
                            <span v-if="paymentMethod">Change Card</span>
                            <span v-else>Add Card</span>
                        </button>
                    </div>
                </div>
            </div>
            <div v-else>
                <h2 class="mb-5 hdg-5">Add Payment Method</h2>

                <div v-if="addPaymentStatusError" class="alert alert-error">
                    {{ addPaymentStatusError }}
                </div>

                <div class="form-skin">

                    <div class="form-group">
                        <label for="card-holder-name">Card Holder Name</label>

                        <div class="form-group__input">
                            <input v-model="name" id="card-holder-name" type="text" class="" name="card-holder-name" required>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="card-element">Card</label>

                        <div class="form-group__input form-group__input--stripe">
                            <div id="card-element"></div>
                        </div>
                    </div>
                </div>

                <div class="mt-5 row">
                    <div class="w-full mb-5 col sm:w-1/2 sm:mb-0">
                        <button @click="undoPaymentChanges" class="w-full btn-sm" type="button">Cancel</button>
                    </div>
                    <div class="w-full col sm:w-1/2">
                        <button @click="addPaymentCard" class="w-full btn-green-sm" type="button">Save</button>
                    </div>
                </div>
            </div>

        </div>

        <div class="flex justify-center h-screen mx-auto my-auto mt-12">
            <div class="hidden loader"></div>
        </div>
    </div>
</template>

<script>
import StripeMixin from '../../mixins/StripeMixin';
import * as moment from 'moment-timezone';
export default {
  mixins: [StripeMixin],
  props: ['data'],
  data() {
    return {
      selectedPlan: false,
      selectedTier: false,
      extras: false,
      tier_1: false,
      tier_2: false,
      tier_3: false,
      tier_4: false,
      newSelectedTier: false,
      newSelectedPlan: false,
      newExtras: false,
      changePlan: false,
      addPaymentMethod: false,
      name: this.data.user.full_name,
      paymentMethod: false,
      paymentMethodLoadStatus: 0,
      addPaymentStatus: 0,
      addPaymentStatusError: '',
      changePlanError: '',
      isRunning: false,
      onTrial: this.data.on_trial,
      isCancel: false,
    };
  },
  mounted() {
    if ( this.data.current_subscription ) {
      this.selectedPlan = this.data.current_subscription.stripe_plan;
      this.newSelectedPlan = this.data.current_subscription.stripe_plan;
    }

    if ( this.data.sponsored_listing_subscription ) {
      this.selectedTier = this.data.sponsored_listing_subscription.stripe_plan;
      this.newSelectedTier = this.data.sponsored_listing_subscription.stripe_plan;
      if ( this.data.sponsored_tier === 'tier_1' ) {
        this.tier_1 = true;
      }
      if ( this.data.sponsored_tier === 'tier_2' ) {
        this.tier_2 = true;
      }
      if ( this.data.sponsored_tier === 'tier_3' ) {
        this.tier_3 = true;
      }
      if ( this.data.sponsored_tier === 'tier_4' ) {
        this.tier_4 = true;
      }
    }
    this.loadPaymentMethod();
  },
  methods: {
    addPaymentCard() {
      const _this = this;

      if ( ! _this.isRunning ) {
        _this.isRunning = true;
        _this.addPaymentStatusError = '';

        _this.addPaymentStatus = 1;
        $( '.change-sub' ).hide();
        $( '.loader' ).removeClass( 'hidden' );

        _this.stripe.confirmCardSetup(
            _this.intent.client_secret, {
              payment_method: {
                card: _this.card,
                billing_details: {
                  name: _this.name,
                },
              },
            }
        ).then( function( result ) {
          if ( result.error ) {
            $( '.change-sub' ).show();
            $( '.loader' ).addClass( 'hidden' );
            _this.isRunning = false;
            _this.addPaymentStatus = 3;
            _this.addPaymentStatusError = result.error.message;
          } else {
            $( '.change-sub' ).show();
            $( '.loader' ).addClass( 'hidden' );
            _this.savePaymentMethod( result.setupIntent.payment_method );
            _this.addPaymentStatus = 2;
            _this.card.clear();
            _this.name = '';
          }
        } );
      }
    },
    cancelSubscriptionPlans() {
      this.newSelectedPlan = false;
      this.newExtras = false;
      this.isCancel = true;
      this.updateSubscriptionPlan( this.isCancel );
    },
    updateSubscriptionPlan( isCancel ) {
      const _this = this;

      if ( ! _this.isRunning ) {
        if ( _this.paymentMethod ) {
          _this.isRunning = true;
          $( '.change-sub' ).hide();
          $( '.loader' ).removeClass( 'hidden' );

          axios.put( '/api/account/subscription', {
            subscriptionPlan: _this.newSelectedPlan,
            extrasPlan: _this.newExtras,
            tierPlan: _this.newSelectedTier,
            payment: _this.paymentMethod.id,
            isCancel: this.isCancel ? this.isCancel : false,
          } ).then( function( response ) {
            window.location.href = '/account/subscription';
          } );
        } else {
          _this.changePlanError = 'Please set up a payment method first.';
          $( '.change-sub' ).show();
          $( '.loader' ).addClass( 'hidden' );
        }
      }
    },
    submitPaymentMethod() {
      const _this = this;
    },
    savePaymentMethod( method ) {
      const _this = this;
      axios.post( '/api/account/payments', {
        payment_method: method,
      } ).then( function() {
        _this.isRunning = false;
        _this.addPaymentMethod = false;
        _this.loadPaymentMethod();
      } );
    },
    loadPaymentMethod() {
      const _this = this;
      _this.paymentMethodLoadStatus = 1;

      axios.get( '/api/account/payment-method' )
          .then( function( response ) {
            _this.paymentMethod = response.data;
            _this.paymentMethodLoadStatus = 2;
          } );
    },
    removeSponsoredPlan() {
      this.newSelectedTier = false;
    },
    removePaymentMethod( paymentID ) {
      const _this = this;
      axios.post( '/api/account/remove-payment', {
        id: paymentID,
      } ).then( function( response ) {
        _this.loadPaymentMethod();
      } );
    },
    undoPlanChanges: function() {
      this.changePlan = false;
      this.newSelectedPlan = this.selectedPlan;
      this.newExtras = this.extras;
    },
    undoPaymentChanges: function() {
      this.addPaymentMethod = false;
    },
    getSubscriptionEndingString: function( endDate ) {
      return 'Cancelled: ends ' + moment( endDate ).format( 'MM/DD/YYYY' );
    },
    getTrialEndingString: function( endDate ) {
      return moment( endDate ).format( 'MM/DD/YYYY' );
    },
  },
  watch: {
    addPaymentMethod: function() {
      if ( this.addPaymentMethod ) {
        this.$nextTick( function() {
          this.card.mount( '#card-element' );
        } );
      }
    },
  },
};
</script>
