<template>
    <div>
      <p class="hdg-6 mb-4">Next Steps Email</p>
      <div v-if="nextStepsEmail.length > 0" class="relative box bg-bgblue mb-8">
        <div v-for="(nextStepsEmail, nextStepsEmailIndex) in nextStepsEmail" :key="nextStepsEmailIndex">
          <div class="flex flex-wrap -mx-2">
            <div class="flex-initial px-2 w-full mb-5 xl:mb-0">
              <div class="flex flex-wrap -mx-2">
                <div class="flex-initial w-full px-2 relative">
                  <div class="form-group mb-2">
                    <label :for="'email_subject_' + nextStepsEmailIndex">Email Subject</label>
                    <div class="form-group__input form-group__input--icon">
                      <input type="text" v-model="nextStepsEmail.email_subject" :id="'email_subject_' + nextStepsEmailIndex" :name="'email_subject_' + nextStepsEmailIndex" class="" required/>
                    </div>
                  </div>
                  <div class="form-group mb-2">
                    <label :for="'email_body_' + nextStepsEmailIndex">Email Body</label>
                    <div class="form-group__input form-group__input--icon">
                      <textarea v-model="nextStepsEmail.email_body" :id="'email_body_' + nextStepsEmailIndex" :name="'email_body_' + nextStepsEmailIndex" class="" required></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['jobListingNextStepsEmail', 'jobListingNextStepsEmailOptions'],
  data() {
    return {
      nextStepsEmail: [],
      year: new Date().getFullYear(),
    };
  },
  mounted() {
    this.setJobListingNextStepsEmail();
  },
  methods: {
    setJobListingNextStepsEmail: function() {
      if ( this.jobListingNextStepsEmail.length > 0 ) {
        this.nextStepsEmail = Object.assign( [], this.jobListingNextStepsEmail );
      } else {
        this.addJobListingNextStepsEmail( false );
      }
    },
    addJobListingNextStepsEmail: function( id ) {
      if ( id !== false ) {
        this.nextStepsEmail.push(
            Object.assign( {}, this.jobListingNextStepsEmailOptions[id] )
        );
      } else {
        this.nextStepsEmail.push(
            {
              id: false,
            }
        );
      }
    },
    removeNextStepsEmail: function( index ) {
      this.nextStepsEmail.splice( index, 1 );
    },
  },
  watch: {
    nextStepsEmail() {
      this.$emit( 'update', this.nextStepsEmail );
    },
  },
};
</script>
