<template>
    <div>
        <p class="hdg-6 mb-2">Social Media</p>

        <p class="paragraph-small text-3xs mb-4 text-gray-500">Manage social media links in <a href="/profile" class="underline text-blue hover:no-underline">your profile</a>.</p>

        <div v-if="socialOptions.length > 0" class="mb-8">
            <div v-for="(social, socialIndex) in socialOptions" :key="socialIndex">

                <div class="form-group form-group--small">
                    <label :for="'social_' + social.slug + '_' + socialIndex">{{ social.label }}</label>

                    <div class="form-group__input form-group__input--icon">
                        <input v-model="social.url" :id="'social_' + social.slug + '_' + socialIndex" type="text" class="pr-5" :name="'social_' + social.slug + '_' + socialIndex" required disabled>
                        <svg @click="removeSocial( socialIndex )" class="block icon icon-minus"><use xlink:href="#icon-minus"></use></svg>
                    </div>
                </div>

            </div>
        </div>

        <div class="relative">
            <button type="button" name="add_social" class="popover__button btn-dashed" data-toggle-class="is-open" data-toggle-outside data-toggle-target="#add-social-popup"><svg class="pointer-events-none icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>Add Social</button>

            <div class="popover__popup hidden absolute left-0 bottom-100 w-full" id="add-social-popup" role="navigation">
                <div class="popover__popup-box">
                    <div v-for="(social, socialIndex) in socialOptions" :key="socialIndex">
                        <button v-if="!isInUse( social.id )" :data-id="socialIndex" @click="addSocial( socialIndex )" data-toggle-trigger-off class="flex w-full items-center text-left text-gray-500 text-sm font-medium hover:text-blue py-3" type="button"><svg :class="'pointer-events-none block text-lg icon mr-2 icon-' + social.slug"><use :xlink:href="'#icon-' + social.slug"></use></svg>{{ social.label }}</button>
                    </div>
                    <p v-if="socialOptions.length === socials.length" class="paragraph-small text-xs text-gray-500">Add more social media links in <a href="/profile" class="underline text-blue hover:no-underline">your profile</a> to select them here.</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
  props: ['userSocials', 'socialOptions'],
  data() {
    return {
      socials: [],
    };
  },
  mounted() {
    this.setSocials();
  },
  methods: {
    setSocials: function() {
      if ( this.userSocials.length > 0 ) {
        this.socials = Object.assign( [], this.userSocials );
      }
    },
    addSocial: function( id ) {
      this.socials.push(
          Object.assign( {}, this.socialOptions[id] )
      );
    },
    removeSocial: function( index ) {
      this.socials.splice( index, 1 );
    },
    isInUse: function( id ) {
      let isInUse = false;
      if ( id ) {
        $.each( this.socials, function( index, social ) {
          if ( social.id === id ) {
            isInUse = true;
          }
        } );
      }
      return isInUse;
    },
  },
  watch: {
    socials() {
      this.$emit( 'update', this.socials );
    },
  },
};
</script>
