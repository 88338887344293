<template>
    <div>
        <p class="hdg-6 mb-4">About</p>

        <div class="mb-8">
            <div class="relative box bg-bgblue mb-8">
                <div class="form-group mb-0">
                    <label for="summary">Professional Summary</label>

                    <div class="form-group__input">
                        <textarea v-model="summary" id="summary" name="summary"></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: ['resumeSummary'],
  data() {
    return {
      summary: '',
    };
  },
  mounted() {
    this.setSummary();
  },
  methods: {
    setSummary: function() {
      if ( this.resumeSummary ) {
        this.summary = this.resumeSummary;
      }
    },
  },
  watch: {
    summary() {
      this.$emit( 'update', this.summary );
    },
  },
};
</script>
