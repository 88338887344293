<template>
    <div class="hidden">
    </div>
</template>

<script>
export default {
  props: ['coverLetterResumeId'],
  mounted() {
    this.trackView();
  },
  methods: {
    trackView: function() {
      const _this = this;
      // For local testing
      // $.ajax( 'https://ip-api.com/json' )
      //     .then(
      //         function success( response ) {
      //           console.log( response );
      //
      //           axios.get( '/track_view', {
      //             params: {
      //               data: response,
      //               id: _this.coverLetterResumeId,
      //             },
      //           } )
      //               .then( ( response ) => {
      //                 console.log( response );
      //               } ).catch( ( error ) => {
      //                 console.log( error );
      //               } );
      //         },
      //         function fail( data, status ) {
      //           console.log( 'Request failed.  Returned status of', status );
      //         }
      //     );
      // For production (SSL)
      $.getJSON( 'https://ipapi.co/json/' )
          .then(
              function success( response ) {
                // console.log( response );

                axios.get( '/track_view', {
                  params: {
                    data: response,
                    id: _this.coverLetterResumeId,
                  },
                } )
                    .then( ( response ) => {
                      // console.log( response );
                    } ).catch( ( error ) => {
                      // console.log( error );
                    } );
              },
              function fail( data, status ) {
                console.log( 'Request failed.  Returned status of', status );
              }
          );
    },
  },
};
</script>
