<template>
    <form method="POST" class="form-skin" @submit.prevent="handleSubmit">
        <div class="saved-banner absolute right-0 w-20 h-8 bg-green text-white flex justify-center my-auto text-xl hidden">Saved!</div>
        <div class="mb-5">
            <div class="row items-center">
                <div class="col w-full sm:w-auto mb-3 sm:mb-0">
                    <h1 v-if="formData.cover_letter === false" class="hdg-4">New Cover Letter</h1>
                    <h1 v-else class="hdg-4">Edit Cover Letter</h1>
                </div>
                <div class="col w-full sm:w-auto ml-auto">
                    <div class="flex -mx-2">
                        <div v-if="formData.cover_letter === false" class="flex-initial px-2">
                            <button type="submit" name="button" class="btn-save">Save Cover Letter</button>
                        </div>
                        <div v-if="formData.cover_letter !== false" class="flex-initial px-2">
                            <button type="button" name="button" class="btn-preview js-init-popup" :data-modal="'#share-' + formData.cover_letter.id">Preview</button>
                            <preview-cover-letter :popup-id="'share-' + formData.cover_letter.id" :form-data="formData" :asset-path="assetPath"></preview-cover-letter>
                        </div>
                        <div v-if="formData.cover_letter !== false" class="flex-initial px-2">
                            <button type="submit" name="button" class="btn-save">Update</button>
                        </div>
                        <div v-if="formData.cover_letter !== false" class="flex-initial px-2">
                            <button @click="handleDelete( formData.cover_letter.id )" type="button" name="button" class="btn-delete"><svg class="icon icon-trash"><use xlink:href="#icon-trash"></use></svg>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="title" class="sr-only">Cover Letter Title</label>

            <div class="form-group__input">
                <input v-model="formData.title" id="title" type="text" class="" name="title" placeholder="Cover Letter Title" required autofocus>
            </div>
        </div>

        <div class="box box--lg">
            <div class="box bg-bgblue">
                <div class="flex -mx-2 flex-wrap">
                    <div class="flex-initial px-2 w-full lg:w-1/2">
                        <div class="form-group">
                            <label for="salutation">Greeting</label>

                            <div class="form-group__input">
                                <input v-model="formData.salutation" id="salutation" type="text" class="" name="salutation" required>
                            </div>
                        </div>
                    </div>
                    <div class="flex-initial px-2 w-full lg:w-1/2">
                        <div class="form-group">
                            <label for="intro">Introduction</label>

                            <div class="form-group__input">
                                <input v-model="formData.intro" id="intro" type="text" class="" name="intro" required>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex -mx-2 flex-wrap">
                    <div class="flex-initial px-2 w-full lg:w-1/3">
                        <div class="form-group">
                            <label for="email">Email</label>

                            <div class="form-group__input">
                                <input v-model="formData.email" id="email" type="email" class="" name="email" required>
                            </div>
                        </div>
                    </div>
                    <div class="flex-initial px-2 w-full lg:w-1/3">
                        <div class="form-group">
                            <label for="location">Location</label>

                            <div class="form-group__input">
                                <input v-model="formData.location" id="location" type="text" class="" name="location" required>
                            </div>
                        </div>
                    </div>
                    <div class="flex-initial px-2 w-full lg:w-1/3">
                        <div class="form-group">
                            <label for="phone">Phone</label>

                            <div class="form-group__input">
                                <input v-model="formData.phone" id="phone" type="text" class="" name="phone" required maxlength="15">
                            </div>
                        </div>
                    </div>
                </div>

                <wysiwyg v-model="formData.content" label="Cover Letter Content" height="500px" :asset-path="assetPath"></wysiwyg>
            </div>
        </div>

    </form>
</template>

<script>
export default {
  props: ['data', 'assetPath'],
  data() {
    return {
      action: '',
      errors: [],
      isRunning: false,
      formData: this.data,
    };
  },
  mounted() {
    const searchParams = new URLSearchParams( window.location.search );
    if ( searchParams.get( 'saved' ) === 'true' ) {
      $( '.saved-banner' ).removeClass( 'hidden' );
      setTimeout( () => {
        $( '.saved-banner' ).fadeOut();
      }, '5000' );
      window.history.pushState( {}, document.title, '/profile' );
    }
    if ( this.formData.cover_letter ) {
      this.action = '/api/cover-letter/update';
    } else {
      this.action = '/api/cover-letter/create';
    }
  },
  methods: {
    handleSubmit() {
      const _this = this;

      if ( this.isRunning === false ) {
        // Validate inputs
        _this.validateInputs();

        if ( _this.errors.length === 0 ) {
          // Submit form
          axios.post( _this.action, {
            data: _this.formData,
          } )
              .then( ( response ) => {
                window.location.href = '/cover-letters/' + response.data.id + '/edit?saved=true';
              } ).catch( ( error ) => {
                console.log( error );
                _this.isRunning = false;
              } );
        } else {
          _this.isRunning = false;
        }
      }
    },
    validateInputs() {
      if ( this.formData.title
            && this.formData.salutation
            && this.formData.intro
            && this.formData.email
            && this.formData.location
            && this.formData.phone ) {
        return true;
      }

      this.errors = [];

      if ( !this.formData.title ) {
        this.errors.push( 'Title is required.' );
      }
      if ( !this.formData.salutation ) {
        this.errors.push( 'Salutation is required.' );
      }
      if ( !this.formData.intro ) {
        this.errors.push( 'Introduction required.' );
      }
      if ( !this.formData.email ) {
        this.errors.push( 'Email required.' );
      }
      if ( !this.formData.location ) {
        this.errors.push( 'Location required.' );
      }
      if ( !this.formData.phone ) {
        this.errors.push( 'Phone required.' );
      }
    },
    handleDelete( id ) {
      const _this = this;

      if ( this.isRunning === false ) {
        // Validate inputs
        _this.validateInputs();

        if ( _this.errors.length === 0 ) {
          // Submit form
          axios.delete( '/cover-letters/' + id )
              .then( ( response ) => {
                window.location.href = '/cover-letters';
              } ).catch( ( error ) => {
                console.log( error );
                _this.isRunning = false;
              } );
        } else {
          _this.isRunning = false;
        }
      }
    },
  },
};
</script>
