<template>
    <div>
        <p class="hdg-6 mb-4" v-if="formData.user.user_role === 'applicant'">Personal Values</p>
        <p class="hdg-6 mb-4" v-if="formData.user.user_role === 'employer'">Company Values</p>

        <div v-if="values.length > 0" class="mb-8">
            <div v-for="(value, valueIndex) in values" :key="valueIndex" class="relative box bg-bgblue mb-8">
                <!-- <button class="absolute right-0 top-0 p-4 hover:text-error" v-if="values.length > 0" @click="removeValue( valueIndex )" type="button" :name="'remove_value_' + valueIndex"><svg class="block text-lg icon icon-minus"><use xlink:href="#icon-minus"></use></svg></button> -->

                <div class="flex flex-wrap -mx-2">
                    <div class="flex-initial px-2 w-full mb-5 xl:mb-0">
                        <div class="flex flex-wrap -mx-2">

                            <div class="flex-initial w-full px-2">
                                <div class="form-group mb-2">
                                    <label :for="'attribute_' + valueIndex">Value #1</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select v-model="value.attribute_1" :id="'attribute_' + valueIndex" class="" :name="'attribute_' + valueIndex" required>
                                            <option value=""></option>
                                            <option v-for="(attribute, attributeIndex) in formData.personal_values_options" :key="attributeIndex"  :value="attribute.attribute_name" >{{attribute.attribute_name}}</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>

                                <div class="form-group mb-2">
                                    <label :for="'attribute_2_' + valueIndex">Value #2</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select v-model="value.attribute_2" :id="'attribute_2_' + valueIndex" class="" :name="'attribute_2_' + valueIndex" required>
                                            <option value=""></option>
                                            <option v-for="(attribute, attributeIndex) in formData.personal_values_options" :key="attributeIndex"  :value="attribute.attribute_name">{{attribute.attribute_name}}</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>

                                <div class="form-group mb-0">
                                    <label :for="'attribute_3_' + valueIndex">Value #3</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select v-model="value.attribute_3" :id="'attribute_3_' + valueIndex" class="" :name="'attribute_3_' + valueIndex" required>
                                            <option value=""></option>
                                            <option v-for="(attribute, attributeIndex) in formData.personal_values_options" :key="attributeIndex"  :value="attribute.attribute_name">{{attribute.attribute_name}}</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- <button v-if="values.length < 0" @click="addValue" type="button" name="add_value" class="btn-dashed"><svg class="icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>Add Value</button> -->
    </div>
</template>

<script>
export default {
  props: ['userValues', 'formData'],
  data() {
    return {
      values: [],
      year: new Date().getFullYear(),
    };
  },
  mounted() {
    this.setPersonalValues();
  },
  methods: {
    setPersonalValues: function() {
      if ( this.userValues.length > 0 ) {
        this.values = Object.assign( [], this.userValues );
      } else {
        this.addValue();
      }
    },
    addValue: function() {
      this.values.push(
          {
            id: false,
            resume_user_personal_value_id: false,
            attribute_1: '',
            attribute_2: '',
            attribute_3: '',
          }
      );
    },
    removeValue: function( index ) {
      this.values.splice( index, 1 );
    },
  },
  watch: {
    values() {
      this.$emit( 'update', this.values );
    },
  },
};
</script>
