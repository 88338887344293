<template>
    <div>
        <p class="hdg-6 mb-4">Personal Values</p>

        <div v-if="values.length > 0" class="mb-8">
            <div v-for="(value, valueIndex) in values" :key="valueIndex" class="relative box bg-bgblue mb-8">
                <p v-if="value.id !== false" class="text-3xs text-gray-500 mb-2 pr-4">You can edit this work experience data in <a href="/profile" class="underline text-blue hover:no-underline">your profile</a>.</p>
                <div class="flex flex-wrap -mx-2">
                    <div class="flex-initial px-2 w-full mb-5 xl:mb-0">
                        <div class="flex flex-wrap -mx-2">

                            <div class="flex-initial w-full px-2">
                                <div class="form-group mb-2">
                                    <label :for="'attribute_' + valueIndex">Value #1</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select v-model="value.attribute_1" :id="'attribute_' + valueIndex" class="" :name="'attribute_' + valueIndex" required :disabled="value.id !== false">
                                            <option value=""></option>
                                            <option value="Respect">Respect</option>
                                            <option value="Innovation">Innovation</option>
                                            <option value="Teamwork">Teamwork</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>

                                <div class="form-group mb-2">
                                    <label :for="'attribute_2_' + valueIndex">Value #2</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select v-model="value.attribute_2" :id="'attribute_2_' + valueIndex" class="" :name="'attribute_2_' + valueIndex" required :disabled="value.id !== false">
                                            <option value=""></option>
                                            <option value="Respect">Respect</option>
                                            <option value="Innovation">Innovation</option>
                                            <option value="Teamwork">Teamwork</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>

                                <div class="form-group mb-0">
                                    <label :for="'attribute_3_' + valueIndex">Value #3</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select v-model="value.attribute_3" :id="'attribute_3_' + valueIndex" class="" :name="'attribute_3_' + valueIndex" required :disabled="value.id !== false">
                                            <option value=""></option>
                                            <option value="Respect">Respect</option>
                                            <option value="Innovation">Innovation</option>
                                            <option value="Teamwork">Teamwork</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
  props: ['userValues', 'personalValuesOptions'],
  data() {
    return {
      values: [],
      year: new Date().getFullYear(),
    };
  },
  mounted() {
    this.setPersonalValues();
  },
  methods: {
    setPersonalValues: function() {
      if ( this.userValues.length > 0 ) {
        this.values = Object.assign( [], this.userValues );
      } else {
        this.addValue( false );
      }
    },
    addValue: function( id ) {
      if ( id !== false ) {
        this.values.push(
            Object.assign( {}, this.personalValuesOptions[id] )
        );
      } else {
        this.values.push(
            {
              id: false,
              resume_user_personal_value_id: false,
              attribute_1: '',
              attribute_2: '',
              attribute_3: '',
            }
        );
      }
    },
    removeValue: function( index ) {
      this.values.splice( index, 1 );
    },
  },
  watch: {
    values() {
      this.$emit( 'update', this.values );
    },
  },
};
</script>
