<template>
    <div>

        <div v-if="jobListings.length > 0">
            <div class="hidden pl-6 mb-4 row md:flex">
                <div class="jobs__table-col-title">
                    <span class="jobs__table-heading">Title</span>
                </div>
                <div class="jobs__table-col-sponsored">
                    <span class="jobs__table-heading">Sponsored</span>
                </div>
                <div class="jobs__table-col-status">
                    <span class="jobs__table-heading">Status</span>
                </div>
                <div class="jobs__table-col-conversion">
                    <span class="jobs__table-heading">Conversion</span>
                </div>
                <div class="jobs__table-col-activity">
                    <span class="jobs__table-heading">Activity</span>
                </div>
                <div class="jobs__table-col-action">
                    <span class="jobs__table-heading">Action</span>
                </div>
            </div>

            <div v-for="(jobListing, jobListingIndex) in jobListings" :key="jobListingIndex"  class="block py-4 mb-3 bg-white rounded-sm hover:shadow-xxl">
                <div class="hidden pl-6 row md:flex">
                    <div class="jobs__table-col-title">
                        <div>
                            <a :href="'/job-listings/' + jobListing.id + '/edit'" class="inline-block w-full h-full pl-5 text-xs font-medium text-gray-500 hover:text-blue">{{ jobListing.listing_title }}</a>
                            <a :href="'/job-listings/' + jobListing.id + '/edit'" class="inline-block pl-5 text-gray-500 underline text-2xs hover:text-blue">Edit</a> <span class="px-1 text-gray-500 text-2xs">|</span>
                            <button @click="handleDelete( jobListing.id )" type="button" name="button" class="inline-block text-gray-500 underline text-2xs hover:text-blue">Delete</button>
                        </div>
                    </div>
                    <div class="flex jobs__table-col-sponsored">
                        <span v-if="jobListing.sponsored_listing" class="my-auto text-xs font-medium text-gray-500">Sponsored</span>
                        <span v-else-if="!jobListing.sponsored_listing" class="my-auto text-xs font-medium text-gray-500">Not Sponsored</span>
                    </div>
                    <div class="flex jobs__table-col-status">
                        <span v-if="jobListing.status == 'published'" class="jobs__status-published">Published</span>
                        <span v-else class="jobs__status-draft">Draft</span>
                    </div>
                    <div class="flex jobs__table-col-conversion">
                        <div class="inline-block my-auto text-xs font-medium text-gray-500 hover:text-blue">{{jobListing.status === 'published' ? jobListing.applications > 0 ? ((jobListing.applications / jobListing.views) * 100).toFixed(0) + '%' : '-' : ''}}</div>
                    </div>
                    <div class="flex jobs__table-col-activity">
                        <a :href="'submissions/'+jobListing.id" v-if="jobListing.status == 'published' && jobListing.applications > 0" class="inline-block my-auto text-xs font-medium underline text-blue hover:text-blue">{{ jobListing.applications }} Applicant{{jobListing.applications !== 1 ? '\s' : ''}}</a>
                        <div v-else class="inline-block my-auto text-xs font-medium text-gray-500 hover:text-blue">-</div>
                    </div>
                    <div class="flex jobs__table-col-action">
                        <a v-if="jobListing.status === 'draft'" :href="limit ? '/account/subscription' :'/job-listings/' + jobListing.id + '/edit'" class="inline-block my-auto text-xs font-medium underline text-blue hover:text-blue">Publish Listing</a>
                        <a v-else @click="handleFindCandidates(jobListing.details[0].industry_id, jobListing.details[0].employment_type_id, jobListing.details[0].job_level_id, jobListing.details[0].salary_max, jobListing.details[0].willing_to_relocate)" class="inline-block my-auto text-xs font-medium underline cursor-pointer text-blue hover:text-blue">Find Candidates</a>
                    </div>
                </div>

                <div class="row md:hidden">
                    <div class="w-full col">
                        <div class="pl-5 mb-2">
                            <span class="jobs__table-heading">Title</span><br>
                            <a :href="'/job-listings/' + jobListing.id + '/edit'" class="inline-block text-xs font-medium text-gray-500 hover:text-blue">{{ jobListing.listing_title }}</a>
                        </div>
                        <div class="pl-5 mb-2">
                            <span class="jobs__table-heading">Date</span><br>
                            <span v-if="jobListing.updated_at" class="text-xs font-medium text-gray-500">Updated</span>
                            <span v-else-if="jobListing.created_at" class="text-xs font-medium text-gray-500">Created</span>
                            <span v-if="jobListing.updated_at" class="text-xs text-gray-500 underline">{{ formatDate( jobListing.updated_at ) }}</span>
                            <span v-else-if="jobListing.created_at" class="text-xs text-gray-500 underline">{{ formatDate( jobListing.created_at ) }}</span>
                        </div>
                        <div class="w-1/2 pl-5 mb-2">
                            <span class="jobs__table-heading">Status</span><br>
                            <span v-if="jobListing.status == 'published'" class="jobs__status-published">Published</span>
                            <span v-else class="jobs__status-draft">Draft</span>
                        </div>
                        <div class="pl-5 mb-2">
                            <span class="jobs__table-heading">Share</span><br>
                            <button type="button" class="hover:text-green js-init-popup" :data-modal="'#share-' + jobListing.id"><svg class="icon icon-arrow-3"><use xlink:href="#icon-arrow-3"></use></svg></button>
                        </div>
                        <div class="pl-5">
                            <span class="jobs__table-heading">Delete</span><br>
                            <button @click="handleDelete( jobListing.id )" type="button" class="pr-5 hover:text-error"><svg class="icon icon-trash"><use xlink:href="#icon-trash"></use></svg></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="jobListings.length <= 0">
            <p>Please post a job listing.</p>
        </div>
    </div>
</template>

<script>
import * as moment from 'moment-timezone';
export default {
  props: ['jobListings', 'atLimit'],
  data() {
    return {
      isRunning: false,
      limit: this.atLimit,
    };
  },
  mounted() {
  },
  methods: {
    formatDate: function( date ) {
      return moment( date ).format( 'MM/DD/YYYY' );
    },
    handleFindCandidates( industry, employmentType, jobLevel, salaryMax, willingToRelocate ) {
      const _this = this;
      const url = '/api/find-candidates?industries=' + industry + '&types=' + employmentType + '&jobLevels=' + jobLevel + '&salaryRange=' + salaryMax + '&relocate=' + willingToRelocate + '&currentPage=0';
      const newUrl = '/candidates?industries=' + industry + '&types=' + employmentType + '&jobLevels=' + jobLevel + '&salaryRange=' + salaryMax + '&relocate=' + willingToRelocate + '&currentPage=0';
      axios.get( url )
          .then( ( response ) => {
            window.location.href = newUrl;
          } ).catch( ( error ) => {
            console.log( error );
          } );
    },
    handleDelete( id ) {
      const _this = this;

      if ( this.isRunning === false ) {
        // Submit form
        axios.delete( '/job-listings/' + id )
            .then( ( response ) => {
              window.location.href = '/job-listings';
            } ).catch( ( error ) => {
              console.log( error );
              _this.isRunning = false;
            } );
      }
    },
  },
};
</script>
