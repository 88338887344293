<template>
    <div>
        <p class="hdg-6 mb-2">Video</p>

        <p class="paragraph-small mb-4 text-gray-500">YouTube and Vimeo links are accepted. <button class="js-init-popup text-blue hover:underline" data-modal="#video-popup">Need help getting your video URL?</button></p>

        <div class="mfp-hide popup" id="video-popup">
            <div class="container">
                <div class="row">
                    <div class="col w-full md:w-3/4 lg:w-2/3 mx-auto">
                        <div class="box box--lg h-auto relative">
                            <button class="js-close-popup close-popup-icon" type="button">
                                <svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg>
                            </button>

                            <div class="max-w-lg mx-auto">
                                <div class="my-6">
                                    <div class="hdg-5 mb-5">YouTube:</div>
                                    <p class="paragraph-default mb-5">Go to the video's page and click "Share" to open a popup. Click "Embed", then copy the URL in the "src" of the Embed Video code.</p>
                                    <img class="mb-8 max-w-full" src="/img/youtube.png" alt="YouTube Share Instructions">
                                    <div class="hdg-5 mb-5">Vimeo:</div>
                                    <p class="paragraph-default mb-5">Go to the video's page and click "Share" to open a popup. In the "Embed" section, copy the URL in the "src" of the code.</p>
                                    <img class="mb-8 max-w-full" src="/img/youtube.png" alt="Vimeo Share Instructions">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="flex flex-wrap mx-1 bg-bgblue rounded-lg border-bggrayishblue border p-4">
            <div class="flex-initial px-2 w-full mb-5 xl:mb-0">
                <div class="flex flex-wrap -mx-2">
                    <div class="flex-initial w-full px-2">
                        <div class="form-group">
                            <label for='videoIndex'>Video URL</label>
                            <div class="form-group__input" v-for="(companyVideo, companyVideosIndex) in companyVideos" :key="companyVideosIndex">
                                <input id="videoIndex" type="text" class="" name="videoIndex" v-model="companyVideo.video_url">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>

</template>

<script>
export default {
  props: ['userCompanyVideos'],
  data() {
    return {
      companyVideos: [],
      year: new Date().getFullYear(),
    };
  },
  mounted() {
    console.log( this.data );
    this.setCompanyVideos();
  },
  methods: {
    setCompanyVideos: function() {
      if ( this.userCompanyVideos.length > 0 ) {
        this.companyVideos = Object.assign( [], this.userCompanyVideos );
      } else {
        this.addCompanyVideos();
      }
    },
    addCompanyVideos: function() {
      this.companyVideos.push(
          {
            id: false,
            video_url: '',
          }
      );
    },
    removeCompanyVideos: function( index ) {
      this.companyVideos.splice( index, 1 );
    },
  },
  watch: {
    companyVideos() {
      this.$emit( 'update', this.companyVideos );
    },
  },
};
</script>
