<template>
    <div>
        <p class="hdg-6 mb-4">Custom Text</p>

        <div v-if="additionals.length > 0" class="mb-8">
            <div v-for="(additional, additionalIndex) in additionals" :key="additionalIndex" class="relative box bg-bgblue mb-8">
                <button class="absolute right-0 top-0 p-4 hover:text-error" @click="removeAdditional( additionalIndex )" type="button" :name="'remove_additional_' + additionalIndex"><svg class="block text-lg icon icon-minus"><use xlink:href="#icon-minus"></use></svg></button>

                <div class="form-group">
                    <label :for="'additional_title_' + additionalIndex">Title</label>

                    <div class="form-group__input">
                        <input v-model="additional.title" :id="'additional_title_' + additionalIndex" type="text" class="" :name="'additional_title_' + additionalIndex" required>
                    </div>
                </div>

                <wysiwyg v-model="additional.content" label="Custom Text Field" height="300px" :asset-path="assetPath"></wysiwyg>

            </div>
        </div>

        <div v-if="resumeAdditionals.length > 0" class="relative">
            <button type="button" name="add_additional" class="popover__button btn-dashed" data-toggle-class="is-open" data-toggle-outside data-toggle-target="#add-additional-popup"><svg class="pointer-events-none icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>Add Custom Text Field</button>

            <div class="popover__popup hidden absolute left-0 bottom-100 w-full" id="add-additional-popup" role="navigation">
                <div class="popover__popup-box">
                    <div>
                        <button @click="addAdditional( false )" data-toggle-trigger-off class="flex w-full items-center text-left text-gray-500 text-sm font-medium hover:text-blue py-3" type="button">Add New Custom Text Field<svg class="ml-auto pointer-events-none block text-lg icon mr-2 icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg></button>
                    </div>

                    <div v-for="(additional, additionalIndex) in resumeAdditionals" :key="additionalIndex">
                        <button v-if="!isInUse( additional.id )" :data-id="additionalIndex" @click="addAdditional( additionalIndex )" data-toggle-trigger-off class="flex w-full items-center text-left text-gray-500 text-sm font-medium hover:text-blue py-3 border-t" type="button">{{ additional.title }}</button>
                    </div>
                </div>
            </div>
        </div>

        <button v-else @click="addAdditional( false )" type="button" name="add_additional" class="btn-dashed"><svg class="icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>Add Custom Text Field</button>

    </div>
</template>

<script>
export default {
  props: ['assetPath', 'resumeAdditionals'],
  data() {
    return {
      additionals: [],
      year: new Date().getFullYear(),
    };
  },
  mounted() {
    this.setAdditionals();
  },
  methods: {
    setAdditionals: function() {
      if ( this.resumeAdditionals.length > 0 ) {
        this.additionals = Object.assign( [], this.resumeAdditionals );
      } else {
        // this.addAdditional( false );
      }
    },
    addAdditional: function( id ) {
      if ( id !== false ) {
        this.additionals.push(
            Object.assign( {}, this.resumeAdditionals[id] )
        );
      } else {
        this.additionals.push(
            {
              id: false,
              title: '',
              content: '',
            }
        );
      }
    },
    removeAdditional: function( index ) {
      this.additionals.splice( index, 1 );
    },
    isInUse: function( id ) {
      let isInUse = false;
      if ( id ) {
        $.each( this.additionals, function( index, additional ) {
          if ( additional.id === id ) {
            isInUse = true;
          }
        } );
      }
      return isInUse;
    },
  },
  watch: {
    additionals() {
      this.$emit( 'update', this.additionals );
    },
  },
};
</script>
