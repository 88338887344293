<template>
  <div>
    <div v-if="slides.length <= 0">
        <h1 class="hdg-4 mb-6">Match Maker</h1>
        <p>You have 0 jobs.</p>
    </div>
    <div class="mx-auto lg:w-2/3 w-full relative">
        <button class="match-maker__prev" @click="showPrev()" v-if="slides.length > 0">
            <svg class="icon icon-sliderarrow h-8 w-8"><use xlink:href="#icon-sliderarrow"></use></svg>
        </button>
        <button class="match-maker__next" @click="showNext()" v-if="slides.length > 0">
            <svg class="icon icon-sliderarrow h-8 w-8"><use xlink:href="#icon-sliderarrow"></use></svg>
        </button>
        <VueSlickCarousel v-bind="settings" ref="carousel" @swipe="handleSwipe" @afterChange="handleAfterChange" @beforeChange="handleBeforeChange" v-if="slides.length > 0">
            <div class="border border-gray-300 bg-white rounded-sm p-12 mb-8 relative" v-for="(slide, slideIndex) in slides" :key="slideIndex" :data-job-listing="slide.id">
                <div class="absolute top-0 right-0 mr-2 w-3/12 h-full">
                    <div class="job-match"><span class="job-match__high">HIGH</span></div>
                </div>
                <div class="row flex">
                    <div class="profile__image h-12 w-12 ml-5" v-if="slide.company_logo" :style="{backgroundImage: 'url(\'/uploads/' + slide.company_logo.path + '\')'}"></div>
                    <div class="profile__image" v-else :style="{ backgroundImage: 'url(\'' + imageUrl + '\')' }"></div>
                    <div class="col pr-0">
                        <p class="text-gray-500"><a class=" hover:text-blue" :href="'/u/'+slide.user_id">{{slide.company}}</a> &bull; {{slide.location}} &bull; <span> {{formatDate(slide.created_at)}} </span></p>
                        <p class="text-gray-700 font-bold underline">{{slide.listing_title}}</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col w-full flex mb-5">
                        <p class="text-center w-32 mt-5 mr-4 text-blue bg-tint-valueblue px-4 py-2 rounded-xs uppercase text-2xs tracking-spaced flex justify-center" v-for="(value, valueIndex) in slide.values" :key="valueIndex">
                            {{value}}
                        </p>
                    </div>
                    <div class="col w-full mb-5" v-if="slide.description">
                        <p v-if="!readMoreActivated">{{slide.description.slice(0, 234)}} <a class="" v-if="!readMoreActivated && slide.description.length >= 234" @click="activateReadMore" href="#">
                            ... Read More</a>
                        </p>
                        <span v-if="readMoreActivated" v-html="slide.description"></span>
                    </div>
                    <div class="col w-full mb-5">
                        <p class="font-medium">Location</p>
                        <p>{{slide.location}}</p>
                    </div>
                    <div class="col w-full flex">
                        <div class="flex flex-col w-1/3">
                            <p class="font-medium">Salary</p>
                            <p v-if="slide.salary_min || slide.salary_max">${{slide.salary_min}} - ${{slide.salary_max}}</p>
                            <p v-else>Undisclosed</p>
                        </div>
                        <div class="flex flex-col w-1/3">
                            <p class="font-medium">Job Type</p>
                            <p>{{slide.employment_type}}</p>
                        </div>
                        <div class="flex flex-col w-1/3">
                            <p class="font-medium">Job Level</p>
                            <p>{{slide.job_level}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </VueSlickCarousel>
        <div class="flex justify-center" v-if="slides.length > 0">
            <div class="flex flex-col mr-8">
            <button class="h-16 w-16 bg-skipred mb-3 match-match" @click="showNext()"><svg class="text-pink icon icon-skip h-6 w-6"><use xlink:href="#icon-skip"></use></svg></button>
            <p class="text-pink mx-auto">Skip</p>
            </div>
            <div class="flex flex-col ml-8">
            <button class="h-16 w-16 bg-saveblue mb-3 save-match" @click="saveMatch()"><svg class="text-blue h-6 w-6 icon icon-savestar2 "><use xlink:href="#icon-savestar2"></use></svg></button>
            <p class="text-blue mx-auto">Save</p>
            </div>
        </div>
        </div>
        <div class="match-maker__popup flex absolute lg:w-9/12 w-full right-0" v-if="!matchMakerPopup">
        <div class="flex justify-center my-auto">
            <div class="flex flex-col justify-center text-center bg-white lg:w-2/3 w-full z-10 py-12 px-16 rounded-sm">
            <div class="mb-5"><svg class="icon h-6 w-6 text-blue icon-match-maker"><use xlink:href="#icon-match-maker"></use></svg></div>
            <p class="mb-5 hdg-6">Welcome to Match Maker</p>
            <p class="mb-8">You'll see personalized job listings here that match your profile and values. Explore your matches by using the “Skip” or “Save” buttons below. You can find your saved jobs on your “My Jobs” page.</p>
            <button class="mx-auto lg:w-3/12 w-full btn btn-save" @click="userMatchMakerPopup()">Get Started!</button>
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import * as moment from 'moment-timezone';
import VueSlickCarousel from 'vue-slick-carousel';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
export default {
  props: ['activeJobListings', 'assetPath', 'viewMode', 'employmentType', 'industries', 'jobLevels', 'jobTypes', 'industry', 'jobFilterTypes', 'jobFilterLevels', 'jobFilterSalaryRange', 'jobFilterRelocate', 'companyNames', 'matchMakerPopup'],
  components: {VueSlickCarousel},
  data() {
    return {
      settings: {
        'centerMode': true,
        'centerPadding': '20px',
        'focusOnSelect': true,
        'infinite': true,
        'slidesToShow': 1,
        'speed': 500,
        'adaptiveHeight': true,
        'arrows': false,
      },
      slides: this.activeJobListings.data,
      search: '',
      imageUrl: '../img/default_profile.png',
      image: '',
      searchListings: [],
      hasSearched: false,
      userCompany: [],
      userIndustry: [],
      userJobTypes: [],
      userJobLevels: [],
      userSalaryRange: [],
      userRelocate: [],
      prevView: '',
      currentPage: 0,
      lastPage: 0,
      filtered: false,
      jobListingIndex: '',
      readMoreActivated: false,
      swipeDir: '',
    };
  },
  mounted() {

  },
  methods: {
    matchMatch() {
      const activeJobListing = document.querySelector( '.slick-slide.slick-active div div' );
      const parentWithClass = activeJobListing.closest( '.slick-slide.slick-active' );
      axios.post( '/api/save-job-listing?q=' + activeJobListing.getAttribute( 'data-job-listing' ) + '&status=matched' )
          .then( ( response ) => {
            this.slides.splice( parentWithClass.getAttribute( 'data-index' ), 1 );
          } ).catch( ( error ) => {
            console.log( error );
            _this.hasSearched = false;
          } );
    },
    saveMatch() {
      const activeJobListing = document.querySelector( '.slick-slide.slick-active div div' );
      const parentWithClass = activeJobListing.closest( '.slick-slide.slick-active' );
      axios.post( '/api/save-job-listing?q=' + activeJobListing.getAttribute( 'data-job-listing' ) + '&status=saved' )
          .then( ( response ) => {
            this.slides.splice( parentWithClass.getAttribute( 'data-index' ), 1 );
          } ).catch( ( error ) => {
            console.log( error );
            _this.hasSearched = false;
          } );
    },
    userMatchMakerPopup() {
      axios.post( '/api/account/update-match-maker-popup' )
          .then( ( response ) => {
            const matchMakerPopup = document.querySelector( '.match-maker__popup' );
            matchMakerPopup.style.display = 'none';
          } ).catch( ( error ) => {
            console.log( error );
            _this.hasSearched = false;
          } );
    },
    activateReadMore() {
      this.readMoreActivated = true;
    },
    handleSwipe( direction ) {
      if ( direction == 'left' ) {
        // $( '.match-match' ).click();
        this.swipeDir = 'right';
      }
      if ( direction == 'right' ) {
        // $( '.save-match' ).click();
        this.swipeDir = 'left';
      }
    },
    handleAfterChange( event, slick, currentSlide, previousSlide, direction ) {
      this.readMoreActivated = false;
    },
    handleBeforeChange( event, slick, currentSlide ) {
      this.readMoreActivated = false;
    },
    reInit() {
      this.$nextTick( () => {
        this.$refs.slick.reSlick();
        this.readMoreActivated = false;
      } );
    },
    handleInit( event, slick ) {
      this.readMoreActivated = false;
    },
    handleReInit( event, slick ) {
      this.readMoreActivated = false;
    },
    showNext() {
      this.$refs.carousel.next();
    },
    showPrev() {
      this.$refs.carousel.prev();
    },
    formatDate: function( date ) {
      return moment( date ).fromNow();
    },
    resetForm( ) {
      $( '#search-filters__form' )[0].reset();
    },
  },
};
</script>

