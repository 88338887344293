<template>
    <div>
        <div class="mb-8">
            <!-- <h2 class="hdg-5">Select Your Plan</h2> -->

            <ul>
                <li class="py-8 border-b border-gray-300">
                    <label class="account-billing__radio" for="trial_plan">
                        <span v-if="data.student_trial.plan === 'BASIC_SUBSCRIPTION_ID'" class="account-billing__details-col">
                            <span class="block mb-2 leading-tight"><span class="font-bold text-xl">Free Year Trial</span> <span class="font-bold text-xl line-through">$4.99</span> <span class="inline-block">/ month</span></span>
                            <span class="block paragraph-default leading-tight">1 resume with up to 3 cover letters</span>

                        </span>

                        <span v-if="data.student_trial.plan === 'STANDARD_SUBSCRIPTION_ID'" class="account-billing__details-col">
                            <span class="block mb-2 leading-tight"><span class="font-bold text-xl">Free Year Trial</span> <span class="font-bold text-xl line-through">$9.99</span> <span class="inline-block">/ month</span></span>
                            <span class="block paragraph-default leading-tight">2 resumes with up to 6 cover letters</span>

                        </span>

                        <span v-if="data.student_trial.plan === 'PRO_SUBSCRIPTION_ID'" class="account-billing__details-col">
                            <span class="block mb-2 leading-tight"><span class="font-bold text-xl">Free Year Trial</span> <span class="font-bold text-xl line-through">$14.99</span> <span class="inline-block">/ month</span></span>
                            <span class="block paragraph-default leading-tight">3 resumes with up to 9 cover letters</span>

                        </span>

                        <span class="account-billing__button-col">
                            <span class="account-billing__button-checked">
                                Selected <svg class="ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                            </span>
                        </span>
                    </label>
                    <input v-model="selectedPlan" class="hidden" id="trial_plan" type="radio" name="plan" :value="data.student_trial.plan">
                </li>
            </ul>

        </div>

        <div class="flex">
            <button class="flex-initial ml-auto btn-green" id="add-card-button" v-on:click="startSubscription()">
                Start Free Trial
            </button>
        </div>

    </div>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      selectedPlan: this.data.student_trial.plan,
      isRunning: false,
    };
  },
  mounted() {
  },
  methods: {
    startSubscription() {
      const _this = this;

      if ( ! _this.isRunning ) {
        _this.isRunning = true;

        axios.put( '/api/account/trial', {
          subscriptionPlan: _this.selectedPlan,
        } ).then( function( response ) {
          window.location.href = '/profile';
        } );
      }
    },
  },
};
</script>
