export default {
  props: ['token', 'intent'],
  data() {
    return {
      stripe: undefined,
      elements: undefined,
      card: undefined,
      stripeOptions: {
        // see https://stripe.com/docs/stripe.js#element-options for details
      },
    };
  },
  mounted: function() {
    this.$nextTick( function() {
      this.stripe = new Stripe( this.token );
      this.elements = this.stripe.elements();
      this.card = this.elements.create( 'card' );
      if ( $( '#card-element' ).length ) {
        this.card.mount( '#card-element' );
      }
    } );
  },
};
