<template>
    <div class="form-group">
        <label v-if="label" for="company_">{{ label }}</label>

        <div class="form-group__input">
            <editor
              v-model="llValue"
              :init="tinymceConfig"
            >
            </editor>
        </div>
    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue';

export default {
  props: ['value', 'label', 'assetPath', 'height'],
  data() {
    return {
      tinymceConfig: {
        height: this.height,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap',
          'searchreplace visualblocks code fullscreen',
          'print preview anchor insertdatetime media',
          'paste code help wordcount table',
          'contextmenu',
        ],
        toolbar: 'undo redo | copy paste pastetext | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | help code',
        skin: 'oxide',
        contextmenu: 'cut copy paste link',
        content_css: [this.assetPath + 'css/app.css'],
        // theme: 'oxide',
      },
      llValue: this.value,
    };
  },
  components: {
    editor: Editor,
  },
  mounted() {
    if ( ! this.value ) {
      this.value = '';
    }
  },
  watch: {
    llValue() {
      this.$emit( 'input', this.llValue );
    },
  },
};
</script>
