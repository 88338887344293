<template>
    <div>
      <div :class="'find-candidates__header flex items-center pb-4 ' + prevView">
        <span class="text-xs text-gray-500">{{appliedJobs.to}} of {{appliedJobs.total}} results</span>
      </div>

      <div class="h-auto px-8 box">
        <div :class="'find-candidates__card-wrap ' + prevView">
          <div class="flex pb-3 font-medium text-gray-500 uppercase border-b border-gray-300 table-heading text-4xs leading-lenient tracking-spaced">
            <div class="w-1/12"></div>
            <div class="w-3/12">Name</div>
            <div class="w-3/12">Quick Links</div>
            <div class="w-2/12">Match</div>
            <div class="w-2/12">Send Next Steps</div>
            <div class="pl-2">Save</div>
          </div>
          <div :class="'find-candidates__body ' + prevView" v-for="(applicant, applicantIndex) in theAppliedJobs.data" :key="applicantIndex">
            <div class="candidate-selector">

            </div>
            <div>
              <div class="profile__image" v-if="applicant.photo" :style="{ backgroundImage: 'url(\'/uploads/' + applicant.photo.path + '\')' }"></div>
              <div class="profile__image" v-else :style="{ backgroundImage: 'url(\'/uploads/' + imageUrl + '\')' }"></div>
            </div>
            <div class="candidate-highlevel">
              <span class="text-xs text-gray-700 candidate__name"><a :href="'/applicant/'+applicant.active_resume.share_code">{{ applicant.applicant.full_name }}</a></span>
            </div>
            <div class="text-xs text-gray-500 candidate-job__title">
              <a :href="'/view-application/'+applicant.job_listing_id+'/'+applicant.user_id+'/employer'" class="underline">Application</a> |
              <a :href="'/applicant/'+applicant.active_resume.share_code" class="underline">Profile</a> |
              <a :href="'/applicant/'+applicant.active_resume.share_code" class="underline">Resume</a>
            </div>
            <div class="job-match"><span :class="'job-match__'+applicant.personal_values">{{ applicant.personal_values }}</span></div>
            <div class="candidate-card__footer">
              <div class="job-apply-now">
                <div :class="'next-steps-send-'+applicant.user_id+'_'+applicant.job_listing_id" v-if="applicant.send_next_steps.length < 1">
                  <svg class="mr-1 icon icon-Shape text-blue"><use xlink:href="#icon-Shape"></use></svg>
                  <a @click="handleNextSteps(applicant.user_id, applicant.job_listing_id)" class="text-xs font-bold underline cursor-pointer text-blue">Send Next Steps</a>
                </div>
                <div :class="'next-steps-sent-'+applicant.user_id+'_'+applicant.job_listing_id" v-else>
                  <svg class="mr-1 text-gray-500 icon icon-applied"><use xlink:href="#icon-applied"></use></svg>
                  <span class="text-xs font-bold text-gray-500 underline">Sent</span>
                </div>
              </div>
              <div class="cursor-pointer candidate-save" @click="handleSaveCandidate(applicant.applicant.id)" v-if="!applicant.saved">
                <svg :class="'icon icon-savestar mr-1 star-'+ applicant.applicant.id"><use xlink:href="#icon-savestar"></use></svg>
                <span :class="'text-xs underline text-gray-500 font-bold save-'+ applicant.applicant.id">Save</span>
              </div>
              <div class="candidate-save text-green" v-else>
                <svg :class="'icon icon-savestar mr-1 star-'+ applicant.applicant.id"><use xlink:href="#icon-savestar"></use></svg>
                <span :class="'text-xs font-bold save-'+ applicant.applicant.id">Saved</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-6 row find-candidates__paginate">
        <div class="w-full col">
          <div class="flex justify-between">
            <div class="flex">
              <a :href="appliedJobs.prev_page_url" :class="appliedJobs.prev_page_url != null ? 'flex search-prev' : 'flex disabled text-gray-500 search-prev'">
                <svg class="w-6 h-6 icon icon-pagination page-prev"><use xlink:href="#icon-pagination"></use></svg>
                <span class="my-auto ml-4 text-sm">Previous</span>
              </a>
            </div>
            <div class="flex">
              <a class="mr-3" v-for="(applicant, applicantIndex) in appliedJobs.last_page"
                :key="applicantIndex"
                :href="appliedJobs.path + '?page='+(applicantIndex+1)">
                <span :class="(applicantIndex+1 == appliedJobs.current_page)?'underline text-gray-700':'text-gray-500'">{{applicantIndex + 1}}</span>
              </a>
            </div>
            <div class="">
              <a :href="appliedJobs.next_page_url" :class="appliedJobs.next_page_url != null ? 'flex search-next' : 'flex search-next disabled text-gray-500'">
                <span class="my-auto mr-3 text-sm">Next</span>
                <svg class="w-6 h-6 icon icon-pagination"><use xlink:href="#icon-pagination"></use></svg>
              </a>
            </div>
          </div>
        </div>
      </div>


    </div>
</template>

<script>
import * as moment from 'moment-timezone';
export default {
  props: ['appliedJobs', 'jobTypes', 'jobLevels', 'industry', 'viewMode'],
  data() {
    return {
      search: '',
      imageUrl: '../img/default_profile.png',
      isRunning: false,
      prevView: this.viewMode + 'view',
      theAppliedJobs: this.appliedJobs,
      currentPage: this.appliedJobs.current_page,
      lastPage: this.appliedJobs.last_page,
      hasSearched: false,
      userId: this.appliedJobs.currentUser,
    };
  },
  mounted() {

  },
  methods: {
    formatDate: function( date ) {
      return moment( date ).format( 'MM/DD/YYYY' );
    },
    handleApiCall: function( url, filtered ) {
      const _this = this;

      axios.get( url )
          .then( ( response ) => {
            _this.hasSearched = true;
            _this.theAppliedJobs = response.data;
            _this.currentPage = response.data.current_page;
            _this.lastPage = response.data.last_page;
            $( '.no-results-found' ).empty();
            $( '.box' ).css( {'opacity': 1, 'pointer-events': 'all'} );
            $( '.find-candidates__header' ).css( {'opacity': 1, 'pointer-events': 'all'} );
            $( '.find-candidates__paginate' ).css( 'opacity', 1 );
            if ( filtered ) {
              $( '.filter-controls__close' ).click();
            }
          } ).catch( ( error ) => {
            console.log( error );
            _this.theAppliedJobs = this.appliedJobs;
            _this.hasSearched = false;
            $( '.no-results-found' ).html( 'No results found.' );
            $( '.box' ).css( {'opacity': 0, 'pointer-events': 'none'} );
            $( '.find-candidates__paginate' ).css( 'opacity', 0 );
            $( '.find-candidates__header' ).css( {'opacity': 0, 'pointer-events': 'none'} );
          } );
    },
    handleNextSteps: function( applicantId, jobListing ) {
      const _this = this;
      axios.post( '/api/send-next-steps?candidateId='+applicantId+'&jobListingId='+jobListing )
          .then( ( response ) => {
            $( '.invite-to-apply__modal' ).toggleClass( 'hidden' );
            $( '.next-steps-send-'+applicantId+'_'+jobListing ).html( '<svg class="mr-1 text-gray-500 icon icon-applied"><use xlink:href="#icon-applied"></use></svg><span class="text-xs font-bold text-gray-500 underline">Sent</span>' );
          } ).catch( ( error ) => {
            console.log( error );
          } );
    },
    // handleApplicantSearch( direction ) {
    //   const _this = this;
    //   if ( document.querySelector( '.find-candidates__header' ).classList.contains( 'listview' ) ) {
    //     _this.prevView = 'listview';
    //   } else {
    //     _this.prevView = 'cardview';
    //   }
    //   let url = '';
    //   if ( _this.currentPage < _this.lastPage && direction === 'next' ) {
    //     url = '/api/search-candidates?q=' + _this.search + '&currentPage=' + _this.currentPage;
    //     _this.handleApiCall( url, false );
    //   }
    //   if ( direction === 'prev' && !document.querySelector( '.search-prev' ).classList.contains( 'disabled' ) ) {
    //     url = '/api/search-candidates?q=' + _this.search + '&currentPage=' + ( _this.currentPage ) + '&previousPage=1';
    //     _this.handleApiCall( url, false );
    //   }
    //   if ( direction === 'search' ) {
    //     url = '/api/search-candidates?q=' + _this.search + '&currentPage=0';
    //     _this.handleApiCall( url, false );
    //   }
    // },
    handleSearchJump( page ) {
      const _this = this;
      if ( document.querySelector( '.find-candidates__header' ).classList.contains( 'listview' ) ) {
        _this.prevView = 'listview';
      } else {
        _this.prevView = 'cardview';
      }
      if ( !_this.hasSearched ) {
        _this.currentPage = 1;
      }
      if ( _this.currentPage >= 1 ) {
        const url = '/api/search-candidates?q=' + _this.search + '&currentPage=' + ( page );
        _this.handleApiCall( url, false );
      }
    },
    handleSaveCandidate( candidateId ) {
      axios.post( '/api/save-candidates/' + candidateId )
          .then( ( response ) => {
            $( '.save-' + candidateId ).html( 'Saved' ).css( 'color', '#6FCB6F' );
            $( '.star-' + candidateId ).css( 'color', '#6FCB6F' );
          } ).catch( ( error ) => {
            console.log( error );
          } );
    },
    updateCompany( company ) {
      this.userCompany = company;
    },
    updateIndustry( industry ) {
      this.userIndustry = industry;
    },
    updateJobTypes( jobTypes ) {
      this.userJobTypes = jobTypes;
    },
    updateJobLevels( jobLevels ) {
      this.userJobLevels = jobLevels;
    },
    updateSalaryRange( salaryRange ) {
      this.userSalaryRange = salaryRange;
    },
    updateRelocate( relocate ) {
      this.userRelocate = relocate;
    },
  },
};
</script>
