<template>
    <div>
      <div class="h-auto px-8 box">
        <div :class="'find-jobs__header flex items-center pb-4 text-4xs text-gray-500'" v-if="activeJobListings.total > 0">
            <div class="hidden w-7/12 pl-16 ml-2 uppercase lg:block">Name</div>
            <div class="hidden w-2/12 pl-4 uppercase lg:block">Match</div>
            <span class="ml-auto text-xs">{{activeJobListings.to}} of {{activeJobListings.total}} results</span>
        </div>
        <div class="find-jobs__card-wrap" v-if="activeJobListings.total > 0">
          <div class="find-jobs__body" v-for="(jobListing, jobListingIndex) in activeJobListings.data" :key="jobListingIndex">

            <div class="profile__image" v-if="jobListing.company_logo" :style="{ backgroundImage: 'url(\'/uploads/' + jobListing.company_logo.path + '\')' }"></div>
            <div class="profile__image" v-else :style="{ backgroundImage: 'url(\'' + imageUrl + '\')' }"></div>
            <div class="job-highlevel">
              <div class="job-title-sponsored"><a :href="'/apply-now/'+jobListing.job_listing_id" class="text-xs text-gray-700 job__title">{{ jobListing.listing_title }}</a><span class="text-gray-400 uppercase job__sponsored text-3xs" v-if="jobListing.sponsored_listing">Sponsored</span></div>
              <div class="text-xs text-gray-500"><a :href="'/u/' + jobListing.lister">{{ jobListing.company }}</a> | {{ jobListing.location }}</div>
            </div>
            <div class="job-match"><span :class="'job-match__'+jobListing.personal_values">{{ jobListing.personal_values }}</span></div>
            <div class="job-card__footer">
              <div class="job-apply-now">
                <svg class="mr-1 icon icon-Shape text-blue"><use xlink:href="#icon-Shape"></use></svg>
                <a :href="'/apply-now/'+jobListing.job_listing_id" class="text-xs font-bold underline cursor-pointer text-blue">Apply Now</a>
              </div>
              <div class="job-save" v-if="performAction === 'saved'" @click="handleUserJobApplicationRemove(jobListing.job_listing_id)">
                <span class="text-xs font-bold text-gray-500 underline cursor-pointer">Remove</span>
              </div>
              <div v-if="performAction === 'invite'" class="cursor-pointer job-save" @click="handleUserJobApplicationSave(jobListing.job_listing_id)">
                <svg class="mr-1 icon icon-savestar"><use xlink:href="#icon-savestar"></use></svg>
                <span class="text-xs font-bold text-gray-500 underline cursor-pointer">Save</span>
              </div>
              <div v-if="performAction === 'match'" class="cursor-pointer job-save" @click="handleUserJobApplicationSave(jobListing.id)">
                <svg class="mr-1 icon icon-savestar"><use xlink:href="#icon-savestar"></use></svg>
                <span class="text-xs font-bold text-gray-500 underline cursor-pointer">Save</span>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-4" v-else>There are no results. Please try again.</div>
      </div>

      <div class="mt-6 row find-jobs__paginate">
        <div class="w-full col">
          <div class="flex justify-between">
            <div class="flex">
              <a :href="activeJobListings.prev_page_url" :class="activeJobListings.prev_page_url != null ? 'flex' : 'flex disabled text-gray-500'">
                <svg class="w-6 h-6 icon icon-pagination page-prev"><use xlink:href="#icon-pagination"></use></svg>
                <span class="my-auto ml-4 text-sm">Previous</span>
              </a>
            </div>
            <div class="flex">
              <a class="mr-3" v-for="(jobListing, jobListingIndex) in activeJobListings.last_page"
                :key="jobListingIndex"
                :href="activeJobListings.path + '?page=' + (jobListingIndex + 1)">
                <span :class="(jobListingIndex+1 == activeJobListings.current_page)?'underline text-gray-700':'text-gray-500'">{{jobListingIndex + 1}}</span>
              </a>
            </div>
            <div class="">
              <a  :href="activeJobListings.next_page_url" :class="activeJobListings.next_page_url != null ? 'flex search-next' : 'flex search-next disabled text-gray-500'">
                <span class="my-auto mr-3 text-sm">Next</span>
                <svg class="w-6 h-6 icon icon-pagination"><use xlink:href="#icon-pagination"></use></svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import * as moment from 'moment-timezone';
export default {
  props: ['activeJobListings', 'performAction', 'assetPath', 'viewMode', 'employmentType', 'industries', 'jobLevels', 'jobTypes', 'industry', 'jobFilterTypes', 'jobFilterLevels', 'jobFilterSalaryRange', 'jobFilterRelocate', 'companyNames'],
  data() {
    return {
      search: '',
      imageUrl: '../img/default_profile.png',
      image: '',
      searchListings: [],
      hasSearched: false,
      userCompany: [],
      userIndustry: [],
      userJobTypes: [],
      userJobLevels: [],
      userSalaryRange: [],
      userRelocate: [],
      prevView: '',
      currentPage: 0,
      lastPage: 0,
      filtered: false,
      jobListingIndex: '',
    };
  },
  mounted() {
    this.setView();
  },
  methods: {
    setView: function() {
      const windowsize = $( window ).width();
      if ( windowsize <= 828 ) {
        $( '.view-selector' ).hide();
        $( '.find-jobs__card' ).attr( 'data-toggle-is-active', true );
        $( '.find-jobs__list' ).removeAttr( 'data-toggle-is-active' );
      }
    },
    formatDate: function( date ) {
      return moment( date ).format( 'MM/DD/YYYY' );
    },
    handleUserJobApplicationSave( id ) {
      const _this = this;
      const saveJobs = document.querySelectorAll( '.save-listing:checked' );
      const ids = [];
      ids.push( id );
      console.log( id );
      if ( saveJobs.length > 0 ) {
        saveJobs.forEach( function( element, index ) {
          ids.push( element.value );
        } );
      }
      if ( document.querySelector( '.find-jobs__header' ).classList.contains( 'listview' ) ) {
        _this.prevView = 'listview';
      } else {
        _this.prevView = 'cardview';
      }

      axios.post( '/api/save-job-listing?q=' + ids + '&status=saved' )
          .then( ( response ) => {
          } ).catch( ( error ) => {
            console.log( error );
            _this.hasSearched = false;
          } );
    },
    handleUserJobApplicationRemove( id ) {
      const _this = this;
      const saveJobs = document.querySelectorAll( '.save-listing:checked' );
      const ids = [];
      ids.push( id );
      saveJobs.forEach( function( element, index ) {
        ids.push( element.value );
      } );
      if ( document.querySelector( '.find-jobs__header' ).classList.contains( 'listview' ) ) {
        _this.prevView = 'listview';
      } else {
        _this.prevView = 'cardview';
      }

      axios.delete( '/api/user-job-listing/delete/?q=' + ids + '&action=' + this.performAction )
          .then( ( response ) => {
            window.location.href = '/my-job-listings';
          } ).catch( ( error ) => {
            console.log( error );
            _this.hasSearched = false;
          } );
    },
    resetForm( ) {
      $( '#search-filters__form' )[0].reset();
    },
  },
};
</script>
