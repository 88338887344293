<template>
    <form method="POST" class="form-skin" @submit.prevent="handleSubmit">
        <div class="absolute right-0 flex justify-center hidden w-20 h-8 my-auto text-xl text-white saved-banner bg-green">Saved!</div>
        <div class="mb-5">
            <div class="items-center row">
                <div class="w-full mb-3 col sm:w-auto sm:mb-0">
                    <h1 v-if="formData.job_listing === false" class="hdg-4">New Job Listing</h1>
                    <h1 v-else class="mb-3 hdg-4 lg:mb-0">Edit Job Listing</h1>
                </div>
                <div class="flex w-full ml-auto col sm:w-auto">
                    <!-- <div v-if="formData.job_listing !== false"  class="flex-initial px-2 py-1">
                        <a :href="'/job-listings/' + formData.job_listing.id + '/preview'" target="_blank" class="btn-preview">Preview</a>
                    </div> -->
                    <div v-if="formData.job_listing === false" class="flex-initial px-2 py-1">
                        <button @click="handleSaveDraft" type="submit" name="button" class="btn-save-draft">Save Draft</button>
                    </div>
                    <div v-if="formData.job_listing === false" class="flex-initial px-2 py-1">
                        <button @click="handlePublish" type="submit" name="button" class="btn-save">Publish</button>
                    </div>
                    <div v-if="formData.job_listing === false" class="flex-initial px-2 py-1">
                        <a href="/job-listings" class="btn-delete"><svg class="icon icon-trash"><use xlink:href="#icon-trash"></use></svg>Delete</a>
                    </div>
                    <div v-if="formData.job_listing !== false" class="flex-initial px-2 py-1">
                        <button @click="handleSaveDraft" type="submit" name="button" class="btn-save-draft">Save as Draft</button>
                    </div>
                    <div v-if="formData.job_listing !== false"  class="flex-initial px-2 py-1">
                        <button @click="handlePublish" type="submit" name="button" class="btn-save">Save Changes</button>
                    </div>
                    <div v-if="formData.job_listing !=- false"  class="flex-initial px-2 py-1">
                        <button @click="handleDelete( formData.job_listing.id )" type="button" name="button" class="btn-delete"><svg class="icon icon-trash"><use xlink:href="#icon-trash"></use></svg>Delete</button>
                    </div>
                </div>
            </div>
        </div>

        <div class="mb-8 form-group">
            <label for="title">Job Title</label>

            <div class="form-group__input">
                <input v-model="formData.listing_title" id="listing_title" type="text" class="" name="listing_title" placeholder="Job Title" required autofocus>
            </div>
        </div>

        <div class="row">
            <div class="w-full mb-8 col">
                <div class="flex items-center justify-between box box--sponsored border-green">
                    <div class="w-full lg:w-1/2">
                        <p class="mb-2 hdg-6">Sponsor Listing</p>
                        <p class="">Sponsor this listing for more visibility</p>
                    </div>
                    <div class="w-full lg:w-1/2">
                        <div class="flex justify-end">
                            <label for="sponsored_listing">
                              <span class="account-billing__button-col" v-if="formData.at_sponsored_listing_limit && !formData.sponsored_listing">
                                  <a href="/account/subscription" class="bg-gray-300 border-gray-300 account-billing__button-unchecked">
                                      Upgrade Plan
                                  </a>
                              </span>
                              <span class="account-billing__button-col" v-else>
                                  <span v-if="formData.sponsored_listing" class="account-billing__button-checked">
                                      Sponsored <svg class="flex-shrink-0 ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                                  </span>
                                  <span v-else class="account-billing__button-unchecked">
                                      Not Sponsored <svg class="flex-shrink-0 ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                                  </span>
                              </span>
                            </label>
                            <input v-model="formData.sponsored_listing" id="sponsored_listing" type="checkbox" class="hidden" name="sponsored_listing">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="w-full col">
                <div class="box box--lg">
                    <div class="row">
                        <div class="w-full col">

                            <div class="mb-12">
                                <job-listing-details
                                    :job-listing-details="formData.details"
                                    :industry="formData.industry_options"
                                    :job-level="formData.job_level_options"
                                    :employment-type="formData.employment_type_options"
                                    @update="updateDetails"></job-listing-details>
                            </div>

                            <div class="mb-12">
                                <job-listing-benefits :job-listing-benefits="formData.benefits" @update="updateBenefits"></job-listing-benefits>
                            </div>

                            <div class="mb-12">
                                <job-listing-videos :job-listing-videos="formData.videos" :asset-path="assetPath" @update="updateVideos"></job-listing-videos>
                            </div>

                            <div class="mb-12">
                                <job-listing-pre-interview-questions :job-listing-pre-interview-questions="formData.pre_interview_questions" @update="updatePreInterviewQuestions"></job-listing-pre-interview-questions>
                            </div>

                            <div class="mb-12">
                                <job-listing-next-steps-email :job-listing-next-steps-email="formData.next_steps_email" @update="updateNextStepsEmail"></job-listing-next-steps-email>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-12">
            <div class="items-center row">
                <div class="flex w-full ml-auto col sm:w-auto">
                    <div v-if="formData.job_listing === false" class="flex-initial px-2 py-1">
                        <button @click="handleSaveDraft" type="submit" name="button" class="btn-save-draft">Save Draft</button>
                    </div>
                    <div v-if="formData.job_listing === false" class="flex-initial px-2 py-1">
                        <button @click="handlePublish" type="submit" name="button" class="btn-save">Publish</button>
                    </div>
                    <div v-if="formData.job_listing === false" class="flex-initial px-2 py-1">
                        <a href="/job-listings" class="btn-delete"><svg class="icon icon-trash"><use xlink:href="#icon-trash"></use></svg>Delete</a>
                    </div>
                    <div v-if="formData.job_listing !== false" class="flex-initial px-2 py-1">
                        <button @click="handleSaveDraft" type="submit" name="button" class="btn-save-draft">Save Draft</button>
                    </div>
                    <div v-if="formData.job_listing !== false"  class="flex-initial px-2 py-1">
                        <button @click="handlePublish" type="submit" name="button" class="btn-save">Save Changes</button>
                    </div>
                    <div v-if="formData.job_listing !=- false"  class="flex-initial px-2 py-1">
                        <button @click="handleDelete( formData.job_listing.id )" type="button" name="button" class="btn-delete"><svg class="icon icon-trash"><use xlink:href="#icon-trash"></use></svg>Delete</button>
                    </div>
                </div>
            </div>
        </div>

    </form>
</template>

<script>
export default {
  props: ['data', 'assetPath'],

  data() {
    return {
      action: '',
      errors: [],
      isRunning: false,
      formData: this.data,
      perform: '',
    };
  },
  mounted() {
    const searchParams = new URLSearchParams( window.location.search );
    if ( searchParams.get( 'saved' ) === 'true' ) {
      $( '.saved-banner' ).removeClass( 'hidden' );
      setTimeout( () => {
        $( '.saved-banner' ).fadeOut();
      }, '5000' );
      window.history.pushState( {}, document.title, '/job-listings' );
    }
    if ( this.formData.job_listing ) {
      this.action = '/api/job-listing/update';
    } else {
      this.action = '/api/job-listing/create';
    }
  },
  methods: {
    handleSaveDraft() {
      this.handleSubmit( 'draft' );
    },
    handlePublish() {
      this.handleSubmit( 'published' );
    },
    handleSubmit( perform ) {
      const _this = this;
      if ( this.isRunning === false ) {
        // Validate inputs
        _this.validateInputs();
        $( '.btn-save' ).attr( 'disabled', true );
        if ( _this.errors.length === 0 ) {
          // Submit form
          axios.post( _this.action, {
            data: _this.formData,
            perform: perform,
          } )
              .then( ( response ) => {
                // console.log( response );
                $( '.btn-save' ).attr( 'disabled', false );
                window.location.href = '/job-listings';
              } ).catch( ( error ) => {
                console.log( error );
                _this.isRunning = false;
              } );
        } else {
          _this.isRunning = false;
          $( '.btn-save' ).attr( 'disabled', false );
          this.errors = [];
        }
      }
    },
    validateInputs() {
      if ( this.formData.listing_title
            && this.formData.details[0].description
            // && this.formData.benefits[0].benefit_name
            && this.formData.preInterviewQuestions[0].question_text
            && this.formData.nextStepsEmail[0].email_subject
            && this.formData.nextStepsEmail[0].email_body ) {
        return true;
      }

      this.errors = [];

      if ( !this.formData.listing_title ) {
        this.errors.push( 'Listing title is required.' );
      }
      if ( !this.formData.details[0].description ) {
        this.errors.push( 'Listing description is required.' );
      }
      if ( !this.formData.preInterviewQuestions[0].question_text ) {
        this.errors.push( 'Pre-Interview questions required.' );
      }
      if ( !this.formData.nextStepsEmail[0].email_subject ) {
        this.errors.push( 'Next steps email subject required.' );
      }
      if ( !this.formData.nextStepsEmail[0].email_body ) {
        this.errors.push( 'Next steps email body required.' );
      }
    },
    handleDelete( id ) {
      const _this = this;

      if ( this.isRunning === false ) {
        // Validate inputs
        _this.validateInputs();

        if ( _this.errors.length === 0 ) {
          // Submit form
          axios.delete( '/job-listings/' + id )
              .then( ( response ) => {
                window.location.href = '/job-listings';
              } ).catch( ( error ) => {
                console.log( error );
                _this.isRunning = false;
              } );
        } else {
          _this.isRunning = false;
        }
      }
    },
    updateDetails( details ) {
      this.formData.details = details;
    },
    updateBenefits( benefits ) {
      this.formData.benefits = benefits;
    },
    updateVideos( videos ) {
      this.formData.videos = videos;
    },
    updatePreInterviewQuestions( preInterviewQuestions ) {
      this.formData.preInterviewQuestions = preInterviewQuestions;
    },
    updateNextStepsEmail( nextStepsEmail ) {
      this.formData.nextStepsEmail = nextStepsEmail;
    },
  },
};
</script>
