<template>
    <div>
        <p class="hdg-6 mb-4">Portfolio</p>

        <div v-if="portfolios.length > 0" class="mb-8">
            <div v-for="(portfolio, portfolioIndex) in portfolios" :key="portfolioIndex" class="relative box bg-bgblue mb-8">
                <button class="absolute right-0 top-0 p-4 hover:text-error" @click="removePortfolio( portfolioIndex )" type="button" :name="'remove_portfolio_' + portfolioIndex"><svg class="block text-lg icon icon-minus"><use xlink:href="#icon-minus"></use></svg></button>

                <div class="form-group">
                    <label :for="'portfolio_title_' + portfolioIndex">Title</label>

                    <div class="form-group__input">
                        <input v-model="portfolio.title" :id="'portfolio_title_' + portfolioIndex" type="text" class="" :name="'portfolio_title_' + portfolioIndex" required>
                    </div>
                </div>

                <div v-for="(portfolioMedia, portfolioMediaIndex) in portfolio.portfolio_media" :key="portfolioMediaIndex" class="box mb-4 py-6">

                    <div class="form-portfolio mb-4">
                        <div v-if="portfolioMedia.image_path && !portfolioMedia.changed_image" class="portfolio__image" :style="{ backgroundImage: 'url(\'' + assetPath + 'uploads/' + portfolioMedia.image_path + '\')' }"></div>
                        <div v-if="portfolioMedia.image_path && portfolioMedia.changed_image" class="portfolio__image" :style="{ backgroundImage: 'url(\'' + portfolioMedia.image_path + '\')' }"></div>

                        <button @click="clickFileInput( portfolioIndex, portfolioMediaIndex )" class="btn-dashed mt-5" type="button" name="add_photo">
                            <svg class="icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>
                            <span v-if="portfolioMedia.image_path">Change Image</span>
                            <span v-else>Add Image</span>
                        </button>

                        <div class="form-group__input hidden">
                            <input class="hidden" :id="'portfolio_image_' + portfolioIndex + '_' + portfolioMediaIndex" :name="'portfolio_image_' + portfolioIndex + '_' + portfolioMediaIndex" type="file" accept="image/jpeg, image/gif, image/png" @change="onFileChange( $event, portfolioIndex, portfolioMediaIndex )" :required="!portfolioMedia.image_path" />
                        </div>
                    </div>

                    <div class="form-group">
                        <label :for="'portfolio_image_title_' + portfolioIndex + '_' + portfolioMediaIndex">Image Title</label>

                        <div class="form-group__input">
                            <input v-model="portfolioMedia.image_title" :id="'portfolio_image_title_' + portfolioIndex + '_' + portfolioMediaIndex" type="text" class="" :name="'portfolio_image_title_' + portfolioIndex + '_' + portfolioMediaIndex" required>
                        </div>
                    </div>

                    <div class="form-group mb-0">
                        <label :for="'portfolio_image_caption_' + portfolioIndex + '_' + portfolioMediaIndex">Image Caption</label>

                        <div class="form-group__input">
                            <input v-model="portfolioMedia.image_caption" :id="'portfolio_image_caption_' + portfolioIndex + '_' + portfolioMediaIndex" type="text" class="" :name="'portfolio_image_caption_' + portfolioIndex + '_' + portfolioMediaIndex">
                        </div>
                    </div>

                </div>

                <button @click="addPortfolioImage( portfolioIndex )" type="button" name="add_portfolio" class="btn-dashed"><svg class="icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>Add Additional Image</button>

            </div>
        </div>

        <div v-if="resumePortfolios.length > 0" class="relative">
            <button type="button" name="add_portfolio" class="popover__button btn-dashed" data-toggle-class="is-open" data-toggle-outside data-toggle-target="#add-portfolio-popup"><svg class="pointer-events-none icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>Add Portfolio</button>

            <div class="popover__popup hidden absolute left-0 bottom-100 w-full" id="add-portfolio-popup" role="navigation">
                <div class="popover__popup-box">
                    <div>
                        <button @click="addPortfolio( false )" data-toggle-trigger-off class="flex w-full items-center text-left text-gray-500 text-sm font-medium hover:text-blue py-3" type="button">Add New Portfolio<svg class="ml-auto pointer-events-none block text-lg icon mr-2 icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg></button>
                    </div>

                    <div v-for="(portfolio, portfolioIndex) in resumePortfolios" :key="portfolioIndex">
                        <button v-if="!isInUse( portfolio.id )" :data-id="portfolioIndex" @click="addPortfolio( portfolioIndex )" data-toggle-trigger-off class="flex w-full items-center text-left text-gray-500 text-sm font-medium hover:text-blue py-3 border-t" type="button">{{ portfolio.title }}</button>
                    </div>
                </div>
            </div>
        </div>

        <button v-else @click="addPortfolio( false )" type="button" name="add_portfolio" class="btn-dashed"><svg class="icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>Add Portfolio</button>

    </div>
</template>

<script>
export default {
  props: ['assetPath', 'resumePortfolios'],
  data() {
    return {
      portfolios: [],
      year: new Date().getFullYear(),
    };
  },
  mounted() {
    this.setPortfolios();
  },
  methods: {
    setPortfolios: function() {
      if ( this.resumePortfolios.length > 0 ) {
        this.portfolios = Object.assign( [], this.resumePortfolios );
      } else {
        // this.addPortfolio( false );
      }
    },
    addPortfolio: function( id ) {
      if ( id !== false ) {
        this.portfolios.push(
            Object.assign( {}, this.resumePortfolios[id] )
        );
      } else {
        this.portfolios.push(
            {
              id: false,
              title: '',
              portfolio_media: [
                {
                  image_caption: '',
                  image_title: '',
                  image_path: '',
                  changed_image: true,
                },
              ],
            }
        );
      }
    },
    addPortfolioImage: function( id ) {
      this.portfolios[id].portfolio_media.push(
          {
            image_caption: '',
            image_title: '',
            image_path: '',
            changed_image: true,
          }
      );
    },
    removePortfolio: function( index ) {
      this.portfolios.splice( index, 1 );
    },
    isInUse: function( id ) {
      let isInUse = false;
      if ( id ) {
        $.each( this.portfolios, function( index, portfolio ) {
          if ( portfolio.id === id ) {
            isInUse = true;
          }
        } );
      }
      return isInUse;
    },
    onFileChange( e, portfolioIndex, portfolioMediaIndex ) {
      const files = e.target.files || e.dataTransfer.files;
      if ( !files.length ) {
        return;
      }
      this.createImage( files[0], portfolioIndex, portfolioMediaIndex );
    },
    createImage( file, portfolioIndex, portfolioMediaIndex ) {
      const reader = new FileReader();
      const _this = this;
      reader.onload = ( e ) => {
        // _this.image = e.target.result;
        console.log( 'Portfolio: ' + portfolioIndex );
        console.log( 'Image: ' + portfolioMediaIndex );
        _this.portfolios[portfolioIndex].portfolio_media[portfolioMediaIndex].image_path = e.target.result;
        _this.portfolios[portfolioIndex].portfolio_media[portfolioMediaIndex].changed_image = true;
        _this.portfolios[portfolioIndex].portfolio_media[portfolioMediaIndex].id = false;
      };
      reader.readAsDataURL( file );
    },
    clickFileInput: function( portfolioIndex, portfolioMediaIndex ) {
      $( '#portfolio_image_' + portfolioIndex + '_' + portfolioMediaIndex ).click();
    },
  },
  watch: {
    portfolios() {
      this.$emit( 'update', this.portfolios );
    },
  },
};
</script>
