<template>
    <div>
        <p class="hdg-6 mb-4">Work Experience</p>

        <div v-if="workExperiences.length > 0" class="mb-8">
            <div v-for="(workExperience, workExperienceIndex) in workExperiences" :key="workExperienceIndex" class="relative box bg-bgblue mb-8">
                <button class="absolute right-0 top-0 p-4 hover:text-error" @click="removeWorkExperience( workExperienceIndex )" type="button" :name="'remove_work_experience_' + workExperienceIndex"><svg class="block text-lg icon icon-minus"><use xlink:href="#icon-minus"></use></svg></button>

                <p v-if="workExperience.id !== false" class="text-3xs text-gray-500 mb-2 pr-4">You can edit this work experience data in <a href="/profile" class="underline text-blue hover:no-underline">your profile</a>.</p>

                <div class="form-group">
                    <label :for="'company_' + workExperienceIndex">Company</label>

                    <div class="form-group__input">
                        <input v-model="workExperience.company" :id="'company_' + workExperienceIndex" type="text" class="" :name="'company_' + workExperienceIndex" required :disabled="workExperience.id !== false">
                    </div>
                </div>

                <div class="form-group">
                    <label :for="'location_' + workExperienceIndex">Location</label>

                    <div class="form-group__input">
                        <input v-model="workExperience.location" :id="'location_' + workExperienceIndex" type="text" class="" :name="'location_' + workExperienceIndex" required :disabled="workExperience.id !== false">
                    </div>
                </div>

                <div class="form-group">
                    <label :for="'job_title_' + workExperienceIndex">Position/Job Title</label>

                    <div class="form-group__input">
                        <input v-model="workExperience.job_title" :id="'job_title_' + workExperienceIndex" type="text" class="" :name="'job_title_' + workExperienceIndex" required :disabled="workExperience.id !== false">
                    </div>
                </div>

                <div class="form-group">
                    <label :for="'employment_type_' + workExperienceIndex">Employment Type</label>

                    <div class="form-group__input form-group__input--icon">
                        <select v-model="workExperience.employment_type" :id="'employment_type_' + workExperienceIndex" class="" :name="'employment_type_' + workExperienceIndex" required :disabled="workExperience.id !== false">
                            <option value="Full Time">Full Time</option>
                            <option value="Part Time">Part Time</option>
                            <option value="Contract">Contract</option>
                            <option value="Temporary">Temporary</option>
                            <option value="Commission">Commission</option>
                            <option value="Internship">Internship</option>
                        </select>
                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                    </div>
                </div>

                <div class="form-check mb-5 mt-8">
                    <input v-model="workExperience.current_job" type="checkbox" :name="'current_job_' + workExperienceIndex" :id="'current_job_' + workExperienceIndex" :disabled="workExperience.id !== false">

                    <label :for="'current_job_' + workExperienceIndex">This is my current job</label>
                </div>

                <div class="flex -mx-2 flex-wrap">
                    <div class="flex-initial px-2 w-full xl:w-1/2 mb-5 xl:mb-0">
                        <div class="flex flex-wrap -mx-2">
                            <div class="flex-initial w-full px-2">
                                <p class="font-bold text-gray-500 mb-2">Start Date</p>
                            </div>

                            <div class="flex-initial w-1/2 px-2">
                                <div class="form-group mb-0">
                                    <label :for="'start_date_month_' + workExperienceIndex">Month</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select v-model="workExperience.start_date_month" :id="'start_date_month_' + workExperienceIndex" class="" :name="'start_date_month_' + workExperienceIndex" required :disabled="workExperience.id !== false">
                                            <option value=""></option>
                                            <option value="January">Jan</option>
                                            <option value="February">Feb</option>
                                            <option value="March">Mar</option>
                                            <option value="April">Apr</option>
                                            <option value="May">May</option>
                                            <option value="June">June</option>
                                            <option value="July">July.</option>
                                            <option value="August">Aug</option>
                                            <option value="September">Sept</option>
                                            <option value="October">Oct</option>
                                            <option value="November">Nov</option>
                                            <option value="December">Dec</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>
                            </div>

                            <div class="flex-initial w-1/2 px-2">
                                <div class="form-group mb-0">
                                    <label :for="'start_date_year_' + workExperienceIndex">Year</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select v-model="workExperience.start_date_year" :id="'start_date_year_' + workExperienceIndex" class="" :name="'start_date_year_' + workExperienceIndex" required :disabled="workExperience.id !== false">
                                            <option value=""></option>
                                            <option v-for="index in 50" :key="index" :value="year - index + 1">{{ year - index + 1}}</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="! workExperience.current_job" class="flex-initial px-2 w-full xl:w-1/2">
                        <div class="flex flex-wrap -mx-2">
                            <div class="flex-initial w-full px-2">
                                <p class="font-bold text-gray-500 mb-2">End Date</p>
                            </div>

                            <div class="flex-initial w-1/2 px-2">
                                <div class="form-group mb-0">
                                    <label :for="'end_date_month_' + workExperienceIndex">Month</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select v-model="workExperience.end_date_month" :id="'end_date_month_' + workExperienceIndex" class="" :name="'end_date_month_' + workExperienceIndex" required :disabled="workExperience.id !== false">
                                            <option value=""></option>
                                            <option value="January">Jan</option>
                                            <option value="February">Feb</option>
                                            <option value="March">Mar</option>
                                            <option value="April">Apr</option>
                                            <option value="May">May</option>
                                            <option value="June">June</option>
                                            <option value="July">July.</option>
                                            <option value="August">Aug</option>
                                            <option value="September">Sept</option>
                                            <option value="October">Oct</option>
                                            <option value="November">Nov</option>
                                            <option value="December">Dec</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>
                            </div>

                            <div class="flex-initial w-1/2 px-2">
                                <div class="form-group mb-0">
                                    <label :for="'end_date_year_' + workExperienceIndex">Year</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select v-model="workExperience.end_date_year" :id="'end_date_year_' + workExperienceIndex" class="" :name="'end_date_year_' + workExperienceIndex" required :disabled="workExperience.id !== false">
                                            <option value=""></option>
                                            <option v-for="index in 50" :key="index" :value="year - index + 1">{{ year - index + 1}}</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="relative">
            <button type="button" name="add_work_experience" class="popover__button btn-dashed" data-toggle-class="is-open" data-toggle-outside data-toggle-target="#add-work-experience-popup"><svg class="pointer-events-none icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>Add Work Experience</button>

            <div class="popover__popup hidden absolute left-0 bottom-100 w-full" id="add-work-experience-popup" role="navigation">
                <div class="popover__popup-box">
                    <div>
                        <button @click="addWorkExperience( false )" data-toggle-trigger-off class="flex w-full items-center text-left text-gray-500 text-sm font-medium hover:text-blue py-3" type="button">Add New Work Experience<svg class="ml-auto pointer-events-none block text-lg icon mr-2 icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg></button>
                    </div>

                    <div v-for="(workExperience, workExperienceIndex) in workExperienceOptions" :key="workExperienceIndex">
                        <button v-if="!isInUse( workExperience.id )" :data-id="workExperienceIndex" @click="addWorkExperience( workExperienceIndex )" data-toggle-trigger-off class="flex w-full items-center text-left text-gray-500 text-sm font-medium hover:text-blue py-3 border-t" type="button">{{ getWorkExperienceLabel( workExperience ) }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: ['userWorkExperiences', 'workExperienceOptions'],
  data() {
    return {
      workExperiences: [],
      year: new Date().getFullYear(),
    };
  },
  mounted() {
    this.setWorkExperiences();
  },
  methods: {
    setWorkExperiences: function() {
      if ( this.userWorkExperiences.length > 0 ) {
        this.workExperiences = Object.assign( [], this.userWorkExperiences );
      } else {
        this.addWorkExperience( false );
      }
    },
    addWorkExperience: function( id ) {
      if ( id !== false ) {
        this.workExperiences.push(
            Object.assign( {}, this.workExperienceOptions[id] )
        );
      } else {
        this.workExperiences.push(
            {
              id: false,
              resume_user_work_experience_id: false,
              company: '',
              location: '',
              job_title: '',
              employment_type: 'Full Time',
              current_job: false,
              start_date_month: '',
              start_date_year: '',
              end_date_month: '',
              end_date_year: '',
            }
        );
      }
    },
    removeWorkExperience: function( index ) {
      this.workExperiences.splice( index, 1 );
    },
    getWorkExperienceLabel: function( workExperience ) {
      let label = workExperience.company;

      if ( workExperience.start_date_month && workExperience.start_date_year ) {
        label += ' (' + workExperience.start_date_month + ' ' + workExperience.start_date_year + ' - ';

        if ( workExperience.current_job ) {
          label += 'Present)';
        } else {
          label += workExperience.end_date_month + ' ' + workExperience.end_date_year + ')';
        }
      }

      return label;
    },
    isInUse: function( id ) {
      let isInUse = false;
      if ( id ) {
        $.each( this.workExperiences, function( index, workExperience ) {
          if ( workExperience.id === id ) {
            isInUse = true;
          }
        } );
      }
      return isInUse;
    },
  },
  watch: {
    workExperiences() {
      this.$emit( 'update', this.workExperiences );
    },
  },
};
</script>
