<template>
    <div>

        <div v-if="error" class="alert alert-error">
            {{ error }}
        </div>

        <div v-if="success" class="alert alert-success">
            {{ success }}
        </div>

        <form class="form-skin" @submit.prevent="handleSubmit">

            <div class="row">
                <div class="col w-full md:w-1/2">
                    <div class="form-group">
                        <label for="first_name"><span v-if="data.user_role === 'employer'">Company</span><span v-if="data.user_role === 'applicant'">First</span> Name</label>

                        <div class="form-group__input">
                            <input v-model="firstName" id="first_name" type="text" name="first_name" required>
                        </div>
                    </div>
                </div>

                <div class="col w-full md:w-1/2" v-if="data.user_role === 'applicant'">
                    <div class="form-group">
                        <label for="last_name">Last Name</label>

                        <div class="form-group__input">
                            <input v-model="lastName" id="last_name" type="text" name="last_name" required>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label for="email">Email</label>

                <div class="form-group__input">
                    <input v-model="email" id="email" type="email" name="email" required>
                </div>
            </div>

            <div class="row mt-12">
                <div class="col w-full sm:w-1/2 mb-5 sm:mb-0 ml-auto">
                    <button class="btn-green-sm w-full" type="submit">Save</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      firstName: this.data.first_name,
      lastName: this.data.last_name,
      email: this.data.email,
      error: false,
      success: false,
      isRunning: false,
    };
  },
  methods: {
    handleSubmit: function() {
      const _this = this;
      _this.error = false;
      _this.success = false;
      if ( ! _this.firstName || _this.firstName === '' ) {
        _this.error = 'First Name cannot be empty.';
      } else if ( ! _this.lastName || _this.lastName === '' ) {
        _this.error = 'Last Name cannot be empty.';
      } else if ( ! _this.email || _this.email === '' ) {
        _this.error = 'Email cannot be empty.';
      } else {
        if ( _this.isRunning === false ) {
          _this.isRunning = true;
          // Submit form
          axios.post( '/api/account/update-personal-information', {
            first_name: _this.firstName,
            last_name: _this.lastName,
            email: _this.email,
          } )
              .then( ( response ) => {
                _this.success = 'Personal information updated successfully!';
                _this.isRunning = false;
              } ).catch( ( error ) => {
                console.log( error );
                _this.error = 'There was a problem updating your personal information.';
                _this.isRunning = false;
              } );
        }
      }
    },
  },
};
</script>
