<template>
    <div>
        <p class="hdg-6 mb-4">Company Details</p>

        <div v-if="companyDetails.length > 0" class="mb-8">
            <div v-for="(companyDetail, companyDetailsIndex) in companyDetails" :key="companyDetailsIndex">
                <div class="relative box bg-bgblue mb-8">
                    <div class="form-group">
                        <label :for="'industry_' + companyDetailsIndex">Industry</label>

                        <div class="form-group__input form-group__input--icon">
                            <select v-model="companyDetail.industry_id" :id="'industry_id' + companyDetailsIndex" class="" :name="'industry_id' + companyDetailsIndex" required>
                                <option value=""></option>
                                <option v-for="(option, optionIndex) in formData.industry_options" :key="optionIndex" :value="option.id">{{ option.name }}</option>
                            </select>
                            <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                        </div>
                    </div>

                    <div class="form-group">
                        <label :for="'company_size' + companyDetailsIndex">Company Size</label>

                        <div class="form-group__input form-group__input--icon">
                            <select v-model="companyDetail.company_size" :id="'company_size' + companyDetailsIndex" class="" :name="'company_size' + companyDetailsIndex" required>
                                <option value=""></option>
                                <option value="small">Small (1-100 empolyees)</option>
                                <option value="medium">Medium (101-999 empolyees)</option>
                                <option value="large">Large (1000+ empolyees)</option>
                            </select>
                            <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                        </div>
                    </div>

                    <div class="form-group">
                        <label :for="'company_type' + companyDetailsIndex">Company Type</label>

                        <div class="form-group__input form-group__input--icon">
                            <select v-model="companyDetail.company_type" :id="'company_type' + companyDetailsIndex" class="" :name="'company_type' + companyDetailsIndex" required>
                                <option value=""></option>
                                <option value="private">Privately held</option>
                                <option value="public">Publicly held</option>
                            </select>
                            <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                        </div>
                    </div>
                </div>


                <p class="hdg-6 mb-4">Company Introduction</p>

                <div class="mb-8">
                    <div class="relative box bg-bgblue mb-8">
                        <div class="form-group">
                            <label :for="'introduction' + companyDetailsIndex">About the Company</label>
                            <div class="form-group__input form-group__input--icon">
                            <textarea v-model="companyDetail.company_introduction" type="textarea" :id="'introduction' + companyDetailsIndex" class="" :name="'introduction' +companyDetailsIndex" required />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
export default {
  props: ['userCompanyDetails', 'formData'],
  data() {
    return {
      companyDetails: [],
      year: new Date().getFullYear(),
    };
  },
  mounted() {
    this.setCompanyDetails();
  },
  methods: {
    setCompanyDetails: function() {
      if ( this.userCompanyDetails.length > 0 ) {
        this.companyDetails = Object.assign( [], this.userCompanyDetails );
      } else {
        this.addCompanyDetails();
      }
    },
    addCompanyDetails: function() {
      this.companyDetails.push(
          {
            id: false,
            industry_id: '',
            company_size: '',
            company_type: '',
            company_introduction: '',
          }
      );
    },
    removeCompanyDetails: function( index ) {
      this.companyDetails.splice( index, 1 );
    },
  },
  watch: {
    companyDetails() {
      this.$emit( 'update', this.companyDetails );
    },
  },
};
</script>
