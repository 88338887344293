<template>
    <div>
        <p class="hdg-6 mb-4">Social Media</p>

        <div v-if="socials.length > 0" class="mb-8">
            <div v-for="(social, socialIndex) in socials" :key="socialIndex">

                <div class="form-group form-group--small">
                    <label :for="'social_' + socialIndex">{{ social.label }}</label>

                    <div class="form-group__input form-group__input--icon">
                        <input v-model="social.url" :id="'social_' + socialIndex" type="text" class="pr-5" :name="'social_' + socialIndex" required>
                        <svg @click="removeSocial( socialIndex )" class="block icon icon-minus"><use xlink:href="#icon-minus"></use></svg>
                    </div>
                </div>

            </div>
        </div>

        <div class="relative">
            <button type="button" name="add_social" class="popover__button btn-dashed" data-toggle-class="is-open" data-toggle-outside data-toggle-target="#add-social-popup"><svg class="pointer-events-none icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>Add Social</button>

            <div class="popover__popup hidden absolute left-0 bottom-100 w-full" id="add-social-popup" role="navigation">
                <div class="popover__popup-box">
                    <div v-for="(socialOption, socialOptionIndex) in socialOptions" :key="socialOptionIndex">
                        <button @click="addSocial( socialOptionIndex )" data-toggle-trigger-off class="flex items-center text-left my-3 text-gray-500 text-sm font-medium hover:text-blue" type="button" :name="'add_' + socialOption.slug"><svg :class="'pointer-events-none block text-lg icon mr-2 icon-' + socialOption.slug"><use :xlink:href="'#icon-' + socialOption.slug"></use></svg>{{ socialOption.label }}</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
  props: ['userSocials', 'socialOptions'],
  data() {
    return {
      socials: [],
      year: new Date().getFullYear(),
    };
  },
  mounted() {
    this.setWorkExperiences();
  },
  methods: {
    setWorkExperiences: function() {
      if ( this.userSocials.length > 0 ) {
        this.socials = Object.assign( [], this.userSocials );
      }
    },
    addSocial: function( index ) {
      this.socials.push(
          {
            id: this.socialOptions[index].id,
            label: this.socialOptions[index].label,
            slug: this.socialOptions[index].slug,
            social_user_id: false,
            url: '',
          }
      );
    },
    removeSocial: function( index ) {
      this.socials.splice( index, 1 );
    },
  },
  watch: {
    socials() {
      this.$emit( 'update', this.socials );
    },
  },
};
</script>
