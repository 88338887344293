<template>
    <canvas v-bind:id="this.id"></canvas>
</template>

<script>
import ChartMixin from '../mixins/ChartMixin';
export default {
  mixins: [ChartMixin],
  data() {
    return {
    };
  },
};
</script>
