<template>
    <div class="mfp-hide popup" :id="popupId">
        <div class="container">
            <div class="row">
                <div class="col w-full md:w-3/4 lg:w-2/3 mx-auto">
                    <div class="box box--lg h-auto public-resume__cover-letter relative">
                        <button class="js-close-popup close-popup-icon" type="button">
                            <svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg>
                        </button>

                        <div class="max-w-lg mx-auto" v-if="coverLetters.length > 0">
                            <p class="hdg-3 text-center mb-2">Share Resume</p>
                            <p class="paragraph-small text-gray-500 text-center mb-5">Enter email address below to share the URL with them</p>

                            <form class="form-skin" @submit.prevent="handleSubmit">
                                <div class="form-group">
                                    <label for="email">Email</label>

                                    <div class="form-group__input">
                                        <input v-model="recipient" id="email" type="email" name="email" required>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="cover_letter">Attach a cover letter</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select v-model="coverLetter" id="cover_letter" class="" name="cover_letter" required>
                                            <option value=""></option>
                                            <option v-for="(option, optionIndex) in coverLetters" :key="optionIndex" :value="option.id">{{ option.title }}</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>

                                <div class="form-group mb-12">
                                    <label for="message">Add a message</label>

                                    <div class="form-group__input">
                                        <textarea v-model="message" name="message" style="min-height: 7rem;" required></textarea>
                                    </div>
                                </div>

                                <div class="row mb-8">
                                    <div class="col order-2 sm:order-1 w-full sm:w-1/2">
                                        <button class="js-close-popup btn w-full" type="button" name="button">Cancel</button>
                                    </div>
                                    <div class="col order-1 sm:order-2 w-full sm:w-1/2 mb-5 sm:mb-0">
                                        <button class="btn-green w-full" type="submit" name="button">Send</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="max-w-lg mx-auto" v-if="coverLetters.length <= 0">
                            <p class="hdg-3 text-center mb-2">Share Resume</p>
                            <p class="paragraph-small text-gray-500 text-center mb-5">You must create a cover letter first.</p>
                            <div class="row mb-8 justify-center">
                                <div class="col sm:order-2 w-full sm:w-1/2 mb-5 sm:mb-0">
                                    <a href="/cover-letters/create" class="btn-green w-full" type="submit" name="button">Create a Cover Letter</a>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: ['resume', 'coverLetters', 'popupId'],
  data() {
    return {
      recipient: '',
      coverLetter: '',
      message: '',
      action: '/api/resume/share',
      errors: [],
      isRunning: false,
    };
  },
  mounted() {
    // console.log( 'Component mounted.' );
  },
  methods: {
    handleSubmit() {
      const _this = this;

      if ( this.isRunning === false ) {
        // Validate inputs
        _this.validateInputs();

        if ( _this.errors.length === 0 ) {
          // Submit form
          axios.post( _this.action, {
            data: {
              resume: _this.resume,
              recipient: _this.recipient,
              cover_letter: _this.coverLetter,
              message: _this.message,
            },
          } )
              .then( ( response ) => {
                // console.log( response );
                window.location.href = '/active-resumes';
              } ).catch( ( error ) => {
                console.log( error );
                _this.isRunning = false;
              } );
        } else {
          _this.isRunning = false;
        }
      }
    },
    validateInputs() {
      //
    },
  },
};
</script>
