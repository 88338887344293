export default {
  props: ['id', 'datasets', 'labels', 'type', 'options'],
  data() {
    return {
      chart: false,
    };
  },
  watch: {
    datasets: function( newValue, oldValue ) {
      this.chart.data.datasets = this.datasets;
      this.chart.update();
    },
    labels: function( newValue, oldValue ) {
      this.chart.data.labels = this.labels;
      this.chart.update();
    },
  },
  mounted() {
    const ctx = document.getElementById( this.id ).getContext( '2d' );

    this.chart = new Chart( ctx, {
      // The type of chart we want to create
      type: this.type,

      // The data for our dataset
      data: {
        labels: this.labels,
        datasets: this.datasets,
      },

      // Configuration options go here
      options: this.options,
    } );
    // console.log( this.options );
  },
};
