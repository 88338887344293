<template>
    <div>
      <div class="find-jobs__filter_checkbox_group_label">Job Type</div>
      <div class="find-jobs__filter_checkbox_group">
        <div class="" v-for="(jobType, jobTypeIndex) in jobTypes" :key="jobTypeIndex">
          <input v-model="jobFilterTypes" :id="'jobFilterTypes_'+jobTypeIndex" :name="'jobFilterTypes_'+jobTypeIndex" :value="jobType.id" type="checkbox"/>
          <label :for="'jobFilterTypes_'+jobTypeIndex">{{jobType.name}}</label>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['jobTypes'],
  data() {
    return {
      jobFilterTypes: [],
      year: new Date().getFullYear(),
    };
  },
  methods: {

  },
  watch: {
    jobFilterTypes( ) {
      this.$emit( 'update', this.jobFilterTypes );
    },
  },
};
</script>
