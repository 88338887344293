<template>
    <div>
        <form class="lg:mb-4 form-skin" @submit.prevent="handleSearch">
            <div class="row">
                <div class="w-full col">
                    <div class="flex flex-col lg:flex-row form-group">
                        <div class="w-full mb-4 lg:w-9/12 lg:mb-0 form-group__input">
                            <label class="sr-only" for="search">Search</label>
                            <input id="search" type="text" name="search" placeholder="Search" v-model="search" required>
                        </div>
                        <button class="h-12 my-auto mb-4 ml-0 lg:ml-4 lg:mb-0 btn-save" type="submit">Search</button>
                        <button class="flex h-12 p-2 ml-auto border border-gray-300 rounded-xs"
                            data-toggle-class="filterjobs"
                            data-toggle-target=".find-jobs__filtercontrols, .find-jobs__paginate, .find-jobs__filtersearch, .listing-body">
                            <div class="my-auto mr-2 text-xs">Filter</div><svg
                                class="my-auto icon icon-filtercontrols text-blue">
                                <use xlink:href="#icon-filtercontrols"></use>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <a :href="adUrl" class="flex justify-center mb-8">
            <div :style="'background-image:url(' + imageRot + ');background-repeat:no-repeat;background-position:center;background-size:contain;'"
                class="w-full mx-auto long-ad">
            </div>
        </a>

        <div class="h-auto px-8 box" v-if="hasSearched === false">
            <div :class="'find-jobs__header flex items-center pb-4 text-4xs text-gray-500'">
                <div class="hidden w-7/12 pl-16 ml-2 uppercase lg:block">Name</div>
                <div class="hidden w-2/12 pl-4 uppercase lg:block">Match</div>
                <span class="ml-auto text-xs">{{ activeJobListings.to }} of {{ activeJobListings.total }} results</span>
            </div>
            <div class="find-jobs__card-wrap" v-if="activeJobListings.data.length > 0">
                <div class="find-jobs__body" v-for="(jobListing, jobListingIndex) in activeJobListings.data"
                    :key="jobListingIndex">
                    <a :href="'/apply-now/' + jobListing.id">
                      <div class="profile__image" v-if="jobListing.company_logo"
                          :style="{ backgroundImage: 'url(\'/uploads/' + jobListing.company_logo.path + '\')' }"></div>
                      <div class="profile__image" v-else :style="{ backgroundImage: 'url(\'' + imageUrl + '\')' }"></div>
                    </a>
                    <div class="job-highlevel">
                        <div class="job-title-sponsored"><a :href="'/apply-now/' + jobListing.id"
                                class="text-xs text-gray-700 job__title">{{ jobListing.listing_title }}</a><span
                                class="text-gray-400 uppercase job__sponsored text-3xs"
                                v-if="jobListing.sponsored_listing">Sponsored</span></div>
                        <div class="text-xs text-gray-500"><a :href="'/u/' + jobListing.user_id">{{ jobListing.company
                        }}</a> | {{ jobListing.location }}</div>
                    </div>
                    <div class="job-match"><span :class="'job-match__' + jobListing.personal_values">{{
                            jobListing.personal_values
                    }}</span></div>
                    <div class="job-card__footer">
                        <div class="job-apply-now">
                            <svg class="mr-1 icon icon-Shape text-blue">
                                <use xlink:href="#icon-Shape"></use>
                            </svg>
                            <a :href="'/apply-now/' + jobListing.id"
                                class="text-xs font-bold underline cursor-pointer text-blue">Apply Now</a>
                        </div>
                        <div v-if="jobListing.saved.length === 0"
                            :class="'job-save cursor-pointer saved-' + jobListing.id"
                            @click="handleUserJobApplicationSave(jobListing.id)">
                            <svg class="mr-1 icon icon-savestar">
                                <use xlink:href="#icon-savestar"></use>
                            </svg>
                            <span class="text-xs font-bold text-gray-500 underline">Save</span>
                        </div>
                        <div v-else class="job-save">
                            <svg class="mr-1 icon icon-savestar text-green">
                                <use xlink:href="#icon-savestar"></use>
                            </svg>
                            <span class="text-xs font-bold underline text-green">Saved</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="h-auto px-8 box" v-if="hasSearched !== false">
            <div :class="'find-jobs__header flex items-center pb-4 ' + prevView">
                <span v-if="searchListings.length <= 0" class="ml-auto text-xs text-gray-500">0 results</span>
                <span v-else class="ml-auto text-xs text-gray-500">{{ searchListings.to }} of {{ searchListings.total }}
                    results</span>
            </div>

            <div class="" v-if="searchListings.length <= 0">
                <div class="pt-4">There are no results. Please try again.</div>
            </div>

            <div :class="'find-jobs__card-wrap ' + prevView">
                <div class="find-jobs__body" :class="prevView"
                    v-for="(jobListing, jobListingIndex) in searchListings.data" :key="jobListingIndex">
                    <label>Select</label>
                    <a :href="'/apply-now/' + jobListing.id">
                      <div class="profile__image" v-if="jobListing.company_logo"
                          :style="{ backgroundImage: 'url(\'/uploads/' + jobListing.company_logo.path + '\')' }"></div>
                      <div class="profile__image" v-else :style="{ backgroundImage: 'url(\'' + imageUrl + '\')' }"></div>
                    </a>
                    <div class="job-highlevel">
                        <div class="job-title-sponsored"><a :href="'/apply-now/' + jobListing.id"
                                class="text-xs text-gray-700 job__title">{{ jobListing.listing_title }}</a><span
                                class="text-gray-400 uppercase job__sponsored text-3xs"
                                v-if="jobListing.sponsored_listing">Sponsored</span></div>
                        <div class="text-xs text-gray-500">{{ jobListing.company }} | {{ jobListing.location }}</div>
                    </div>
                    <div class="job-match"><span :class="'job-match__' + jobListing.personal_values">{{
                            jobListing.personal_values
                    }}</span></div>
                    <div class="job-card__footer">
                        <div class="job-apply-now">
                            <svg class="mr-1 icon icon-Shape text-blue">
                                <use xlink:href="#icon-Shape"></use>
                            </svg>
                            <a :href="'/apply-now/' + jobListing.id"
                                class="text-xs font-bold underline cursor-pointer text-blue">Apply Now</a>
                        </div>
                        <div v-if="jobListing.saved.length === 0"
                            :class="'job-save cursor-pointer saved-' + jobListing.id"
                            @click="handleUserJobApplicationSave(jobListing.id)">
                            <svg class="mr-1 icon icon-savestar">
                                <use xlink:href="#icon-savestar"></use>
                            </svg>
                            <span class="text-xs font-bold text-gray-500 underline">Save</span>
                        </div>
                        <div v-else class="job-save">
                            <svg class="mr-1 icon icon-savestar text-green">
                                <use xlink:href="#icon-savestar"></use>
                            </svg>
                            <span class="text-xs font-bold underline text-green">Saved</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-6 row find-jobs__paginate" v-if="hasSearched === false">
            <div class="w-full col">
                <div class="flex justify-between">
                    <div class="flex">
                        <a @click="handleSearchPrev()"
                            :class="activeJobListings.prev_page_url != null ? 'flex' : 'flex disabled text-gray-500'">
                            <svg class="w-6 h-6 icon icon-pagination page-prev">
                                <use xlink:href="#icon-pagination"></use>
                            </svg>
                            <span class="my-auto ml-4 text-sm">Previous</span>
                        </a>
                    </div>
                    <div class="flex">
                        <a v-for="(jobListing, jobListingIndex) in activeJobListings.last_page"
                            :class="(jobListingIndex + 1 == activeJobListings.current_page) ? 'page-item active' : 'page-item'"
                            :key="jobListingIndex" @click="handleSearchJump(jobListingIndex)">
                            <span
                                :class="(jobListingIndex + 1 == activeJobListings.current_page) ? 'underline text-gray-700' : 'text-gray-500'">{{ jobListingIndex
                                        + 1
                                }}</span>
                        </a>
                    </div>
                    <div class="">
                        <a @click="handleSearchNext(jobListingIndex)"
                            :class="activeJobListings.next_page_url != null ? 'flex search-next' : 'flex search-next disabled text-gray-500'">
                            <span class="my-auto mr-3 text-sm">Next</span>
                            <svg class="w-6 h-6 icon icon-pagination">
                                <use xlink:href="#icon-pagination"></use>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-6 row find-jobs__paginate" v-if="hasSearched !== false">
            <div class="w-full col">
                <div class="flex justify-between">
                    <div class="flex">
                        <a v-if="!filtered" @click="handleSearchPrev()"
                            :class="searchListings.prev_page_url != null ? 'flex' : 'flex disabled text-gray-500'">
                            <svg class="w-6 h-6 icon icon-pagination page-prev">
                                <use xlink:href="#icon-pagination"></use>
                            </svg>
                            <span class="my-auto ml-4 text-sm">Previous</span>
                        </a>
                        <a v-else @click="handleFilterPrev()"
                            :class="searchListings.prev_page_url != null ? 'flex' : 'flex disabled text-gray-500'">
                            <svg class="w-6 h-6 icon icon-pagination page-prev">
                                <use xlink:href="#icon-pagination"></use>
                            </svg>
                            <span class="my-auto ml-4 text-sm">Previous</span>
                        </a>
                    </div>
                    <div class="flex" v-if="!filtered">
                        <a class="mr-3" v-for="(jobListing, jobListingIndex) in searchListings.last_page"
                            :key="jobListingIndex" @click="handleSearchJump(jobListingIndex)">
                            <span
                                :class="(jobListingIndex + 1 == searchListings.current_page) ? 'underline text-gray-700' : 'text-gray-500'">{{ jobListingIndex
                                        + 1
                                }}</span>
                        </a>
                    </div>
                    <div class="flex" v-else>
                        <a class="mr-3" v-for="(jobListing, jobListingIndex) in searchListings.last_page"
                            :key="jobListingIndex" @click="handleFilterJump(jobListingIndex)">
                            <span
                                :class="(jobListingIndex + 1 == searchListings.current_page) ? 'underline text-gray-700' : 'text-gray-500'">{{ jobListingIndex
                                        + 1
                                }}</span>
                        </a>
                    </div>
                    <div class="">
                        <a v-if="!filtered" @click="handleSearchNext()"
                            :class="searchListings.last_page != currentPage ? 'flex search-next' : 'search-next flex disabled text-gray-500'">
                            <span class="my-auto mr-3 text-sm toggle-next">Next</span>
                            <svg class="w-6 h-6 icon icon-pagination">
                                <use xlink:href="#icon-pagination"></use>
                            </svg>
                        </a>
                        <a v-else @click="handleFilterNext()"
                            :class="searchListings.last_page != currentPage ? 'flex search-next' : 'search-next flex disabled text-gray-500'">
                            <span class="my-auto mr-3 text-sm toggle-next">Next</span>
                            <svg class="w-6 h-6 icon icon-pagination">
                                <use xlink:href="#icon-pagination"></use>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <form id="search-filters__form bg-transparent" @submit.prevent="handleFilter">
            <div class="bg-transparent find-jobs__filtercontrols">
                <div class="lg:w-2/3 md:w-1/3 filter-controls__bg bg-navy opacity-40"></div>
                <div class="relative w-full overflow-scroll bg-white lg:w-1/3 md:w-2/3">
                    <div class="pb-6 mt-6 mb-4 border-b border-gray-300">
                        <div class="flex justify-between px-12">
                            <span class="text-lg font-bold text-gray-700">Filter Jobs</span>
                            <span class="filter-controls__close" data-toggle-class="filterjobs"
                                data-toggle-target=".find-jobs__filtercontrols, .find-jobs__paginate, .find-jobs__filtersearch">
                                <svg class="text-gray-500 icon icon-exit-close">
                                    <use xlink:href="#icon-exit-close"></use>
                                </svg>
                            </span>
                        </div>
                    </div>

                    <search-filter-company :active-job-listings="activeJobListings" :company-names="companyNames"
                        @update="updateCompany"></search-filter-company>
                    <search-filter-industry :industries="industries" :industry="industry"
                        @update="updateIndustry"></search-filter-industry>
                    <search-filter-job-type :job-types="employmentType" :job-filter-types="jobFilterTypes"
                        @update="updateJobTypes"></search-filter-job-type>
                    <search-filter-job-level :job-level="jobLevels" :job-filter-level="jobFilterLevels"
                        @update="updateJobLevels"></search-filter-job-level>
                    <search-filter-salary-range :job-filter-salary-range="jobFilterSalaryRange"
                        @update="updateSalaryRange"></search-filter-salary-range>
                    <search-filter-location @update="updateZipCode" @zipper="updateUserZip"></search-filter-location>
                    <search-filter-willing-to-relocate :job-filter-relocate="jobFilterRelocate"
                        @update="updateRelocate"></search-filter-willing-to-relocate>

                </div>
            </div>
            <div class="w-full px-8 find-jobs__filtersearch lg:w-1/3 md:w-2/3">
                <div class="mr-8 text-gray-700 underline cursor-pointer hover:text-green" @click="resetListingsForm()">
                    Reset</div>
                <button type="submit" name="button" class="my-2 btn-save">Show Results</button>
            </div>
        </form>

        <a :href="adUrlBot" class="flex justify-center my-8">
            <div :style="'background-image:url('+imageRotBot+');background-repeat:no-repeat;background-position:center;background-size:contain;'"
                class="w-full mx-auto long-ad"></div>
        </a>

    </div>
</template>

<script>
import * as moment from 'moment-timezone';
export default {
  props: ['activeJobListings', 'activeJobApplications', 'assetPath', 'viewMode', 'employmentType', 'industries', 'jobLevels', 'jobTypes', 'industry', 'jobFilterTypes', 'jobFilterLevels', 'jobFilterSalaryRange', 'jobFilterRelocate', 'companyNames'],
  data() {
    return {
      imageRot: '../img/The-Music-Exchange-Banner-Ad.png',
      images: ['../img/The-Music-Exchange-Banner-Ad.png', '/img/renew-crew-horiz-ad.png'],
      adUrl: ['https://www.stjosephantiques.com/'],
      adUrls: ['https://www.stjosephantiques.com/', '#'],
      imageRotBot: '/img/renew-crew-horiz-ad.png',
      imagesBot: ['/img/renew-crew-horiz-ad.png', '../img/The-Music-Exchange-Banner-Ad.png'],
      adUrlBot: ['#'],
      adUrlsBot: ['#', 'https://www.stjosephantiques.com/'],
      search: '',
      imageUrl: '../img/default_profile.png',
      image: '',
      searchListings: [],
      hasSearched: false,
      userCompany: [],
      userIndustry: [],
      userJobTypes: [],
      userJobLevels: [],
      userSalaryRange: [],
      userZipCode: [],
      startingZip: [],
      userRelocate: [],
      prevView: '',
      currentPage: 0,
      lastPage: 0,
      filtered: false,
      jobListingIndex: '',
      appliedJobListings: this.activeJobApplications,
    };
  },
  mounted() {
    this.setView();
  },
  created() {
    this.rotateImages();
  },
  methods: {
    rotateImages() {
      let index = 0;
      let indexBot = 0;
      let urlIndex = 0;
      let urlIndexBot = 0;
      setInterval( () => {
        this.imageRot = this.images[index];
        index = index === this.images.length - 1 ? 0 : index + 1;

        this.adUrl = this.adUrls[urlIndex];
        urlIndex = urlIndex === this.adUrls.length - 1 ? 0 : urlIndex + 1;

        this.imageRotBot = this.imagesBot[indexBot];
        indexBot = indexBot === this.imagesBot.length - 1 ? 0 : indexBot + 1;

        this.adUrlBot = this.adUrlsBot[urlIndexBot];
        urlIndexBot = urlIndexBot === this.adUrlsBot.length - 1 ? 0 : urlIndexBot + 1;
      }, 30000 );
    },
    resetListingsForm: function() {
      const allChecked = document.querySelectorAll( 'input[type=checkbox]:checked' );
      for ( const checkbox of allChecked ) {
        checkbox.checked = false;
      }
    },
    setView: function() {
      const windowsize = $( window ).width();
      if ( windowsize <= 828 ) {
        $( '.view-selector' ).hide();
        $( '.find-jobs__card' ).attr( 'data-toggle-is-active', true );
        $( '.find-jobs__list' ).removeAttr( 'data-toggle-is-active' );
      }
    },
    formatDate: function( date ) {
      return moment( date ).format( 'MM/DD/YYYY' );
    },
    handleSearch() {
      const _this = this;
      if ( document.querySelector( '.find-jobs__header' ).classList.contains( 'listview' ) ) {
        _this.prevView = 'listview';
      } else {
        _this.prevView = 'cardview';
      }

      axios.get( '/api/job-listing/search?q=' + _this.search + '&currentPage=' + 0 )
          .then( ( response ) => {
            _this.hasSearched = true;
            _this.searchListings = response.data;
            _this.currentPage = response.data.current_page;
          } ).catch( ( error ) => {
            console.log( error );
            _this.hasSearched = false;
          } );
    },
    handleSearchNext( jobListingIndex ) {
      const _this = this;
      if ( document.querySelector( '.find-jobs__header' ).classList.contains( 'listview' ) ) {
        _this.prevView = 'listview';
      } else {
        _this.prevView = 'cardview';
      }
      if ( !_this.hasSearched ) {
        _this.currentPage = ( _this.currentPage + 1 );
      }
      if ( !document.querySelector( '.search-next' ).classList.contains( 'disabled' ) ) {
        axios.get( '/api/job-listing/search?q=' + _this.search + '&currentPage=' + _this.currentPage )
            .then( ( response ) => {
              _this.hasSearched = true;
              _this.searchListings = response.data;
              _this.currentPage = response.data.current_page;
              _this.lastPage = response.data.last_page;
            } ).catch( ( error ) => {
              console.log( error );
              _this.hasSearched = false;
            } );
      }
    },
    handleSearchPrev() {
      const _this = this;
      if ( document.querySelector( '.find-jobs__header' ).classList.contains( 'listview' ) ) {
        _this.prevView = 'listview';
      } else {
        _this.prevView = 'cardview';
      }
      if ( _this.currentPage >= 1 ) {
        axios.get( '/api/job-listing/search?q=' + _this.search + '&currentPage=' + ( _this.currentPage ) + '&previousPage=1' )
            .then( ( response ) => {
              _this.hasSearched = true;
              _this.searchListings = response.data;
              _this.currentPage = response.data.current_page;
              _this.lastPage = response.data.last_page;
            } ).catch( ( error ) => {
              console.log( error );
              _this.hasSearched = false;
            } );
      }
    },
    handleSearchJump( page ) {
      const _this = this;
      if ( document.querySelector( '.find-jobs__header' ).classList.contains( 'listview' ) ) {
        _this.prevView = 'listview';
      } else {
        _this.prevView = 'cardview';
      }
      if ( !_this.hasSearched ) {
        _this.currentPage = 1;
      }
      if ( _this.currentPage >= 1 && _this.currentPage <= _this.activeJobListings.last_page ) {
        axios.get( '/api/job-listing/search?q=' + _this.search + '&currentPage=' + ( page ) )
            .then( ( response ) => {
              _this.hasSearched = true;
              _this.searchListings = response.data;
              _this.currentPage = response.data.current_page;
              _this.lastPage = response.data.last_page;
            } ).catch( ( error ) => {
              console.log( error );
              _this.hasSearched = false;
            } );
      }
    },
    handleFilter() {
      const _this = this;
      if ( document.querySelector( '.find-jobs__header' ).classList.contains( 'listview' ) ) {
        _this.prevView = 'listview';
      } else {
        _this.prevView = 'cardview';
      }
      axios.get( '/api/job-listing/filter?companyId=' + _this.userCompany + '&industries=' + _this.userIndustry + '&types=' + _this.userJobTypes + '&jobLevels=' + _this.userJobLevels + '&salaryRange=' + _this.userSalaryRange + '&startingZip=' + _this.startingZip + '&zipCode=' + _this.userZipCode + '&relocate=' + _this.userRelocate + '&currentPage=' + 0 )
          .then( ( response ) => {
            _this.hasSearched = true;
            _this.searchListings = response.data;
            _this.currentPage = response.data.current_page;
            _this.lastPage = response.data.last_page;
            _this.filtered = true;
            $( '.filter-controls__close' ).click();
          } ).catch( ( error ) => {
            console.log( error );
            _this.hasSearched = false;
            _this.filtered = false;
          } );
    },
    handleFilterNext() {
      const _this = this;
      if ( document.querySelector( '.find-jobs__header' ).classList.contains( 'listview' ) ) {
        _this.prevView = 'listview';
      } else {
        _this.prevView = 'cardview';
      }

      if ( !document.querySelector( '.search-next' ).classList.contains( 'disabled' ) ) {
        axios.get( '/api/job-listing/filter?companyId=' + _this.userCompany + '&industries=' + _this.userIndustry + '&types=' + _this.userJobTypes + '&jobLevels=' + _this.userJobLevels + '&salaryRange=' + _this.userSalaryRange + '&startingZip=' + _this.startingZip + '&zipCode=' + _this.userZipCode + '&relocate=' + _this.userRelocate + '&currentPage=' + _this.currentPage )
            .then( ( response ) => {
              _this.hasSearched = true;
              _this.searchListings = response.data;
              _this.currentPage = response.data.current_page;
              _this.lastPage = response.data.last_page;
              _this.filtered = true;
            } ).catch( ( error ) => {
              console.log( error );
              _this.hasSearched = false;
              _this.filtered = false;
            } );
      }
    },
    handleFilterPrev() {
      const _this = this;
      if ( document.querySelector( '.find-jobs__header' ).classList.contains( 'listview' ) ) {
        _this.prevView = 'listview';
      } else {
        _this.prevView = 'cardview';
      }
      if ( _this.currentPage >= 1 ) {
        axios.get( '/api/job-listing/filter?companyId=' + _this.userCompany + '&industries=' + _this.userIndustry + '&types=' + _this.userJobTypes + '&jobLevels=' + _this.userJobLevels + '&salaryRange=' + _this.userSalaryRange + '&startingZip=' + _this.startingZip + '&zipCode=' + _this.userZipCode + '&relocate=' + _this.userRelocate + '&currentPage=' + ( _this.currentPage ) + '&previousPage=1' )
            .then( ( response ) => {
              _this.hasSearched = true;
              _this.searchListings = response.data;
              _this.currentPage = response.data.current_page;
              _this.lastPage = response.data.last_page;
              _this.filtered = true;
            } ).catch( ( error ) => {
              console.log( error );
              _this.hasSearched = false;
              _this.filtered = false;
            } );
      }
    },
    handleFilterJump( page ) {
      const _this = this;
      if ( document.querySelector( '.find-jobs__header' ).classList.contains( 'listview' ) ) {
        _this.prevView = 'listview';
      } else {
        _this.prevView = 'cardview';
      }
      if ( _this.currentPage >= 1 ) {
        axios.get( '/api/job-listing/filter?companyId=' + _this.userCompany + '&industries=' + _this.userIndustry + '&types=' + _this.userJobTypes + '&jobLevels=' + _this.userJobLevels + '&salaryRange=' + _this.userSalaryRange + '&startingZip=' + _this.startingZip + '&zipCode=' + _this.userZipCode + '&relocate=' + _this.userRelocate + '&currentPage=' + ( page ) )
            .then( ( response ) => {
              _this.hasSearched = true;
              _this.searchListings = response.data;
              _this.currentPage = response.data.current_page;
              _this.lastPage = response.data.last_page;
            } ).catch( ( error ) => {
              console.log( error );
              _this.hasSearched = false;
            } );
      }
    },
    handleUserJobApplicationSave( id ) {
      const _this = this;
      const saveJobs = document.querySelectorAll( '.save-listing:checked' );
      const ids = [];
      ids.push( id );
      saveJobs.forEach( function( element, index ) {
        ids.push( element.value );
      } );
      if ( document.querySelector( '.find-jobs__header' ).classList.contains( 'listview' ) ) {
        _this.prevView = 'listview';
      } else {
        _this.prevView = 'cardview';
      }

      axios.post( '/api/save-job-listing?q=' + ids + '&status=saved' )
          .then( ( response ) => {
            ids.forEach( function( id ) {
              $( '.saved-' + id ).html( 'Saved' ).css( 'color', '#6FCB6F' );
              $( '.star-' + id ).css( 'color', '#6FCB6F' );
            } );
          } ).catch( ( error ) => {
            console.log( error );
            _this.hasSearched = false;
          } );
    },
    updateCompany( company ) {
      this.userCompany = company;
    },
    updateIndustry( industry ) {
      this.userIndustry = industry;
    },
    updateJobTypes( jobTypes ) {
      this.userJobTypes = jobTypes;
    },
    updateJobLevels( jobLevels ) {
      this.userJobLevels = jobLevels;
    },
    updateSalaryRange( salaryRange ) {
      this.userSalaryRange = salaryRange;
    },
    updateZipCode( zipCode ) {
      this.userZipCode = zipCode;
    },
    updateUserZip( userZip ) {
      this.startingZip = userZip;
    },
    updateRelocate( relocate ) {
      this.userRelocate = relocate;
    },
    resetForm() {
      $( '#search-filters__form' )[0].reset();
    },
  },
};
</script>
