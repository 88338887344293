<template>
    <div>
      <p class="hdg-6 mb-4">Pre-Interview Questions</p>
      <div v-if="preInterviewQuestions.length > 0" class="relative box bg-bgblue mb-8">
        <div v-for="(preInterviewQuestion, preInterviewQuestionIndex) in preInterviewQuestions" :key="preInterviewQuestionIndex">
          <div class="flex flex-wrap -mx-2">
            <div class="flex-initial px-2 w-full mb-5 xl:mb-0">
              <div class="flex flex-wrap -mx-2">
                <div class="flex-initial w-full px-2 relative">
                  <button class="absolute right-0 top-0 p-4 hover:text-error" @click="removePreInterviewQuestions( preInterviewQuestionIndex )" type="button" :name="'remove_video_' + preInterviewQuestionIndex"><svg class="block text-lg icon icon-minus"><use xlink:href="#icon-minus"></use></svg></button>
                  <div class="form-group mb-2">
                    <label class="sr-only" :for="'question_text_' + preInterviewQuestionIndex">preInterviewQuestion</label>
                    <div class="form-group__input form-group__input--icon w-11/12">
                        <input type="text" v-model="preInterviewQuestion.question_text" :id="'question_text_' + preInterviewQuestionIndex" :name="'question_text_' + preInterviewQuestionIndex" class="" required/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button @click="addJobListingPreInterviewQuestion( false )" type="button" name="add_video" class="btn-dashed"><svg class="icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>Add Question</button>
    </div>
</template>

<script>
export default {
  props: ['jobListingPreInterviewQuestions', 'jobListingPreInterviewQuestionsOptions'],
  data() {
    return {
      preInterviewQuestions: [],
      year: new Date().getFullYear(),
    };
  },
  mounted() {
    this.setJobListingPreInterviewQuestions();
  },
  methods: {
    setJobListingPreInterviewQuestions: function() {
      if ( this.jobListingPreInterviewQuestions.length > 0 ) {
        this.preInterviewQuestions = Object.assign( [], this.jobListingPreInterviewQuestions );
      } else {
        this.addJobListingPreInterviewQuestion( false );
      }
    },
    addJobListingPreInterviewQuestion: function( id ) {
      if ( id !== false ) {
        this.preInterviewQuestions.push(
            Object.assign( {}, this.jobListingPreInterviewQuestionsOptions[id] )
        );
      } else {
        this.preInterviewQuestions.push(
            {
              id: false,
            }
        );
      }
    },
    removePreInterviewQuestions: function( index ) {
      this.preInterviewQuestions.splice( index, 1 );
    },
  },
  watch: {
    preInterviewQuestions() {
      this.$emit( 'update', this.preInterviewQuestions );
    },
  },
};
</script>
