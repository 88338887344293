<template>
    <div class="mfp-hide popup" :id="popupId">
        <div class="container">
            <div class="row">
                <div class="col w-full md:w-3/4 lg:w-2/3 mx-auto">
                    <div class="box box--lg h-auto public-resume__cover-letter relative" :style="{ backgroundImage: 'url(\'' + assetPath + 'img/top_applicant_logo_background.png\')' }">
                        <button class="js-close-popup close-popup-icon" type="button">
                            <svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg>
                        </button>

                        <h3 v-if="formData.salutation" class="hdg-4 text-center mb-6">{{ formData.salutation }}</h3>

                        <h3 v-if="formData.intro" class="hdg-3 text-center text-blue mb-6">{{ formData.intro }}</h3>

                        <div v-if="formData.email || formData.location || formData.phone" class="row justify-center mb-16">
                            <div v-if="formData.email" class="col">
                                <a :href="'mailto:' + formData.email" class="text-xs"><svg class="mr-4 text-lg icon icon-back"><use xlink:href="#icon-back"></use></svg>{{ formData.email }}</a>
                            </div>
                            <div v-if="formData.location" class="col">
                                <span class="text-xs"><svg class="mr-4 text-lg icon icon-big-map-placeholder-outlined-symbol-of-interface"><use xlink:href="#icon-big-map-placeholder-outlined-symbol-of-interface"></use></svg>{{ formData.location }}</span>
                            </div>
                            <div v-if="formData.phone" class="col">
                                <a :href="'tel:' + formData.phone" class="text-xs"><svg class="mr-4 text-lg icon icon-cellphone"><use xlink:href="#icon-cellphone"></use></svg>{{ formData.phone }}</a>
                            </div>
                        </div>

                        <div class="content public-resume__cover-letter-content mb-6">
                            <div v-html="formData.content"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: ['formData', 'popupId', 'assetPath'],
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>
