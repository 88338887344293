<template>
    <div>
        <p class="hdg-6 mb-4">Education</p>

        <div v-if="educations.length > 0" class="mb-8">
            <div v-for="(education, educationIndex) in educations" :key="educationIndex" class="relative box bg-bgblue mb-8">
                <button class="absolute right-0 top-0 p-4 hover:text-error" @click="removeEducation( educationIndex )" type="button" :name="'remove_education_' + educationIndex"><svg class="block text-lg icon icon-minus"><use xlink:href="#icon-minus"></use></svg></button>

                <p v-if="education.id !== false" class="text-3xs text-gray-500 mb-2 pr-4">You can edit this education data in <a href="/profile" class="underline text-blue hover:no-underline">your profile</a>.</p>

                <div class="form-group">
                    <label :for="'degree_' + educationIndex">Degree</label>

                    <div class="form-group__input">
                        <input :id="'degree_' + educationIndex" type="text" class="" :name="'degree_' + educationIndex" v-model="education.degree" required :disabled="education.id !== false">
                    </div>
                </div>

                <div class="form-group">
                    <label :for="'school_location_' + educationIndex">School/Location</label>

                    <div class="form-group__input">
                        <input :id="'school_location_' + educationIndex" type="text" class="" :name="'school_location_' + educationIndex" v-model="education.school_location" required :disabled="education.id !== false">
                    </div>
                </div>

                <div class="form-check mb-5 mt-8">
                    <input type="checkbox" :name="'current_school_' + educationIndex" :id="'current_school_' + educationIndex" v-model="education.current_school" :disabled="education.id !== false">

                    <label :for="'current_school_' + educationIndex">Currently enrolled</label>
                </div>

                <div class="flex flex-wrap -mx-2">
                    <div class="flex-initial px-2 w-full xl:w-1/2 mb-5 xl:mb-0">
                        <div class="flex flex-wrap -mx-2">
                            <div class="flex-initial w-full px-2">
                                <p class="font-bold text-gray-500 mb-2">Start Date</p>
                            </div>

                            <div class="flex-initial w-1/2 px-2">
                                <div class="form-group mb-0">
                                    <label :for="'start_date_month_' + educationIndex">Month</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select :id="'start_date_month_' + educationIndex" class="" :name="'start_date_month_' + educationIndex" v-model="education.start_date_month" required :disabled="education.id !== false">
                                            <option value=""></option>
                                            <option value="January">Jan</option>
                                            <option value="February">Feb</option>
                                            <option value="March">Mar</option>
                                            <option value="April">Apr</option>
                                            <option value="May">May</option>
                                            <option value="June">June</option>
                                            <option value="July">July.</option>
                                            <option value="August">Aug</option>
                                            <option value="September">Sept</option>
                                            <option value="October">Oct</option>
                                            <option value="November">Nov</option>
                                            <option value="December">Dec</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>
                            </div>

                            <div class="flex-initial w-1/2 px-2">
                                <div class="form-group mb-0">
                                    <label :for="'start_date_year_' + educationIndex">Year</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select :id="'start_date_year_' + educationIndex" class="" :name="'start_date_year_' + educationIndex" v-model="education.start_date_year" required :disabled="education.id !== false">
                                            <option value=""></option>
                                            <option v-for="index in 50" :key="index" :value="year - index + 1">{{ year - index + 1 }}</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="! education.current_school" class="flex-initial px-2 w-full xl:w-1/2">
                        <div class="flex flex-wrap -mx-2">
                            <div class="flex-initial w-full px-2">
                                <p class="font-bold text-gray-500 mb-2">End Date</p>
                            </div>

                            <div class="flex-initial w-1/2 px-2">
                                <div class="form-group mb-0">
                                    <label :for="'end_date_month_' + educationIndex">Month</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select :id="'end_date_month_' + educationIndex" class="" :name="'end_date_month_' + educationIndex" v-model="education.end_date_month" required :disabled="education.id !== false">
                                            <option value=""></option>
                                            <option value="January">Jan</option>
                                            <option value="February">Feb</option>
                                            <option value="March">Mar</option>
                                            <option value="April">Apr</option>
                                            <option value="May">May</option>
                                            <option value="June">June</option>
                                            <option value="July">July.</option>
                                            <option value="August">Aug</option>
                                            <option value="September">Sept</option>
                                            <option value="October">Oct</option>
                                            <option value="November">Nov</option>
                                            <option value="December">Dec</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>
                            </div>

                            <div class="flex-initial w-1/2 px-2">
                                <div class="form-group mb-0">
                                    <label :for="'end_date_year_' + educationIndex">Year</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select :id="'end_date_year_' + educationIndex" class="" :name="'end_date_year_' + educationIndex" v-model="education.end_date_year" required :disabled="education.id !== false">
                                            <option value=""></option>
                                            <option v-for="index in 50" :key="index" :value="year - index + 1">{{ year - index + 1 }}</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="relative">
            <button type="button" name="add_education" class="popover__button btn-dashed" data-toggle-class="is-open" data-toggle-outside data-toggle-target="#add-education-popup"><svg class="pointer-events-none icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>Add Education</button>

            <div class="popover__popup hidden absolute left-0 bottom-100 w-full" id="add-education-popup" role="navigation">
                <div class="popover__popup-box">
                    <div>
                        <button @click="addEducation( false )" data-toggle-trigger-off class="flex w-full items-center text-left text-gray-500 text-sm font-medium hover:text-blue py-3" type="button"><svg class="'pointer-events-none block text-lg icon mr-2 icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>Add New Education</button>
                    </div>

                    <div v-for="(education, educationIndex) in educationOptions" :key="educationIndex">
                        <button v-if="!isInUse( education.id )" :data-id="educationIndex" @click="addEducation( educationIndex )" data-toggle-trigger-off class="flex w-full items-center text-left text-gray-500 text-sm font-medium hover:text-blue py-3 border-t" type="button">{{ getEducationLabel( education ) }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: ['userEducations', 'educationOptions'],
  data() {
    return {
      educations: [],
      year: new Date().getFullYear(),
    };
  },
  mounted() {
    this.setEducations();
  },
  methods: {
    setEducations: function() {
      if ( this.userEducations.length > 0 ) {
        this.educations = Object.assign( [], this.userEducations );
      } else {
        this.addEducation( false );
      }
    },
    addEducation: function( id ) {
      if ( id !== false ) {
        this.educations.push(
            Object.assign( {}, this.educationOptions[id] )
        );
      } else {
        this.educations.push(
            {
              id: false,
              resume_user_education_id: false,
              degree: '',
              school_location: '',
              current_school: false,
              start_date_month: '',
              start_date_year: '',
              end_date_month: '',
              end_date_year: '',
            }
        );
      }
    },
    removeEducation: function( index ) {
      this.educations.splice( index, 1 );
    },
    getEducationLabel: function( education ) {
      let label = education.school_location;

      if ( education.start_date_month && education.start_date_year ) {
        label += ' (' + education.start_date_month + ' ' + education.start_date_year + ' - ';

        if ( education.current_school ) {
          label += 'Present)';
        } else {
          label += education.end_date_month + ' ' + education.end_date_year + ')';
        }
      }

      return label;
    },
    isInUse: function( id ) {
      let isInUse = false;
      if ( id ) {
        $.each( this.educations, function( index, education ) {
          if ( education.id === id ) {
            isInUse = true;
          }
        } );
      }
      return isInUse;
    },
  },
  watch: {
    educations() {
      this.$emit( 'update', this.educations );
    },
  },
};
</script>
