<template>
    <div>
      <div class="find-jobs__filter_checkbox_group_label">Salary Range</div>
      <div class="find-jobs__filter_checkbox_group">

          <div><input v-model="jobSalaryRange" id="find-jobs__filter_salary-40" name="find-jobs__filter_salary-40" value="40000" type="checkbox"/><label for="find-jobs__filter_salary-40">$40,000+</label></div>
          <div><input v-model="jobSalaryRange" id="find-jobs__filter_salary-60" name="find-jobs__filter_salary-60" value="60000" type="checkbox"/><label for="find-jobs__filter_salary-60">$60,000+</label></div>
          <div><input v-model="jobSalaryRange" id="find-jobs__filter_salary-80" name="find-jobs__filter_salary-80" value="80000" type="checkbox"/><label for="find-jobs__filter_salary-80">$80,000+</label></div>
          <div><input v-model="jobSalaryRange" id="find-jobs__filter_salary-100" name="find-jobs__filter_salary-100" value="100000" type="checkbox"/><label for="find-jobs__filter_salary-100">$100,000+</label></div>
          <div><input v-model="jobSalaryRange" id="find-jobs__filter_salary-120" name="find-jobs__filter_salary-120" value="120000" type="checkbox"/><label for="find-jobs__filter_salary-120">$120,000+</label></div>
          <div><input v-model="jobSalaryRange" id="find-jobs__filter_salary-140" name="find-jobs__filter_salary-140" value="140000" type="checkbox"/><label for="find-jobs__filter_salary-140">$140,000+</label></div>

      </div>
    </div>
</template>

<script>
export default {
  props: ['jobTypes'],
  data() {
    return {
      jobSalaryRange: [],
      year: new Date().getFullYear(),
    };
  },
  methods: {

  },
  watch: {
    jobSalaryRange( ) {
      this.$emit( 'update', this.jobSalaryRange );
    },
  },
};
</script>
