<template>
    <div>
        <!-- <div class="mb-8 billing-sub">
            <h2 class="hdg-5">Select Your Plan</h2>
            <p class="text-blue">Try now for 30 days <span class="font-bold uppercase">free!</span> Cancel anytime prior to 30 days to avoid any charges.</p>
            <ul>
                <li class="py-8 border-b border-gray-300">
                    <label class="account-billing__radio" for="single_plan">
                        <span class="account-billing__details-col">
                            <span class="block mb-2 leading-tight"><span class="text-xl font-bold">$25.00</span> <span class="inline-block">/ month</span></span>
                            <span class="block leading-tight paragraph-default">1 active job listing</span>
                        </span>
                        <span class="account-billing__button-col">
                            <span v-if="selectedPlan === data.plans.single" class="account-billing__button-checked">
                                Selected <svg class="ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                            </span>
                            <span v-else class="account-billing__button-unchecked">
                                Select Plan
                            </span>
                        </span>
                    </label>
                    <input v-model="selectedPlan" class="hidden" id="single_plan" type="radio" name="plan" :value="data.plans.single">
                </li>
                <li class="py-8 border-b border-gray-300">
                    <label class="account-billing__radio" for="basic_plan">
                        <span class="account-billing__details-col">
                            <span class="block mb-2 leading-tight"><span class="text-xl font-bold">$50.00</span> <span class="inline-block">/ month</span></span>
                            <span class="block leading-tight paragraph-default">Up to 5 active job listings</span>
                        </span>
                        <span class="account-billing__button-col">
                            <span v-if="selectedPlan === data.plans.basic" class="account-billing__button-checked">
                                Selected <svg class="ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                            </span>
                            <span v-else class="account-billing__button-unchecked">
                                Select Plan
                            </span>
                        </span>
                    </label>
                    <input v-model="selectedPlan" class="hidden" id="basic_plan" type="radio" name="plan" :value="data.plans.basic">
                </li>
                <li class="py-8 border-b border-gray-300">
                    <label class="account-billing__radio" for="standard_plan">
                        <span class="account-billing__details-col">
                            <span class="block mb-2 leading-tight"><span class="text-xl font-bold">$150.00</span> <span class="inline-block">/ month</span></span>
                            <span class="block leading-tight paragraph-default">Up to 10 active job listings</span>
                        </span>
                        <span class="account-billing__button-col">
                            <span v-if="selectedPlan === data.plans.standard" class="account-billing__button-checked">
                                Selected <svg class="ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                            </span>
                            <span v-else class="account-billing__button-unchecked">
                                Select Plan
                            </span>
                        </span>
                    </label>
                    <input v-model="selectedPlan" class="hidden" id="standard_plan" type="radio" name="plan" :value="data.plans.standard">
                </li>
                <li class="py-8">
                    <label class="account-billing__radio" for="pro_plan">
                        <span class="account-billing__details-col">
                            <span class="block mb-2 leading-tight"><span class="text-xl font-bold">$250.00</span> <span class="inline-block">/ month</span></span>
                            <span class="block leading-tight paragraph-default">10+ active job listings</span>
                        </span>
                        <span class="account-billing__button-col">
                            <span v-if="selectedPlan === data.plans.pro" class="account-billing__button-checked">
                                Selected <svg class="ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                            </span>
                            <span v-else class="account-billing__button-unchecked">
                                Select Plan
                            </span>
                        </span>
                    </label>
                    <input v-model="selectedPlan" class="hidden" id="pro_plan" type="radio" name="plan" :value="data.plans.pro">
                </li>
            </ul>

        </div> -->

        <div class="mb-16 billing-sub">
            <h2 class="hdg-5">Add Sponsored Listings to Any Plan</h2>
            <p class="text-error ">Not included with free trial! You will be charged the full price of the selected Sponsored Listings plan immediately.</p>
            <span class="block mb-5 leading-tight paragraph-default">Sponsor listings for top-of-page visibility</span>
            <ul>
                <li class="border border-gray-300 p-gutter">
                    <label class="account-billing__checkbox" for="selectedTier_1">
                        <span class="account-billing__details-col">
                            <span class="block mb-2 leading-tight"><span class="text-xl font-bold">+$50.00</span> <span class="inline-block">/ month</span></span>
                            <span class="block leading-tight paragraph-default">1 sponsored listing</span>
                        </span>
                        <span class="account-billing__button-col">
                            <span v-if="selectedTier === this.data.plans.tier_1" class="account-billing__button-checked">
                                Added <svg class="ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                            </span>
                            <span v-else class="account-billing__button-unchecked">
                                Add to Plan
                            </span>
                        </span>
                    </label>
                    <span class="flex justify-end"  v-if="selectedTier === this.data.plans.tier_1">
                        <button v-on:click="removeSponsoredPlan()">
                            Undo
                        </button>
                    </span>
                    <input v-model="selectedTier" class="hidden" id="selectedTier_1" type="radio" name="selectedTier" :value="data.plans.tier_1">
                </li>
                <li class="border border-gray-300 p-gutter">
                    <label class="account-billing__checkbox" for="selectedTier_2">
                        <span class="account-billing__details-col">
                            <span class="block mb-2 leading-tight"><span class="text-xl font-bold">+$100.00</span> <span class="inline-block">/ month</span></span>
                            <span class="block leading-tight paragraph-default">5 sponsored listings</span>
                        </span>
                        <span class="account-billing__button-col">
                            <span v-if="selectedTier === this.data.plans.tier_2" class="account-billing__button-checked">
                                Added <svg class="ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                            </span>
                            <span v-else class="account-billing__button-unchecked">
                                Add to Plan
                            </span>
                        </span>
                    </label>
                    <span class="flex justify-end"  v-if="selectedTier === this.data.plans.tier_2">
                        <button v-on:click="removeSponsoredPlan()">
                            Undo
                        </button>
                    </span>
                    <input v-model="selectedTier" class="hidden" id="selectedTier_2" type="radio" name="selectedTier" :value="data.plans.tier_2">
                </li>
                <li class="border border-gray-300 p-gutter">
                    <label class="account-billing__checkbox" for="selectedTier_3">
                        <span class="account-billing__details-col">
                            <span class="block mb-2 leading-tight"><span class="text-xl font-bold">+$250.00</span> <span class="inline-block">/ month</span></span>
                            <span class="block leading-tight paragraph-default">10 sponsored listings</span>
                        </span>
                        <span class="account-billing__button-col">
                            <span v-if="selectedTier === this.data.plans.tier_3" class="account-billing__button-checked">
                                Added <svg class="ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                            </span>
                            <span v-else class="account-billing__button-unchecked">
                                Add to Plan
                            </span>
                        </span>
                    </label>
                    <span class="flex justify-end"  v-if="selectedTier === this.data.plans.tier_3">
                        <button v-on:click="removeSponsoredPlan()">
                            Undo
                        </button>
                    </span>
                    <input v-model="selectedTier" class="hidden" id="selectedTier_3" type="radio" name="selectedTier" :value="data.plans.tier_3">
                </li>
                <li class="border border-gray-300 p-gutter">
                    <label class="account-billing__checkbox" for="selectedTier_4">
                        <span class="account-billing__details-col">
                            <span class="block mb-2 leading-tight"><span class="text-xl font-bold">+$500.00</span> <span class="inline-block">/ month</span></span>
                            <span class="block leading-tight paragraph-default">Unlimited sponsored listings</span>
                        </span>
                        <span class="account-billing__button-col">
                            <span v-if="selectedTier === this.data.plans.tier_4" class="account-billing__button-checked">
                                Added <svg class="ml-4 text-xl icon icon-checkmark"><use xlink:href="#icon-checkmark"></use></svg>
                            </span>
                            <span v-else class="account-billing__button-unchecked">
                                Add to Plan
                            </span>
                        </span>
                    </label>
                    <span class="flex justify-end"  v-if="selectedTier === this.data.plans.tier_4">
                        <button v-on:click="removeSponsoredPlan()">
                            Undo
                        </button>
                    </span>
                    <input v-model="selectedTier" class="hidden" id="selectedTier_4" type="radio" name="selectedTier" :value="data.plans.tier_4">
                </li>
            </ul>

        </div>

        <div class="mb-12 billing-sub">
            <h2 class="mb-8 hdg-5">Payment Method</h2>

            <div v-if="addPaymentStatusError" class="alert alert-error">
                {{ addPaymentStatusError }}
            </div>

            <div class="form-skin">

                <div class="form-group">
                    <label for="card-holder-name">Card Holder Name</label>

                    <div class="form-group__input">
                        <input v-model="name" id="card-holder-name" type="text" class="" name="card-holder-name" required>
                    </div>
                </div>

                <div class="form-group">
                    <label for="card-element">Card</label>

                    <div class="form-group__input form-group__input--stripe">
                        <div id="card-element"></div>
                    </div>
                </div>
            </div>

        </div>

        <div class="flex billing-sub">
            <button class="flex-initial ml-auto btn-green" id="add-card-button" v-on:click="startSubscription()">
                Subscribe
            </button>
        </div>

        <div class="flex justify-center h-screen mx-auto my-auto mt-12">
            <div class="hidden loader"></div>
        </div>
    </div>
</template>

<script>
import StripeMixin from '../../mixins/StripeMixin';
export default {
  mixins: [StripeMixin],
  props: ['data'],
  data() {
    return {
      selectedPlan: this.data.plans.single,
      selectedTier: false,
      extras: false,
      tier_1: false,
      tier_2: false,
      tier_3: false,
      tier_4: false,
      name: '',
      addPaymentStatus: 0,
      addPaymentStatusError: '',
      isRunning: false,
    };
  },
  mounted() {
  },
  methods: {
    startSubscription() {
      const _this = this;

      if ( ! _this.isRunning ) {
        _this.isRunning = true;
        _this.addPaymentStatusError = '';

        $( '.billing-sub' ).hide();
        $( '.loader' ).removeClass( 'hidden' );
        _this.addPaymentStatus = 1;

        _this.stripe.confirmCardSetup(
            _this.intent.client_secret, {
              payment_method: {
                card: _this.card,
                billing_details: {
                  name: _this.name,
                },
              },
            }
        ).then( function( result ) {
          if ( result.error ) {
            $( '.billing-sub' ).show();
            $( '.loader' ).addClass( 'hidden' );
            _this.isRunning = false;
            _this.addPaymentStatus = 3;
            _this.addPaymentStatusError = result.error.message;
          } else {
            _this.savePaymentMethod( result.setupIntent.payment_method );
            _this.addPaymentStatus = 2;
            _this.card.clear();
            _this.name = '';
            _this.isRunning = false;
          }
        } );
      }
    },
    submitPaymentMethod() {
      const _this = this;
    },
    savePaymentMethod( method ) {
      const _this = this;
      axios.post( '/api/account/payments', {
        payment_method: method,
      } ).then( function() {
        axios.put( '/api/account/subscription', {
          subscriptionPlan: _this.selectedPlan,
          extrasPlan: _this.extras,
          tierPlan: _this.selectedTier,
          payment: method.id,
        } ).then( function( response ) {
          window.location.href = '/profile';
        } );
      } );
    },
    removeSponsoredPlan() {
      this.selectedTier = false;
    },
  },
};
</script>
