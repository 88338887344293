<template>
    <div>
        <div v-if="resumes.length > 0">
            <div class="row mb-4">
                <div class="col flex lg:w-3/12 w-3/4 justify-between">
                    <div class=""><a href="/resumes" class="account__button border-gray-500">View All</a></div>
                    <div class=""><a href="/active-resumes" class="account__button">Sent Resumes</a></div>
                </div>
            </div>
            <div class="row mb-4 hidden md:flex">
                <div class="col resumes__table-col-title">
                    <span class="resumes__table-heading">Title</span>
                </div>
                <div class="col resumes__table-col-date">
                    <span class="resumes__table-heading">Date</span>
                </div>
                <div class="col resumes__table-col-status">
                    <span class="resumes__table-heading">Status</span>
                </div>
                <div class="col resumes__table-col-share">
                    <span class="resumes__table-heading">Share</span>
                </div>
                <div class="col resumes__table-col-delete">
                    <span class="resumes__table-heading">Delete</span>
                </div>
            </div>

            <div v-for="(resume, resumeIndex) in resumes" :key="resumeIndex"  class="block bg-white rounded-sm mb-3 py-4 hover:shadow-xxl">
                <div class="row hidden md:flex">
                    <div class="col resumes__table-col-title">
                        <a :href="'/resumes/' + resume.id + '/edit'" class="inline-block pl-5 text-xs text-gray-500 font-medium hover:text-blue w-full h-full">{{ resume.title }}</a>
                    </div>
                    <div class="col resumes__table-col-date">
                        <span v-if="resume.updated_at" class="text-xs font-medium text-gray-500">Updated</span>
                        <span v-else-if="resume.created_at" class="text-xs font-medium text-gray-500">Created</span>
                        <span v-if="resume.updated_at" class="text-xs text-gray-500 underline">{{ formatDate( resume.updated_at ) }}</span>
                        <span v-else-if="resume.created_at" class="text-xs text-gray-500 underline">{{ formatDate( resume.created_at ) }}</span>
                    </div>
                    <div class="col resumes__table-col-status">
                        <span v-if="resume.is_active" class="resumes__status-published">Published</span>
                        <span v-else class="resumes__status-draft">Draft</span>
                    </div>
                    <div class="col resumes__table-col-share">
                        <button type="button" class="hover:text-green js-init-popup" :data-modal="'#share-' + resume.id"><svg class="icon icon-arrow-3"><use xlink:href="#icon-arrow-3"></use></svg></button>
                        <share-resume :popup-id="'share-' + resume.id" :cover-letters="coverLetters" :resume="resume"></share-resume>
                    </div>
                    <div class="col resumes__table-col-delete">
                        <button @click="handleDelete( resume.id )" type="button" class="pr-5 hover:text-error"><svg class="icon icon-trash"><use xlink:href="#icon-trash"></use></svg></button>
                    </div>
                </div>

                <div class="row md:hidden">
                    <div class="col w-full">
                        <div class="pl-5 mb-2">
                            <span class="resumes__table-heading">Title</span><br>
                            <a :href="'/resumes/' + resume.id + '/edit'" class="inline-block text-xs text-gray-500 font-medium hover:text-blue">{{ resume.title }}</a>
                        </div>
                        <div class="pl-5 mb-2">
                            <span class="resumes__table-heading">Date</span><br>
                            <span v-if="resume.updated_at" class="text-xs font-medium text-gray-500">Updated</span>
                            <span v-else-if="resume.created_at" class="text-xs font-medium text-gray-500">Created</span>
                            <span v-if="resume.updated_at" class="text-xs text-gray-500 underline">{{ formatDate( resume.updated_at ) }}</span>
                            <span v-else-if="resume.created_at" class="text-xs text-gray-500 underline">{{ formatDate( resume.created_at ) }}</span>
                        </div>
                        <div class="pl-5 mb-2">
                            <span class="resumes__table-heading">Status</span><br>
                            <span v-if="resume.is_active" class="resumes__status-published w-auto px-4">Published</span>
                            <span v-else class="resumes__status-draft w-auto px-4">Draft</span>
                        </div>
                        <div class="pl-5 mb-2">
                            <span class="resumes__table-heading">Share</span><br>
                            <button type="button" class="hover:text-green js-init-popup" :data-modal="'#share-' + resume.id"><svg class="icon icon-arrow-3"><use xlink:href="#icon-arrow-3"></use></svg></button>
                        </div>
                        <div class="pl-5">
                            <span class="resumes__table-heading">Delete</span><br>
                            <button @click="handleDelete( resume.id )" type="button" class="pr-5 hover:text-error"><svg class="icon icon-trash"><use xlink:href="#icon-trash"></use></svg></button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import * as moment from 'moment-timezone';
export default {
  props: ['resumes', 'coverLetters'],
  data() {
    return {
      isRunning: false,
    };
  },
  mounted() {
  },
  methods: {
    formatDate: function( date ) {
      return moment( date ).format( 'MM/DD/YYYY' );
    },
    handleDelete( id ) {
      const _this = this;

      if ( this.isRunning === false ) {
        // Submit form
        axios.delete( '/resumes/' + id )
            .then( ( response ) => {
              window.location.href = '/resumes';
            } ).catch( ( error ) => {
              console.log( error );
              _this.isRunning = false;
            } );
      }
    },
  },
};
</script>
