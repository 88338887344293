<template>
    <div>
        <p class="hdg-6 mb-4">Job Preferences</p>

        <div v-if="jobPreferences.length > 0" class="mb-8">
            <div v-for="(jobPreference, jobPreferenceIndex) in jobPreferences" :key="jobPreferenceIndex" class="relative box bg-bgblue mb-8">

                <div class="flex flex-wrap -mx-2">
                    <div class="flex-initial px-2 w-full mb-5 xl:mb-0">
                        <div class="flex flex-wrap -mx-2">

                            <div class="flex-initial w-full px-2">
                                <input v-model="jobPreference.open_to_work" type="hidden" :id="'attribute_' + jobPreferenceIndex" class="" :name="'attribute_' + jobPreferenceIndex" />

                                <div class="form-group">
                                    <label for="industry">Industry</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select v-model="jobPreference.industry_id" id="industry" class="" name="industry" required>
                                            <option value=""></option>
                                            <option v-for="(option, optionIndex) in formData.industry_options" :key="optionIndex" :value="option.id">{{ option.name }}</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="employment_type">Employment Type</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select v-model="jobPreference.employment_type_id" id="employment_type" class="" name="employment_type" required>
                                            <option value=""></option>
                                            <option v-for="(option, optionIndex) in formData.job_type_options" :key="optionIndex" :value="option.id">{{ option.name }}</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label for="job_level">Job Level</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select v-model="jobPreference.job_level_id" id="job_level" class="" name="job_level" required>
                                            <option value=""></option>
                                            <option v-for="(option, optionIndex) in formData.job_level_options" :key="optionIndex" :value="option.id">{{ option.name }}</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>

                                <div class="form-group">
                                  <div class="form-group__input form-group__input--icon">
                                      <label for="location">City</label>

                                      <div class="form-group__input">
                                          <input v-model="jobPreference.location" id="location" type="text" class="" name="location" required>
                                      </div>
                                  </div>

                                </div>

                                <div class="form-group flex">
                                  <div class="form-group__input lg:w-1/2 w-full mr-2">
                                      <label for="location">State</label>

                                      <div class="form-group__input">
                                          <input v-model="jobPreference.state" id="state" type="text" class="" name="state" required>
                                      </div>
                                  </div>
                                  <div class="form-group__input lg:w-1/2 w-full ml-2">
                                      <label for="location">Zip Code</label>

                                      <div class="form-group__input">
                                          <input v-model="jobPreference.zip_code" id="zip_code" type="text" class="" name="zip_code" required>
                                      </div>
                                  </div>
                                </div>

                                <div class="form-check mb-5 mt-8">
                                    <input type="checkbox" name="willing_to_relocate" id="willing_to_relocate" v-model="jobPreference.willing_to_relocate">
                                    <label for="willing_to_relocate">Willing to relocate</label>
                                </div>

                                <p class="flex-initial w-full">Salary Range (optional)</p>

                                <div class="flex justify-between">
                                    <div class="form-group lg:w-1/2 mr-2">
                                        <div class="form-group__input form-group__input--icon">

                                            <select v-model="jobPreference.salary_min" id="salary-minimum" type="text" class="" name="salary-minimum">
                                                <option value=""></option>
                                                <option value="0">$0</option>
                                                <option value="5000">$5,000</option>
                                                <option value="10000">$10,000</option>
                                                <option value="15000">$15,000</option>
                                                <option value="20000">$20,000</option>
                                                <option value="25000">$25,000</option>
                                                <option value="30000">$30,000</option>
                                                <option value="35000">$35,000</option>
                                                <option value="40000">$40,000</option>
                                                <option value="45000">$45,000</option>
                                                <option value="50000">$50,000</option>
                                                <option value="55000">$55,000</option>
                                                <option value="60000">$60,000</option>
                                                <option value="65000">$65,000</option>
                                                <option value="70000">$70,000</option>
                                                <option value="75000">$75,000</option>
                                                <option value="80000">$80,000</option>
                                                <option value="85000">$85,000</option>
                                                <option value="90000">$90,000</option>
                                                <option value="95000">$95,000</option>
                                                <option value="100000">$100,000</option>
                                                <option value="110000">$110,000</option>
                                                <option value="120000">$120,000</option>
                                                <option value="130000">$130,000</option>
                                                <option value="140000">$140,000</option>
                                                <option value="150000">$150,000</option>
                                                <option value="160000">$160,000</option>
                                                <option value="170000">$170,000</option>
                                                <option value="180000">$180,000</option>
                                                <option value="190000">$190,000</option>
                                                <option value="200000">$200,000+</option>
                                            </select>
                                            <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>

                                        </div>
                                        <label for="minimum_salary">Minimum Salary</label>
                                    </div>

                                    <div class="form-group lg:w-1/2 ml-2">
                                        <div class="form-group__input form-group__input--icon">
                                            <select v-model="jobPreference.salary_max" id="salary-maximum" type="text" class="" name="salary-maximum">
                                                <option value=""></option>
                                                <option value="0">$0</option>
                                                <option value="5000">$5,000</option>
                                                <option value="10000">$10,000</option>
                                                <option value="15000">$15,000</option>
                                                <option value="20000">$20,000</option>
                                                <option value="25000">$25,000</option>
                                                <option value="30000">$30,000</option>
                                                <option value="35000">$35,000</option>
                                                <option value="40000">$40,000</option>
                                                <option value="45000">$45,000</option>
                                                <option value="50000">$50,000</option>
                                                <option value="55000">$55,000</option>
                                                <option value="60000">$60,000</option>
                                                <option value="65000">$65,000</option>
                                                <option value="70000">$70,000</option>
                                                <option value="75000">$75,000</option>
                                                <option value="80000">$80,000</option>
                                                <option value="85000">$85,000</option>
                                                <option value="90000">$90,000</option>
                                                <option value="95000">$95,000</option>
                                                <option value="100000">$100,000</option>
                                                <option value="110000">$110,000</option>
                                                <option value="120000">$120,000</option>
                                                <option value="130000">$130,000</option>
                                                <option value="140000">$140,000</option>
                                                <option value="150000">$150,000</option>
                                                <option value="160000">$160,000</option>
                                                <option value="170000">$170,000</option>
                                                <option value="180000">$180,000</option>
                                                <option value="190000">$190,000</option>
                                                <option value="200000">$200,000+</option>
                                            </select>
                                            <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                        </div>
                                        <label for="minimum_salary">Maximum Salary</label>
                                    </div>
                                </div>

                            </div>


                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
  props: ['userJobPreferences', 'formData'],
  data() {
    return {
      jobPreferences: [],
      year: new Date().getFullYear(),
    };
  },
  mounted() {
    this.setJobPreferences();
  },
  methods: {
    setJobPreferences: function() {
      if ( this.userJobPreferences.length > 0 ) {
        this.jobPreferences = Object.assign( [], this.userJobPreferences );
      } else {
        this.addJobPreference();
      }
    },
    addJobPreference: function() {
      this.jobPreferences.push(
          {
            id: false,
            open_to_work: '',
            industry_id: '',
            employment_type_id: '',
            job_level_id: '',
            location: '',
            zip_code: '',
            state: '',
            willing_to_relocate: false,
            salary_min: '',
            salary_max: '',
            user_open: '',
          }
      );
    },
    removeJobPreference: function( index ) {
      this.jobPreferences.splice( index, 1 );
    },
  },
  watch: {
    jobPreferences() {
      this.$emit( 'update', this.jobPreferences );
    },
  },
};
</script>
