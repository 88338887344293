<template>
    <div>
        <p class="hdg-6 mb-4">Work Experience</p>

        <div v-if="workExperiences.length > 0" class="mb-8">
            <div v-for="(workExperience, workExperienceIndex) in workExperiences" :key="workExperienceIndex" class="relative box bg-bgblue mb-8">
                <button class="absolute right-0 top-0 p-4 hover:text-error" @click="removeWorkExperience( workExperienceIndex )" type="button" :name="'remove_work_experience_' + workExperienceIndex"><svg class="block text-lg icon icon-minus"><use xlink:href="#icon-minus"></use></svg></button>

                <div class="form-group">
                    <label :for="'company_' + workExperienceIndex">Company</label>

                    <div class="form-group__input">
                        <input :id="'company_' + workExperienceIndex" type="text" class="" :name="'company_' + workExperienceIndex" v-model="workExperience.company" required>
                    </div>
                </div>

                <div class="form-group">
                    <label :for="'location_' + workExperienceIndex">Location</label>

                    <div class="form-group__input">
                        <input :id="'location_' + workExperienceIndex" type="text" class="" :name="'location_' + workExperienceIndex" v-model="workExperience.location" required>
                    </div>
                </div>

                <div class="form-group">
                    <label :for="'job_title_' + workExperienceIndex">Position/Job Title</label>

                    <div class="form-group__input">
                        <input :id="'job_title_' + workExperienceIndex" type="text" class="" :name="'job_title_' + workExperienceIndex" v-model="workExperience.job_title" required>
                    </div>
                </div>

                <div class="form-group">
                    <label :for="'employment_type_' + workExperienceIndex">Employment Type</label>

                    <div class="form-group__input form-group__input--icon">
                        <select :id="'employment_type_' + workExperienceIndex" class="" :name="'employment_type_' + workExperienceIndex" v-model="workExperience.employment_type" required>
                            <option value="Full Time">Full Time</option>
                            <option value="Part Time">Part Time</option>
                            <option value="Contract">Contract</option>
                            <option value="Temporary">Temporary</option>
                            <option value="Commission">Commission</option>
                            <option value="Internship">Internship</option>
                        </select>
                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                    </div>
                </div>

                <div class="form-check mb-5 mt-8">
                    <input type="checkbox" :name="'current_job_' + workExperienceIndex" :id="'current_job_' + workExperienceIndex" v-model="workExperience.current_job">

                    <label :for="'current_job_' + workExperienceIndex">This is my current job</label>
                </div>

                <div class="flex -mx-2 flex-wrap">
                    <div class="flex-initial px-2 w-full xl:w-1/2 mb-5 xl:mb-0">
                        <div class="flex flex-wrap -mx-2">
                            <div class="flex-initial w-full px-2">
                                <p class="font-bold text-gray-500 mb-2">Start Date</p>
                            </div>

                            <div class="flex-initial w-1/2 px-2">
                                <div class="form-group mb-0">
                                    <label :for="'start_date_month_' + workExperienceIndex">Month</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select :id="'start_date_month_' + workExperienceIndex" class="" :name="'start_date_month_' + workExperienceIndex" v-model="workExperience.start_date_month" required>
                                            <option value=""></option>
                                            <option value="January">Jan</option>
                                            <option value="February">Feb</option>
                                            <option value="March">Mar</option>
                                            <option value="April">Apr</option>
                                            <option value="May">May</option>
                                            <option value="June">June</option>
                                            <option value="July">July.</option>
                                            <option value="August">Aug</option>
                                            <option value="September">Sept</option>
                                            <option value="October">Oct</option>
                                            <option value="November">Nov</option>
                                            <option value="December">Dec</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>
                            </div>

                            <div class="flex-initial w-1/2 px-2">
                                <div class="form-group mb-0">
                                    <label :for="'start_date_year_' + workExperienceIndex">Year</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select :id="'start_date_year_' + workExperienceIndex" class="" :name="'start_date_year_' + workExperienceIndex" v-model="workExperience.start_date_year" required>
                                            <option value=""></option>
                                            <option v-for="index in 50" :key="index" :value="year - index + 1">{{ year - index + 1}}</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="! workExperience.current_job" class="flex-initial px-2 w-full xl:w-1/2">
                        <div class="flex flex-wrap -mx-2">
                            <div class="flex-initial w-full px-2">
                                <p class="font-bold text-gray-500 mb-2">End Date</p>
                            </div>

                            <div class="flex-initial w-1/2 px-2">
                                <div class="form-group mb-0">
                                    <label :for="'end_date_month_' + workExperienceIndex">Month</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select :id="'end_date_month_' + workExperienceIndex" class="" :name="'end_date_month_' + workExperienceIndex" v-model="workExperience.end_date_month" required>
                                            <option value=""></option>
                                            <option value="January">Jan</option>
                                            <option value="February">Feb</option>
                                            <option value="March">Mar</option>
                                            <option value="April">Apr</option>
                                            <option value="May">May</option>
                                            <option value="June">June</option>
                                            <option value="July">July.</option>
                                            <option value="August">Aug</option>
                                            <option value="September">Sept</option>
                                            <option value="October">Oct</option>
                                            <option value="November">Nov</option>
                                            <option value="December">Dec</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>
                            </div>

                            <div class="flex-initial w-1/2 px-2">
                                <div class="form-group mb-0">
                                    <label :for="'end_date_year_' + workExperienceIndex">Year</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select :id="'end_date_year_' + workExperienceIndex" class="" :name="'end_date_year_' + workExperienceIndex" v-model="workExperience.end_date_year" required>
                                            <option value=""></option>
                                            <option v-for="index in 50" :key="index" :value="year - index + 1">{{ year - index + 1}}</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button @click="addWorkExperience" type="button" name="add_work_experience" class="btn-dashed"><svg class="icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>Add Work Experience</button>
    </div>
</template>

<script>
export default {
  props: ['userWorkExperiences'],
  data() {
    return {
      workExperiences: [],
      year: new Date().getFullYear(),
    };
  },
  mounted() {
    this.setWorkExperiences();
  },
  methods: {
    setWorkExperiences: function() {
      if ( this.userWorkExperiences.length > 0 ) {
        this.workExperiences = Object.assign( [], this.userWorkExperiences );
      } else {
        this.addWorkExperience();
      }
    },
    addWorkExperience: function() {
      this.workExperiences.push(
          {
            id: false,
            company: '',
            location: '',
            job_title: '',
            employment_type: 'Full Time',
            current_job: false,
            start_date_month: '',
            start_date_year: '',
            end_date_month: '',
            end_date_year: '',
          }
      );
    },
    removeWorkExperience: function( index ) {
      this.workExperiences.splice( index, 1 );
    },
  },
  watch: {
    workExperiences() {
      this.$emit( 'update', this.workExperiences );
    },
  },
};
</script>
