<template>
    <div>
      <p class="hdg-6 mb-4">Job Details</p>
      <div v-if="details.length > 0" class="mb-8">
        <div v-for="(detail, detailIndex) in details" :key="detailIndex" class="relative box bg-bgblue mb-8">
          <div class="flex flex-wrap -mx-2">
            <div class="flex-initial px-2 w-full mb-5 xl:mb-0">
              <div class="flex flex-wrap -mx-2">
                <div class="flex-initial w-full px-2">

                  <div class="form-group mb-2">
                      <label :for="'description_' + detailIndex">Description</label>
                      <div class="form-group__input form-group__input--icon">
                          <textarea v-model="detail.description" :id="'description_' + detailIndex" :name="'description_' + detailIndex" class="" required></textarea>
                      </div>
                  </div>

                  <div class="form-group">
                      <label :for="'industry_'+detailIndex">Industry</label>
                      <div class="form-group__input form-group__input--icon">
                          <select v-model="detail.industry_id" :id="'industry_'+detailIndex" class="" :name="'industry_'+detailIndex" required>
                              <option value=""></option>
                              <option v-for="(option, optionIndex) in industry" :key="optionIndex" :value="option.id">{{ option.name }}</option>
                          </select>
                          <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                      </div>
                  </div>

                  <div class="form-group">
                      <label for="job_level">Job Level</label>
                      <div class="form-group__input form-group__input--icon">
                          <select v-model="detail.job_level_id" id="job_level" class="" name="job_level" required>
                              <option value=""></option>
                              <option v-for="(option, optionIndex) in jobLevel" :key="optionIndex" :value="option.id">{{ option.name }}</option>
                          </select>
                          <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                      </div>
                  </div>

                  <div class="form-group">
                    <label for="employment_type">Employment Type</label>
                    <div class="form-group__input form-group__input--icon">
                      <select v-model="detail.employment_type_id" id="employment_type" class="" name="employment_type" required>
                        <option value=""></option>
                        <option v-for="(option, optionIndex) in employmentType" :key="optionIndex" :value="option.id">{{ option.name }}</option>
                      </select>
                      <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                    </div>
                  </div>

                  <div class="form-group">
                      <label :for="'location_' + detailIndex">City</label>
                      <div class="form-group__input">
                          <input :id="'location_'+ detailIndex" type="text" :name="'location_' + detailIndex" v-model="detail.location" required>
                      </div>
                  </div>

                  <div class="form-group flex">
                      <div class="lg:w-1/2 w-full mr-2">
                        <label :for="'state_' + detailIndex">State</label>
                        <div class="form-group__input">
                            <input :id="'state_'+ detailIndex" type="text" :name="'state_' + detailIndex" v-model="detail.state" required>
                        </div>
                      </div>
                      <div class="lg:w-1/2 w-full ml-2">
                        <label :for="'zip_code_' + detailIndex">Zip</label>
                        <div class="form-group__input">
                            <input :id="'zip_code_'+ detailIndex" type="text" :name="'zip_code_' + detailIndex" v-model="detail.zip_code" required>
                        </div>
                      </div>
                  </div>

                  <div class="form-check mb-5 mt-8">
                      <input type="checkbox" :name="'willing_to_relocate_'+detailIndex" :id="'willing_to_relocate_'+detailIndex" v-model="detail.willing_to_relocate">
                      <label :for="'willing_to_relocate_'+detailIndex">Willing to relocate</label>
                  </div>

                  <p>Salary Range (optional)</p>
                  <div class="form-group flex">
                    <div class="lg:w-1/2 w-full pr-2">
                      <div class="form-group__input flex">
                        <select v-model="detail.salary_min" :id="'minimum_salary_'+detailIndex" class="" :name="'minimum_salary_'+detailIndex" >
                          <option value=""></option>
                          <option value="0">$0</option>
                          <option value="5000">$5,000</option>
                          <option value="10000">$10,000</option>
                          <option value="15000">$15,000</option>
                          <option value="20000">$20,000</option>
                          <option value="25000">$25,000</option>
                          <option value="30000">$30,000</option>
                          <option value="35000">$35,000</option>
                          <option value="40000">$40,000</option>
                          <option value="45000">$45,000</option>
                          <option value="50000">$50,000</option>
                          <option value="55000">$55,000</option>
                          <option value="60000">$60,000</option>
                          <option value="65000">$65,000</option>
                          <option value="70000">$70,000</option>
                          <option value="75000">$75,000</option>
                          <option value="80000">$80,000</option>
                          <option value="85000">$85,000</option>
                          <option value="90000">$90,000</option>
                          <option value="95000">$95,000</option>
                          <option value="100000">$100,000</option>
                          <option value="110000">$110,000</option>
                          <option value="120000">$120,000</option>
                          <option value="130000">$130,000</option>
                          <option value="140000">$140,000</option>
                          <option value="150000">$150,000</option>
                          <option value="160000">$160,000</option>
                          <option value="170000">$170,000</option>
                          <option value="180000">$180,000</option>
                          <option value="190000">$190,000</option>
                          <option value="200000">$200,000+</option>
                        </select>
                        <svg class="icon icon-arrow relative right-gutter mt-4"><use xlink:href="#icon-arrow"></use></svg>
                      </div>
                      <label :for="'minimum_salary_'+detailIndex">Minimum</label>
                    </div>

                    <div class="lg:w-1/2 w-full pl-2">
                      <div class="form-group__input flex">
                          <option value=""></option>
                        <select v-model="detail.salary_max" :id="'maximum_salary_'+detailIndex" class="" :name="'maximum_salary_'+detailIndex" >
                          <option value="0">$0</option>
                          <option value="5000">$5,000</option>
                          <option value="10000">$10,000</option>
                          <option value="15000">$15,000</option>
                          <option value="20000">$20,000</option>
                          <option value="25000">$25,000</option>
                          <option value="30000">$30,000</option>
                          <option value="35000">$35,000</option>
                          <option value="40000">$40,000</option>
                          <option value="45000">$45,000</option>
                          <option value="50000">$50,000</option>
                          <option value="55000">$55,000</option>
                          <option value="60000">$60,000</option>
                          <option value="65000">$65,000</option>
                          <option value="70000">$70,000</option>
                          <option value="75000">$75,000</option>
                          <option value="80000">$80,000</option>
                          <option value="85000">$85,000</option>
                          <option value="90000">$90,000</option>
                          <option value="95000">$95,000</option>
                          <option value="100000">$100,000</option>
                          <option value="110000">$110,000</option>
                          <option value="120000">$120,000</option>
                          <option value="130000">$130,000</option>
                          <option value="140000">$140,000</option>
                          <option value="150000">$150,000</option>
                          <option value="160000">$160,000</option>
                          <option value="170000">$170,000</option>
                          <option value="180000">$180,000</option>
                          <option value="190000">$190,000</option>
                          <option value="200000">$200,000+</option>
                        </select>
                        <svg class="icon icon-arrow relative right-gutter mt-4"><use xlink:href="#icon-arrow"></use></svg>
                      </div>
                      <label :for="'maximum_salary_'+detailIndex">Maximum</label>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['jobListingDetails', 'jobListingDetailsOptions', 'industry', 'jobLevel', 'employmentType'],
  data() {
    return {
      details: [],
      year: new Date().getFullYear(),
    };
  },
  mounted() {
    this.setJobListingDetails();
  },
  methods: {
    setJobListingDetails: function() {
      if ( this.jobListingDetails.length > 0 ) {
        this.details = Object.assign( [], this.jobListingDetails );
      } else {
        this.addJobListingDetails( false );
      }
    },
    addJobListingDetails: function( id ) {
      if ( id !== false ) {
        this.details.push(
            Object.assign( {}, this.jobListingDetailsOptions[id] )
        );
      } else {
        this.details.push(
            {
              id: false,
              description: '',
              industry: '',
              job_level: '',
              employment_type: '',
              location: '',
              willing_to_relocate: false,
              salary_min: '',
              salary_max: '',
            }
        );
      }
    },
    removeDetail: function( index ) {
      this.details.splice( index, 1 );
    },
  },
  watch: {
    details() {
      this.$emit( 'update', this.details );
    },
  },
};
</script>
