<template>
    <div>
      <div class="find-jobs__filter_checkbox_group_label">Willing to Relocate</div>
      <div class="find-jobs__filter_checkbox_group border-none mb-12">

        <div><input v-model="jobFilterRelocate" value="1" id="find-jobs__filter_willing_to_relocate-yes" name="find-jobs__filter_willing_to_relocate-yes" type="checkbox"/><label for="find-jobs__filter_willing_to_relocate-yes">Yes</label></div>
        <div><input v-model="jobFilterRelocate" value="no" id="find-jobs__filter_willing_to_relocate-no" name="find-jobs__filter_willing_to_relocate-no" type="checkbox"/><label for="find-jobs__filter_willing_to_relocate-no">No</label></div>

      </div>
    </div>
</template>

<script>
export default {
  props: ['jobTypes'],
  data() {
    return {
      jobFilterRelocate: [],
      year: new Date().getFullYear(),
    };
  },
  methods: {

  },
  watch: {
    jobFilterRelocate( ) {
      this.$emit( 'update', this.jobFilterRelocate );
    },
  },
};
</script>
