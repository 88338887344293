<template>
    <div>
        <p class="hdg-6 mb-4">Education</p>

        <div v-if="educations.length > 0" class="mb-8">
            <div v-for="(education, educationIndex) in educations" :key="educationIndex" class="relative box bg-bgblue mb-8">
                <button class="absolute right-0 top-0 p-4 hover:text-error" @click="removeEducation( educationIndex )" type="button" :name="'remove_education_' + educationIndex"><svg class="block text-lg icon icon-minus"><use xlink:href="#icon-minus"></use></svg></button>

                <div class="form-group">
                    <label :for="'degree_' + educationIndex">Degree</label>

                    <div class="form-group__input">
                        <input v-model="education.degree" :id="'degree_' + educationIndex" type="text" class="" :name="'degree_' + educationIndex" required>
                    </div>
                </div>

                <div class="form-group">
                    <label :for="'school_location_' + educationIndex">School/Location</label>

                    <div class="form-group__input">
                        <input v-model="education.school_location" :id="'school_location_' + educationIndex" type="text" class="" :name="'school_location_' + educationIndex" required>
                    </div>
                </div>

                <div class="form-check mb-5 mt-8">
                    <input v-model="education.current_school" type="checkbox" :name="'current_school_' + educationIndex" :id="'current_school_' + educationIndex">

                    <label :for="'current_school_' + educationIndex">Currently enrolled</label>
                </div>

                <div class="flex flex-wrap -mx-2">
                    <div class="flex-initial px-2 w-full xl:w-1/2 mb-5 xl:mb-0">
                        <div class="flex flex-wrap -mx-2">
                            <div class="flex-initial w-full px-2">
                                <p class="font-bold text-gray-500 mb-2">Start Date</p>
                            </div>

                            <div class="flex-initial w-1/2 px-2">
                                <div class="form-group mb-0">
                                    <label :for="'start_date_month_' + educationIndex">Month</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select v-model="education.start_date_month" :id="'start_date_month_' + educationIndex" class="" :name="'start_date_month_' + educationIndex" required>
                                            <option value=""></option>
                                            <option value="January">Jan</option>
                                            <option value="February">Feb</option>
                                            <option value="March">Mar</option>
                                            <option value="April">Apr</option>
                                            <option value="May">May</option>
                                            <option value="June">June</option>
                                            <option value="July">July.</option>
                                            <option value="August">Aug</option>
                                            <option value="September">Sept</option>
                                            <option value="October">Oct</option>
                                            <option value="November">Nov</option>
                                            <option value="December">Dec</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>
                            </div>

                            <div class="flex-initial w-1/2 px-2">
                                <div class="form-group mb-0">
                                    <label :for="'start_date_year_' + educationIndex">Year</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select v-model="education.start_date_year" :id="'start_date_year_' + educationIndex" class="" :name="'start_date_year_' + educationIndex" required>
                                            <option value=""></option>
                                            <option v-for="index in 50" :key="index" :value="year - index + 1">{{ year - index + 1 }}</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="! education.current_school" class="flex-initial px-2 w-full xl:w-1/2">
                        <div class="flex flex-wrap -mx-2">
                            <div class="flex-initial w-full px-2">
                                <p class="font-bold text-gray-500 mb-2">End Date</p>
                            </div>

                            <div class="flex-initial w-1/2 px-2">
                                <div class="form-group mb-0">
                                    <label :for="'end_date_month_' + educationIndex">Month</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select v-model="education.end_date_month" :id="'end_date_month_' + educationIndex" class="" :name="'end_date_month_' + educationIndex" required>
                                            <option value=""></option>
                                            <option value="January">Jan</option>
                                            <option value="February">Feb</option>
                                            <option value="March">Mar</option>
                                            <option value="April">Apr</option>
                                            <option value="May">May</option>
                                            <option value="June">June</option>
                                            <option value="July">July.</option>
                                            <option value="August">Aug</option>
                                            <option value="September">Sept</option>
                                            <option value="October">Oct</option>
                                            <option value="November">Nov</option>
                                            <option value="December">Dec</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>
                            </div>

                            <div class="flex-initial w-1/2 px-2">
                                <div class="form-group mb-0">
                                    <label :for="'end_date_year_' + educationIndex">Year</label>

                                    <div class="form-group__input form-group__input--icon">
                                        <select v-model="education.end_date_year" :id="'end_date_year_' + educationIndex" class="" :name="'end_date_year_' + educationIndex" required>
                                            <option value=""></option>
                                            <option v-for="index in 50" :key="index" :value="year - index + 1">{{ year - index + 1 }}</option>
                                        </select>
                                        <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button @click="addEducation" type="button" name="add_education" class="btn-dashed"><svg class="icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>Add Education</button>
    </div>
</template>

<script>
export default {
  props: ['userEducations'],
  data() {
    return {
      educations: [],
      year: new Date().getFullYear(),
    };
  },
  mounted() {
    this.setWorkExperiences();
  },
  methods: {
    setWorkExperiences: function() {
      if ( this.userEducations.length > 0 ) {
        this.educations = Object.assign( [], this.userEducations );
      } else {
        this.addEducation();
      }
    },
    addEducation: function() {
      this.educations.push(
          {
            id: false,
            degree: '',
            school_location: '',
            current_school: false,
            start_date_month: '',
            start_date_year: '',
            end_date_month: '',
            end_date_year: '',
          }
      );
    },
    removeEducation: function( index ) {
      this.educations.splice( index, 1 );
    },
  },
  watch: {
    educations() {
      this.$emit( 'update', this.educations );
    },
  },
};
</script>
