<template>
    <form @submit.prevent="handleApply">
        <div class="mb-5">
            <div class="items-center row">
                <div class="w-full col">
                    <div class="flex justify-between" v-if="!viewing">
                        <h1 class="my-auto hdg-4">{{jobListing.listing_title}}</h1>
                        <button type="submit" class="btn btn-save" @submit.prevent="handleApply">Apply Now</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-10">
            <div class="block p-4 bg-white border border-gray-300 rounded-sm lg:p-12">
            <div class="hidden row md:flex">
                <div class="w-12 h-12 ml-5 profile__image" v-if="jobListing.company_logo" :style="{ backgroundImage: 'url(\'/uploads/' + jobListing.company_logo.path + '\')' }"></div>
                <div class="col">
                <p class="text-gray-500"><a class=" hover:text-blue" :href="'/u/'+jobListing.user_id">{{jobListing.company}}</a> &bull; {{jobListing.location}} &bull; <span>{{ formatDate }}</span></p>
                <p class="font-bold text-gray-700 underline">{{jobListing.listing_title}}</p>
                </div>
            </div>
            <div class="row">
                <div class="flex flex-col w-full mb-5 lg:flex-row col">
                <p class="w-full px-4 py-2 mt-5 mr-4 text-center uppercase lg:w-32 text-blue bg-tint-valueblue rounded-xs text-2xs tracking-spaced" v-for="(value, valueIndex) in jobListing.values" :key="valueIndex">
                    {{value}}
                </p>
                </div>
                <div class="w-full mb-5 col">
                <p>{{jobListing.description}}</p>
                </div>
                <div class="flex flex-col w-full mb-5 col lg:flex-row">
                <div class="flex flex-col w-full mb-5 lg:w-1/4">
                    <p class="font-medium">Location</p>
                    <p>{{jobListing.location}}</p>
                </div>
                <div class="flex flex-col w-full mb-5 lg:w-1/4">
                    <p class="font-medium">Salary</p>

                    <p v-if="jobListing.salary_min || jobListing.salary_max">${{jobListing.salary_min}} - ${{jobListing.salary_max}}</p>
                    <p v-else>Undisclosed</p>
                </div>
                <div class="flex flex-col w-full mb-5 lg:w-1/4">
                    <p class="font-medium">Job Type</p>
                    <p>{{jobListing.employment_type}}</p>
                </div>
                <div class="flex flex-col w-full lg:w-1/4">
                    <p class="font-medium">Job Level</p>
                    <p>{{jobListing.job_level}}</p>
                </div>
                </div>
            </div>
            </div>
        </div>

        <div class="mb-10 form-skin" >
            <div class="block p-4 bg-white border border-gray-300 rounded-sm lg:p-12">
                <div class="row">
                    <div class="mb-3 col">
                    <p class="text-xl font-bold text-gray-700">Submission Materials</p>
                    </div>
                    <div class="flex flex-col w-full p-5 mx-6 mb-12 border border-gray-400 col bg-bggrayishblue rounded-xs">
                    <div class="flex flex-col">
                        <div class="form-group">
                        <label class="text-xs text-gray-500">Cover Letter</label>
                        <div class="form-group__input form-group__input--icon">
                            <select v-model="formData.cover_letter" name="cover_letters" id="cover_letters" class="w-full h-12 border border-gray-400 shadow-sm rounded-xs" required :disabled="viewing">
                                <option :value="coverLetter.id" class="" v-for="(coverLetter, coverLetterIndex) in formData.cover_letters" :key="coverLetterIndex">{{coverLetter.title}}</option>
                            </select>
                            <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                        </div>
                        </div>
                    </div>
                    <div class="flex flex-col">
                        <div class="form-group">
                        <label class="text-xs text-gray-500">Resume</label>
                        <div class="form-group__input form-group__input--icon">
                            <select v-model="formData.resume" name="" id="" class="w-full h-12 border border-gray-400 shadow-sm rounded-xs" required :disabled="viewing">
                                <option :value="resume.id" class="" v-for="(resume, resumeIndex) in formData.resumes" :key="resumeIndex">{{resume.title}}</option>
                            </select>
                            <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="flex flex-col w-full mb-5 col">
                    <p class="text-xl font-bold text-gray-700">Pre-Interview Questions</p>
                    <p class="text-xs text-gray-500">Type an answer or Youtube and Vimeo links are accepted. Need help getting your video URL?</p>
                    </div>
                    <div class="flex flex-col w-full p-5 mx-6 mb-5 border border-gray-400 col bg-bggrayishblue rounded-xs">
                    <div class="flex flex-col" v-for="(question, questionIndex) in questions" :key="questionIndex">
                        <div class="form-group">
                        <label class="text-xs text-gray-500">{{question.question_text}}</label>
                        <div class="form-group__input form-group__input--icon">
                            <textarea v-model="formData.answers[questionIndex]" :disabled="viewing" :name="'answer_'+questionIndex" :id="'answer_'+questionIndex" class="w-full border border-gray-400 shadow-sm rounded-xs" placeholder="Type your answer here" required></textarea>
                        </div>
                        </div>
                    </div>
                    </div>

                </div>
            </div>
            <div class="flex justify-end mt-12" v-if="!viewing">
                <button type="submit" class="btn btn-save">Apply Now</button>
            </div>
        </div>

    </form>
</template>

<script>
import * as moment from 'moment-timezone';
export default {
  props: ['jobListing', 'assetPath', 'isViewing', 'answers'],
  data() {
    return {
      errors: [],
      isRunning: false,
      viewing: this.isViewing,
      questions: this.jobListing.job_questions,
      formData: {
        resume: this.jobListing.resumes[0].id,
        cover_letter: this.jobListing.cover_letters[0].id,
        cover_letters: this.jobListing.cover_letters,
        resumes: this.jobListing.resumes,
        answers: this.jobListing.answer,
        jobListingId: this.jobListing.id,
      },
    };
  },
  computed: {
    formatDate: function() {
      return moment( this.jobListing.created_at ).fromNow( );
    },
  },
  methods: {
    handleApply() {
      const _this = this;

      if ( this.isRunning === false ) {
        // Validate inputs
        _this.validateInputs();
        $( '.btn-save' ).attr( 'disabled', true );
        if ( _this.errors.length === 0 ) {
          // Submit form
          axios.post( '/api/apply', {
            data: _this.formData,
          } )
              .then( ( response ) => {
                $( '.btn-save' ).attr( 'disabled', false );
                window.location.href = '/applications';
              } ).catch( ( error ) => {
                console.log( error );
                _this.isRunning = false;
              } );
        } else {
          _this.isRunning = false;
        }
      }
    },
    validateInputs() {
      //
    },
  },
};
</script>
