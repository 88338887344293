<template>
    <div>
        <p class="mb-2 hdg-6">Video</p>

        <p class="mb-4 text-gray-500 paragraph-small">YouTube and Vimeo links are accepted. <button class="js-init-popup text-blue hover:underline" data-modal="#video-popup">Need help getting your video URL?</button></p>

        <div class="mfp-hide popup" id="video-popup">
            <div class="container">
                <div class="row">
                    <div class="w-full mx-auto col md:w-3/4 lg:w-2/3">
                        <div class="relative h-auto box box--lg">
                            <button class="js-close-popup close-popup-icon" type="button">
                                <svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg>
                            </button>

                            <div class="max-w-lg mx-auto">
                                <div class="my-6">
                                    <div class="mb-5 hdg-5">YouTube:</div>
                                    <p class="mb-5 paragraph-default">Go to the video's page and click "Share" to open a popup. Click "Embed", then copy the URL in the "src" of the Embed Video code.</p>
                                    <img class="max-w-full mb-8" :src="assetPath + 'img/youtube.png'" alt="YouTube Share Instructions">
                                    <div class="mb-5 hdg-5">Vimeo:</div>
                                    <p class="mb-5 paragraph-default">Go to the video's page and click "Share" to open a popup. In the "Embed" section, copy the URL in the "src" of the code.</p>
                                    <img class="max-w-full mb-8" :src="assetPath + 'img/vimeo.png'" alt="Vimeo Share Instructions">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="videos.length > 0" class="mb-8">
            <div v-for="(video, videoIndex) in videos" :key="videoIndex" class="relative mb-8 box bg-bgblue">
                <button class="absolute top-0 right-0 p-4 hover:text-error" @click="removeVideo( videoIndex )" type="button" :name="'remove_video_' + videoIndex"><svg class="block text-lg icon icon-minus"><use xlink:href="#icon-minus"></use></svg></button>

                <div class="form-group">
                    <label :for="'video_url_' + videoIndex">Video URL</label>

                    <div class="form-group__input">
                        <input :id="'video_url_' + videoIndex" type="text" class="" :name="'video_url_' + videoIndex" v-model="video.url">
                    </div>
                </div>

            </div>
        </div>

        <div v-if="resumeVideos.length > 0" class="relative">
            <button type="button" name="add_video" class="popover__button btn-dashed" data-toggle-class="is-open" data-toggle-outside data-toggle-target="#add-video-popup"><svg class="pointer-events-none icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>Add Video</button>

            <div class="absolute left-0 hidden w-full popover__popup bottom-100" id="add-video-popup" role="navigation">
                <div class="popover__popup-box">
                    <div>
                        <button @click="addVideo( false )" data-toggle-trigger-off class="flex items-center w-full py-3 text-sm font-medium text-left text-gray-500 hover:text-blue" type="button">Add New Video<svg class="block ml-auto mr-2 text-lg pointer-events-none icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg></button>
                    </div>

                    <div v-for="(video, videoIndex) in resumeVideos" :key="videoIndex">
                        <button v-if="!isInUse( video.id )" :data-id="videoIndex" @click="addVideo( videoIndex )" data-toggle-trigger-off class="flex items-center w-full py-3 text-sm font-medium text-left text-gray-500 border-t hover:text-blue" type="button">{{ video.url }}</button>
                    </div>
                </div>
            </div>
        </div>

        <button v-else @click="addVideo( false )" type="button" name="add_video" class="btn-dashed"><svg class="icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>Add Video</button>

    </div>
</template>

<script>
export default {
  props: ['resumeVideos', 'assetPath'],
  data() {
    return {
      videos: [],
      year: new Date().getFullYear(),
    };
  },
  mounted() {
    this.setVideos();
  },
  methods: {
    setVideos: function() {
      if ( this.resumeVideos.length > 0 ) {
        this.videos = Object.assign( [], this.resumeVideos );
      } else {
        this.addVideo( false );
      }
    },
    addVideo: function( id ) {
      if ( id !== false ) {
        this.videos.push(
            Object.assign( {}, this.resumeVideos[id] )
        );
      } else {
        this.videos.push(
            {
              id: false,
              url: '',
            }
        );
      }
    },
    removeVideo: function( index ) {
      this.videos.splice( index, 1 );
    },
    isInUse: function( id ) {
      let isInUse = false;
      if ( id ) {
        $.each( this.videos, function( index, video ) {
          if ( video.id === id ) {
            isInUse = true;
          }
        } );
      }
      return isInUse;
    },
  },
  watch: {
    videos() {
      this.$emit( 'update', this.videos );
    },
  },
};
</script>
