<template>
    <div>
      <form class="mb-4 form-skin" @submit.prevent="handleCandidateSearch('search')">
        <div class="row">
          <div class="w-full col">
            <div class="flex flex-col lg:flex-row form-group">
              <div class="w-full mb-4 lg:w-9/12 lg:mb-0 form-group__input">
                <label class="sr-only" for="search">Search</label>
                <input id="search" type="text" name="search" placeholder="Search" v-model="search" required>
              </div>
              <button class="h-12 my-auto mb-4 lg:ml-4 lg:mb-0 btn-save" type="submit">Search</button>
              <button class="flex h-12 p-2 ml-auto border border-gray-300 rounded-xs" data-toggle-class="filterjobs" data-toggle-target=".find-candidates__filtercontrols, .find-candidates__paginate, .find-candidates__filtersearch"><div class="my-auto mr-2 text-xs">Filter</div><svg class="my-auto icon icon-filtercontrols text-blue"><use xlink:href="#icon-filtercontrols"></use></svg></button>
            </div>
          </div>
          <span class="ml-6 no-results-found"></span>
        </div>
      </form>

      <div :class="'find-candidates__header flex items-center pb-4 ' + prevView">
        <span class="text-xs text-gray-500">{{theCandidates.to}} of {{theCandidates.total}} results</span>
      </div>

      <div class="h-auto px-8 box">
        <div :class="'find-candidates__card-wrap ' + prevView">
          <div class="flex pb-3 font-medium text-gray-500 uppercase border-b border-gray-300 table-heading text-4xs leading-lenient tracking-spaced">

            <div class="w-4/12 pl-16">Name</div>
            <div class="w-3/12">Title</div>
            <div class="w-2/12">Match</div>
            <div class="w-2/12">Invite</div>
            <div class="">Save</div>
          </div>
          <div :class="'find-candidates__body ' + prevView" v-for="(candidate, candidateIndex) in theCandidates.data" :key="candidateIndex">
            <div class="w-2 candidate-selector">

            </div>
            <div v-if="candidate.profile_image.length"><a :href="'/u/'+candidate.id"><div class="profile__image" v-for="(profileImage, profileImageIndex) in candidate.profile_image" :key="profileImageIndex" :style="{ backgroundImage: 'url(\'/uploads/' + profileImage.path + '\')' }"></div></a></div>
            <div v-else><a :href="'/u/'+candidate.id"><div :href="'/u/'+candidate.id" class="profile__image" :style="{ backgroundImage: 'url(\'/uploads/' + imageUrl + '\')' }"></div></a></div>
            <div class="candidate-highlevel">
              <a :href="'/u/'+candidate.id" class="text-xs text-gray-700 candidate__name">{{ candidate.full_name }}</a>
            </div>
            <div class="text-xs text-gray-500 candidate-job__title">{{ candidate.job_title }}</div>
            <div class="job-match"><span :class="'job-match__'+candidate.personal_values">{{ candidate.personal_values }}</span></div>
            <div class="items-center candidate-card__footer">
              <div class="job-apply-now">
                <div class="flex items-center">
                  <svg class="mr-1 icon icon-Shape text-blue"><use xlink:href="#icon-Shape"></use></svg>
                  <a @click="handleInviteCandidate(candidate.id)" class="inline-block text-xs font-bold underline cursor-pointer text-blue">Invite to Apply</a>
                </div>
              </div>
              <div class="candidate-save" @click="handleSaveCandidate(candidate.id)" v-if="candidate.saved_candidate.length === 0">
                <svg :class="'icon icon-savestar mr-1 star-'+ candidate.id"><use xlink:href="#icon-savestar"></use></svg>
                <span :class="'text-xs cursor-pointer underline text-gray-500 font-bold save-'+ candidate.id">Save</span>
              </div>
              <div class="candidate-save text-green" v-else>
                <svg :class="'icon icon-savestar mr-1 star-'+ candidate.id"><use xlink:href="#icon-savestar"></use></svg>
                <span :class="'text-xs font-bold save-'+ candidate.id">Saved</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-6 row find-candidates__paginate">
        <div class="w-full col">
          <div class="flex justify-between">
            <div class="flex">
              <a @click="handleCandidateSearch('prev')" :class="theCandidates.prev_page_url !== null ? 'flex search-prev' : 'flex disabled text-gray-500 search-prev'">
                <svg class="w-6 h-6 icon icon-pagination page-prev"><use xlink:href="#icon-pagination"></use></svg>
                <span class="my-auto ml-4 text-sm">Previous</span>
              </a>
            </div>
            <div class="flex">
              <a :class="(candidateIndex+1 == theCandidates.current_page)?'page-item active':'page-item'"
                 v-for="(candidate, candidateIndex) in theCandidates.last_page"
                :key="candidateIndex"
                @click="handleSearchJump(candidateIndex)">
                <span :class="(candidateIndex+1 == theCandidates.current_page)?'underline text-gray-700':'text-gray-500'">{{candidateIndex + 1}}</span>
              </a>
            </div>
            <div class="">
              <a @click.prevent="handleCandidateSearch('next')" :class="theCandidates.next_page_url !== null ? 'flex search-next' : 'flex search-next disabled text-gray-500'">
                <span class="my-auto mr-3 text-sm">Next</span>
                <svg class="w-6 h-6 icon icon-pagination"><use xlink:href="#icon-pagination"></use></svg>
              </a>
            </div>
          </div>
        </div>
      </div>

      <form id="search-filters__form bg-transparent" @submit.prevent="handleFilter">
        <div class="bg-transparent find-candidates__filtercontrols">
          <div class="lg:w-2/3 md:w-1/3 filter-controls__bg bg-navy opacity-40"></div>
          <div class="relative w-full bg-white lg:w-1/3 md:w-2/3">
            <div class="pb-6 mt-6 mb-4 border-b border-gray-300">
              <div class="flex justify-between px-12">
                <span class="text-lg font-bold text-gray-700">Filter Candidates</span>
                <span class="filter-controls__close"
                      data-toggle-class="filterjobs"
                      data-toggle-target=".find-candidates__filtercontrols, .find-candidates__paginate, .find-candidates__filtersearch">
                      <svg class="text-gray-500 icon icon-exit-close"><use xlink:href="#icon-exit-close"></use></svg>
                </span>
              </div>
            </div>

            <search-filter-industry :industries="industries" :industry="industry" @update="updateIndustry"></search-filter-industry>
            <search-filter-job-type :job-types="employmentType" :job-filter-types="jobFilterTypes" @update="updateJobTypes"></search-filter-job-type>
            <search-filter-job-level :job-level="jobLevels" :job-filter-level="jobFilterLevels" @update="updateJobLevels"></search-filter-job-level>
            <search-filter-salary-range :job-filter-salary-range="jobFilterSalaryRange" @update="updateSalaryRange"></search-filter-salary-range>
            <search-filter-location @update="updateZipCode" @zipper="updateUserZip"></search-filter-location>
            <search-filter-willing-to-relocate :job-filter-relocate="jobFilterRelocate" @update="updateRelocate"></search-filter-willing-to-relocate>

          </div>
        </div>
        <div class="px-8 find-candidates__filtersearch lg:w-1/3 md:w-2/3">
          <a class="mr-8 text-gray-700 underline cursor-pointer hover:text-green" @click="resetForm()">Reset</a>
          <button type="submit" name="button" class="my-2 btn-save">Show Results</button>
        </div>
      </form>

      <div class="absolute inset-0 flex justify-center hidden invite-to-apply__modal">
        <form class="flex flex-col justify-center w-full px-24 my-auto invite-to-apply__inner_modal lg:w-1/2" @submit.prevent="handleInvitingCandidate()">
        <div class="text-right" @click="closeInviteModal()"><svg class="icon icon-exit-close"><use xlink:href="#icon-exit-close"></use></svg></div>
          <div class="mb-8 text-center">
            <div class="mb-3 text-3xl font-bold leading-snug">Invite to Apply</div>
            <div class="text-base font-normal text-gray-500">Select a job listing for the invitation.</div>
          </div>
          <div class="gap-4 mb-12 job-listings__checkboxes">
            <div class="w-full" v-for="(currentListing, currentListingIndex) in currentJobListings" :key="currentListingIndex">
              <input v-model="jobListingToInvite" :id="'invite-to-apply-'+currentListingIndex" :name="'invite-to-apply-'+currentListingIndex" type="checkbox" class="mr-2" :value="currentListing.id"/>
              <label class="text-gray-500" :for="'invite-to-apply-'+currentListingIndex">{{currentListing.listing_title}}</label>
            </div>
          </div>
          <div class="text-center">
            <button class="btn btn-save" type="submit">Send Invite</button>
          </div>
        </form>
      </div>

    </div>
</template>

<script>
import * as moment from 'moment-timezone';
export default {
  props: ['candidates', 'industry', 'industries', 'jobLevels', 'jobTypes', 'employmentType', 'jobFilterTypes', 'jobFilterLevels', 'jobFilterSalaryRange', 'jobFilterRelocate', 'viewMode', 'currentJobListings'],
  data() {
    return {
      search: '',
      imageUrl: '../img/default_profile.png',
      isRunning: false,
      prevView: this.viewMode + 'view',
      theCandidates: this.candidates,
      currentPage: this.candidates.current_page,
      lastPage: this.candidates.last_page,
      hasSearched: false,
      userIndustry: [],
      userJobTypes: [],
      userJobLevels: [],
      userSalaryRange: [],
      userRelocate: [],
      userZipCode: [],
      startingZip: [],
      jobListingToInvite: [],
      applied: this.candidates.applied,
      windowSize: $( window ).width(),
    };
  },
  mounted() {
    const uri = window.location.search.substring( 1 );
    const params = new URLSearchParams( uri );
    const industry = params.get( 'industries' );
    const employmentType = params.get( 'types' );
    const jobLevel = params.get( 'jobLevels' );
    const salaryRange = params.get( 'salaryRange' );
    const relocate = params.get( 'relocate' );
    if ( industry || employmentType || jobLevel || salaryRange || relocate ) {
      const url = '/api/filter-candidates?industries=' + industry + '&types=' + employmentType + '&jobLevels=' + jobLevel + '&salaryRange=' + salaryRange + '&relocate=' + relocate + '&currentPage=0';
      this.handleApiCall( url, false );
    }
    this.setView();
  },
  watch: {
    windowSize( newSize, oldSize ) {
      if ( newSize <= 828 ) {
        this.setView();
      }
    },
  },
  methods: {
    resetForm: function() {
      const allChecked = document.querySelectorAll( 'input[type=checkbox]:checked' );
      for ( const checkbox of allChecked ) {
        checkbox.checked = false;
      }
    },
    setView: function() {
      const _this = this;
      if ( _this.windowSize <= 828 ) {
        $( '.view-selector' ).hide();
        $( '.find-candidates__card' ).attr( 'data-toggle-is-active', true );
        $( '.find-candidates__list' ).removeAttr( 'data-toggle-is-active' );
        $( '.find-candidates__body' ).removeClass( 'listview' );
      }
    },
    formatDate: function( date ) {
      return moment( date ).format( 'MM/DD/YYYY' );
    },
    handleInvitingCandidate: function( candidateId ) {
      const _this = this;
      const candidate = $( '.invite-to-apply__inner_modal' ).data( 'candidate' );
      if ( _this.jobListingToInvite.length > 0 ) {
        axios.post( '/api/invite-candidate?candidateId='+candidate+'&jobListingId='+_this.jobListingToInvite )
            .then( ( response ) => {
              $( '.invite-to-apply__modal' ).toggleClass( 'hidden' );
              this.applied = true;
            } ).catch( ( error ) => {
              console.log( error );
            } );
      }
    },
    closeInviteModal: function( ) {
      $( '.invite-to-apply__modal' ).toggleClass( 'hidden' );
    },
    handleInviteCandidate: function( candidateId ) {
      window.scrollTo( {top: 0, behavior: 'smooth'} );
      $( '.invite-to-apply__modal' ).toggleClass( 'hidden' );
      $( '.invite-to-apply__inner_modal' ).attr( 'data-candidate', candidateId );
    },
    handleApiCall: function( url, filtered ) {
      const _this = this;
      if ( _this.windowSize <= 828 ) {
        $( '.listview' ).removeClass( 'listview' );
      }

      axios.get( url )
          .then( ( response ) => {
            _this.hasSearched = true;
            _this.theCandidates = response.data;
            _this.currentPage = response.data.current_page;
            _this.lastPage = response.data.last_page;
            $( '.no-results-found' ).empty();
            $( '.box' ).css( {'opacity': 1, 'pointer-events': 'all'} );
            $( '.find-candidates__header' ).css( {'opacity': 1, 'pointer-events': 'all'} );
            $( '.find-candidates__paginate' ).css( 'opacity', 1 );
            if ( filtered ) {
              $( '.filter-controls__close' ).click();
            }
            if ( _this.windowSize <= 828 ) {
              _this.prevView = 'cardview';
            }
          } ).catch( ( error ) => {
            console.log( error );
            _this.theCandidates = this.candidates;
            _this.hasSearched = false;
            $( '.no-results-found' ).html( 'No results found.' );
            $( '.box' ).css( {'opacity': 0, 'pointer-events': 'none'} );
            $( '.find-candidates__paginate' ).css( 'opacity', 0 );
            $( '.find-candidates__header' ).css( {'opacity': 0, 'pointer-events': 'none'} );
          } );
    },
    handleCandidateSearch( direction ) {
      const _this = this;
      const uri = window.location.search.substring( 1 );
      const params = new URLSearchParams( uri );
      const industry = params.get( 'industries' );
      const employmentType = params.get( 'types' );
      const jobLevel = params.get( 'jobLevels' );
      const salaryRange = params.get( 'salaryRange' );
      const relocate = params.get( 'relocate' );
      if ( industry || employmentType || jobLevel || salaryRange || relocate ) {
        window.location.search = '';
      }
      if ( document.querySelector( '.find-candidates__header' ).classList.contains( 'listview' ) ) {
        _this.prevView = 'listview';
      } else {
        _this.prevView = 'cardview';
      }
      let url = '';
      if ( _this.currentPage < _this.lastPage && direction === 'next' ) {
        url = '/api/search-candidates?q=' + _this.search + '&currentPage=' + _this.currentPage;
        _this.handleApiCall( url, false );
      }
      if ( direction === 'prev' && !document.querySelector( '.search-prev' ).classList.contains( 'disabled' ) ) {
        url = '/api/search-candidates?q=' + _this.search + '&currentPage=' + ( _this.currentPage ) + '&previousPage=1';
        _this.handleApiCall( url, false );
      }
      if ( direction === 'search' ) {
        url = '/api/search-candidates?q=' + _this.search + '&currentPage=0';
        _this.handleApiCall( url, false );
      }
    },
    handleFilter() {
      const _this = this;
      const uri = window.location.search.substring( 1 );
      const params = new URLSearchParams( uri );
      const industry = params.get( 'industries' );
      const employmentType = params.get( 'types' );
      const jobLevel = params.get( 'jobLevels' );
      const salaryRange = params.get( 'salaryRange' );
      const relocate = params.get( 'relocate' );
      if ( industry || employmentType || jobLevel || salaryRange || relocate ) {
        window.location.search = '';
      }
      if ( document.querySelector( '.find-candidates__header' ).classList.contains( 'listview' ) ) {
        _this.prevView = 'listview';
      } else {
        _this.prevView = 'cardview';
      }
      const url = '/api/filter-candidates?industries=' + _this.userIndustry + '&types=' + _this.userJobTypes + '&jobLevels=' + _this.userJobLevels + '&salaryRange=' + _this.userSalaryRange + '&startingZip=' + _this.startingZip + '&zipCode=' + _this.userZipCode + '&relocate=' + _this.userRelocate + '&currentPage=0';
      _this.handleApiCall( url, true );
    },
    handleSearchJump( page ) {
      const _this = this;
      const uri = window.location.search.substring( 1 );
      const params = new URLSearchParams( uri );
      const industry = params.get( 'industries' );
      const employmentType = params.get( 'types' );
      const jobLevel = params.get( 'jobLevels' );
      const salaryRange = params.get( 'salaryRange' );
      const relocate = params.get( 'relocate' );
      if ( industry || employmentType || jobLevel || salaryRange || relocate ) {
        window.location.search = '';
      }
      if ( document.querySelector( '.find-candidates__header' ).classList.contains( 'listview' ) ) {
        _this.prevView = 'listview';
      } else {
        _this.prevView = 'cardview';
      }
      if ( !_this.hasSearched ) {
        _this.currentPage = 1;
      }
      if ( _this.currentPage >= 1 && page <= _this.lastPage ) {
        const url = '/api/search-candidates?q=' + _this.search + '&currentPage=' + ( page );
        _this.handleApiCall( url, false );
      }
    },
    handleSaveCandidate( candidateId ) {
      axios.post( '/api/save-candidates/' + candidateId )
          .then( ( response ) => {
            $( '.save-' + candidateId ).html( 'Saved' ).css( 'color', '#6FCB6F' );
            $( '.star-' + candidateId ).css( 'color', '#6FCB6F' );
          } ).catch( ( error ) => {
            console.log( error );
          } );
    },
    updateCompany( company ) {
      this.userCompany = company;
    },
    updateIndustry( industry ) {
      this.userIndustry = industry;
    },
    updateJobTypes( jobTypes ) {
      this.userJobTypes = jobTypes;
    },
    updateJobLevels( jobLevels ) {
      this.userJobLevels = jobLevels;
    },
    updateSalaryRange( salaryRange ) {
      this.userSalaryRange = salaryRange;
    },
    updateRelocate( relocate ) {
      this.userRelocate = relocate;
    },
    updateZipCode( zipCode ) {
      this.userZipCode = zipCode;
    },
    updateUserZip( userZip ) {
      this.startingZip = userZip;
    },
  },
};
</script>
