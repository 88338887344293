<template>
    <div>
      <p class="mb-4 hdg-6">Benefits</p>
      <div v-if="benefits.length > 0" class="relative mb-8 box bg-bgblue">
        <div v-for="(benefit, benefitIndex) in benefits" :key="benefitIndex">
          <div class="flex flex-wrap -mx-2">
            <div class="flex-initial w-full px-2 mb-5 xl:mb-0">
              <div class="flex flex-wrap -mx-2">
                <div class="relative flex-initial w-full px-2">
                  <button class="absolute top-0 right-0 p-4 hover:text-error" @click="removeBenefit( benefitIndex )" type="button" :name="'remove_benefit_' + benefitIndex"><svg class="block text-lg icon icon-minus"><use xlink:href="#icon-minus"></use></svg></button>
                  <div class="mb-2 form-group">
                    <label class="sr-only" :for="'benefit_name_' + benefitIndex">Benefit</label>
                    <div class="w-11/12 form-group__input form-group__input--icon">
                        <input type="text" v-model="benefit.benefit_name" :id="'benefit_name_' + benefitIndex" :name="'benefit_name_' + benefitIndex" class="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button @click="addJobListingBenefit( false )" type="button" name="add_video" class="btn-dashed"><svg class="icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>Add Benefit</button>
    </div>
</template>

<script>
export default {
  props: ['jobListingBenefits'],
  data() {
    return {
      benefits: [],
      year: new Date().getFullYear(),
    };
  },
  mounted() {
    this.setJobListingBenefits();
  },
  methods: {
    setJobListingBenefits: function() {
      if ( this.jobListingBenefits.length > 0 ) {
        this.benefits = Object.assign( [], this.jobListingBenefits );
      } else {
        this.addJobListingBenefit( false );
      }
    },
    addJobListingBenefit: function( id ) {
      if ( id !== false ) {
        this.benefits.push(
            Object.assign( {}, this.jobListingBenefitsOptions[id] )
        );
      } else {
        this.benefits.push(
            {
              id: false,
            }
        );
      }
    },
    removeBenefit: function( index ) {
      this.benefits.splice( index, 1 );
    },
  },
  watch: {
    benefits() {
      this.$emit( 'update', this.benefits );
    },
  },
};
</script>
