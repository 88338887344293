<template>
    <div>
        <div class="mb-5">
            <div class="items-center row">
                <div class="w-full mb-3 col lg:w-auto lg:mb-0">
                    <h1 class="hdg-4">Analytics</h1>
                </div>
                <div v-if="activeResumeData.length" class="w-full ml-auto col lg:w-2/3 xl:w-1/2">
                    <div class="form-skin">
                        <div class="mb-0 form-group">
                            <!-- <label for="selected_active_resume">Select Active Resume</label> -->

                            <div class="form-group__input form-group__input--icon">
                                <select v-model="selectedActiveResume" id="selected_active_resume" class="" name="selected_active_resume">
                                    <option v-for="(activeResume, activeResumeIndex) in activeResumeData" :key="activeResumeIndex" :value="activeResume.id">{{ activeResume.name }}</option>
                                </select>
                                <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="activeResumeData.length">
            <div v-for="(activeResume, activeResumeIndex) in activeResumeData" :key="activeResumeIndex">
                <div v-if="selectedActiveResume === activeResume.id" class="items-stretch row">
                    <div class="w-full mb-12 col lg:w-1/3">
                        <div class="box">
                            <div class="mb-2 font-bold paragraph-large">Views</div>
                            <div class="flex mb-6">
                                <div class="flex-initial w-1/2 leading-none">
                                    <span class="inline-block w-3 h-3 align-middle bg-pink rounded-xs"></span>
                                    <span class="text-3xs">New Views</span>
                                </div>
                                <div class="flex-initial w-1/2 leading-none">
                                    <span class="inline-block w-3 h-3 align-middle bg-blue rounded-xs"></span>
                                    <span class="text-3xs">Repeat Views</span>
                                </div>
                            </div>
                            <div class="flex justify-around lg:justify-center">
                                <div class="flex-initial px-2">
                                    <div class="bar bar--pink" :data-value="activeResume.new_views" :data-max="activeResume.repeat_views + activeResume.new_views">
                                        <span></span>
                                    </div>
                                    <span class="block mt-3 hdg-6">{{ activeResume.new_views }}</span>
                                </div>
                                <div class="flex-initial px-2">
                                    <div class="bar bar--blue" :data-value="activeResume.repeat_views" :data-max="activeResume.repeat_views + activeResume.new_views">
                                        <span></span>
                                    </div>
                                    <span class="block mt-3 hdg-6">{{ activeResume.repeat_views }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="w-full mb-12 col lg:w-2/3">
                        <div class="box">
                            <div class="mb-2 font-bold paragraph-large"><span v-if="options.role === 'applicant'">Sent Resume Views</span><span v-else>View Locations</span></div>
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Location</th>
                                        <th># of Views</th>
                                        <th v-if="options.role === 'applicant'">Profile</th>
                                    </tr>
                                </thead>
                                <tbody v-if="Object.keys( activeResume.views ).length > 0">
                                    <tr v-for="(view, viewIndex) in activeResume.views" :key="viewIndex">
                                        <td>
                                            <a v-if="options.role === 'applicant'" class="underline hover:text-blue" :href="'mailto:'+view.recipient">{{ view.recipient }}</a>

                                            <br v-if="options.role === 'applicant'">
                                            <span class="text-2xs">{{ view.location }}</span>
                                        </td>
                                        <td>{{ view.count }}  View{{ ( view.count > 1 ) ? 's' : '' }}</td>
                                        <!-- TODO fix id for profile link -->
                                        <td v-if="options.role === 'applicant'"><a class="underline hover:text-blue" :href="'/u/'+view.user.id" >View Profile</a></td>

                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr>
                                        <td colspan="2" class="text-center">No views yet</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="w-full col">
                        <div class="box">
                            <div class="mb-2 font-bold paragraph-large">Overview</div>
                            <p class="mb-1 text-3xs">Total Views This Month</p>
                            <p class="mb-5 hdg-6">{{ activeResume.new_views + activeResume.repeat_views }}</p>
                            <chart :id="'overview-chart-' + activeResumeIndex" type="line" :datasets="activeResume.datasets" :labels="activeResume.labels" :options="options"></chart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="content">
                <p>No active {{options.role === 'employer' ? 'listings' : 'resumes'}}. <a :href="options.role === 'employer' ? '/job-listings/create' : '/resumes'" class="underline text-blue hover:no-underline">{{options.role === 'employer' ? 'Create a new listing' : 'Share your resume'}}.</a></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: ['activeResumeData', 'options'],
  data() {
    return {
      selectedActiveResume: 0,
    };
  },
  mounted() {
  },
  watch: {
    selectedActiveResume() {
      this.$nextTick( function() {
        $( '.bar' ).each( function( index, element ) {
          const value = parseInt( $( element ).data( 'value' ) );
          const max = parseInt( $( element ).data( 'max' ) );
          if ( max > 0 ) {
            let height = value / max;
            height = height * 100;
            console.log( height );
            $( this ).find( 'span' ).css( 'height', height + '%' );
          }
        } );
      } );
    },
  },
};
</script>
