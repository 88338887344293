<template>
    <div class="mfp-hide popup" id="first-time-profile-popup">
        <div class="container">
            <div class="row">
                <div class="col w-full md:w-3/4 lg:w-2/3 mx-auto">
                    <div class="box box--lg h-auto public-resume__cover-letter relative">
                        <button class="js-close-popup close-popup-icon" type="button">
                            <svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg>
                        </button>

                        <div class="max-w-lg mx-auto">
                            <div class="my-6">
                                <div class="hdg-4 mb-5 text-center">Welcome!</div>
                                <p class="paragraph-large text-center mb-8" v-if="this.userRole === 'applicant'">Fill out information that you want to easily populate to any resume. Once in the resume you will be able to add attachments, a gallery of work, skills etc….</p>
                                <p class="paragraph-large text-center mb-8" v-else>Fill out information that you want to easily populate your profile.</p>
                                <p class="text-center">
                                    <button class="btn-green-sm js-close-popup" type="button" name="button">Continue</button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: ['data', 'userHasSeen'],
  data() {
    return {
      userRole: this.data.user.user_role,
    };
  },
  mounted() {
    if ( ! this.userHasSeen ) {
      $.magnificPopup.open( {
        items: {
          src: '#first-time-profile-popup',
        },
        type: 'inline',
        fixedContentPos: true,
        fixedBgPos: true,
        overflowY: 'auto',
        closeBtnInside: true,
        showCloseBtn: false,
        preloader: false,
        midClick: true,
        removalDelay: 300,
      }, 0 );
    }
  },
};
</script>
