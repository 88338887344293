<template>
    <div>
      <div class="find-jobs__filter_checkbox_group_label">Job Level</div>
      <div class="find-jobs__filter_checkbox_group">
        <div class="" v-for="(jobLevel, jobLevelIndex) in jobLevel" :key="jobLevelIndex">
          <input v-model="jobFilterLevel" :id="'jobFilterLevel_'+jobLevelIndex" :name="'jobFilterLevel_'+jobLevelIndex" :value="jobLevel.id" type="checkbox"/>
          <label :for="'jobFilterLevel_'+jobLevelIndex">{{jobLevel.name}}</label>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  props: ['jobLevel'],
  data() {
    return {
      jobFilterLevel: [],
      year: new Date().getFullYear(),
    };
  },
  methods: {

  },
  watch: {
    jobFilterLevel( ) {
      this.$emit( 'update', this.jobFilterLevel );
    },
  },
};
</script>
