<template>
    <div>
        <div class="row mb-4">
            <div class="col flex lg:w-3/12 w-3/4 justify-between">
                <div class=""><a href="/resumes" class="account__button">View All</a></div>
                <div class=""><a href="/active-resumes" class="account__button border-gray-500">Sent Resumes</a></div>
            </div>
        </div>
        <div class="row mb-4 hidden md:flex" v-if="activeResumes.length > 0">
            <div class="col active-resumes__table-col-resume">
                <span class="active-resumes__table-heading">Resume</span>
            </div>
            <div class="col active-resumes__table-col-recipient">
                <span class="active-resumes__table-heading">Sent To</span>
            </div>
            <div class="col active-resumes__table-col-date">
                <span class="active-resumes__table-heading">Date</span>
            </div>
            <div class="col active-resumes__table-col-status">
                <span class="active-resumes__table-heading">Status</span>
            </div>
        </div>

        <div v-for="(activeResume, activeResumeIndex) in activeResumes" :key="activeResumeIndex"  class="block bg-white rounded-sm mb-3 py-4 hover:shadow-xxl">
            <div class="row hidden md:flex">
                <div class="col active-resumes__table-col-resume">
                    <a :href="'/resumes/' + activeResume.resume.id + '/edit'" class="inline-block pl-5 text-xs text-gray-500 font-medium hover:text-blue w-full h-1/2">{{ activeResume.resume.title }}</a>
                    <br>
                    <a :href="'/applicant/' + activeResume.share_code" target="_blank" class="inline-block pl-5 text-3xs text-blue hover:underline">View public resume</a>
                </div>
                <div class="col active-resumes__table-col-recipient">
                    <span class="inline-block text-xs text-gray-500 font-medium">{{ activeResume.recipient }}</span>
                </div>
                <div class="col active-resumes__table-col-date">
                    <span v-if="activeResume.created_at" class="text-xs font-medium text-gray-500">Sent</span>
                    <span v-if="activeResume.created_at" class="text-xs text-gray-500 underline">{{ formatDate( activeResume.created_at ) }}</span>
                </div>
                <div class="col active-resumes__table-col-status">
                    <span v-if="activeResume.views.length > 0" class="text-xs font-medium text-gray-500">Most Recent View</span>
                    <span v-if="activeResume.views.length > 0" class="text-xs text-green">{{ formatDate( activeResume.views[0].created_at ) }}</span>
                    <span v-if="!activeResume.views.length > 0" class="text-xs text-error">Not Yet Viewed</span>
                </div>
            </div>

            <div class="row md:hidden">
                <div class="col w-full">
                    <div class="pl-5 mb-2">
                        <span class="resumes__table-heading">Resume</span><br>
                        <a :href="'/resumes/' + activeResume.resume.id + '/edit'" class="inline-block text-xs text-gray-500 font-medium hover:text-blue">{{ activeResume.resume.title }}</a>
                        <br>
                        <a :href="'/applicant/' + activeResume.share_code" target="_blank" class="inline-block text-3xs text-blue hover:underline">View public resume</a>
                    </div>
                    <div class="pl-5 mb-2">
                        <span class="resumes__table-heading">Sent To</span><br>
                        <span class="inline-block text-xs text-gray-500 font-medium">{{ activeResume.recipient }}</span>
                    </div>
                    <div class="pl-5 mb-2">
                        <span class="resumes__table-heading">Date</span><br>
                        <span v-if="activeResume.created_at" class="text-xs font-medium text-gray-500">Sent</span>
                        <span v-if="activeResume.created_at" class="text-xs text-gray-500 underline">{{ formatDate( activeResume.created_at ) }}</span>
                    </div>
                    <div class="pl-5">
                        <span class="resumes__table-heading">Status</span><br>
                        <span v-if="activeResume.views.length > 0" class="text-xs font-medium text-gray-500">Most Recent View</span>
                        <span v-if="activeResume.views.length > 0" class="text-xs text-green">{{ formatDate( activeResume.views[0].created_at ) }}</span>
                        <span v-if="!activeResume.views.length > 0" class="text-xs text-error">Not Yet Viewed</span>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import * as moment from 'moment-timezone';
export default {
  props: ['activeResumes'],
  mounted() {
  },
  methods: {
    formatDate: function( date ) {
      return moment( date ).format( 'MM/DD/YYYY' );
    },
  },
};
</script>
