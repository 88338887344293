<template>
    <div>
        <div class="form-photo">
            <div class="profile__image" :style="{ backgroundImage: 'url(\'' + imageUrl + '\')' }"></div>

            <button @click="clickFileInput" class="btn-dashed mt-5" type="button" name="add_photo">
                <svg class="icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>{{ buttonText }}
            </button>

            <div class="form-group__input hidden">
                <input class="hidden" id="photo_input" name="photo_input" type="file" accept="image/jpeg, image/gif, image/png" @change="onFileChange" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: ['userPhoto', 'assetPath'],
  data() {
    return {
      imageUrl: '../img/default_profile.png',
      image: '',
      buttonText: 'Add Photo',
    };
  },
  mounted() {
    if ( this.userPhoto ) {
      this.imageUrl = this.assetPath + 'uploads/' + this.userPhoto.path;
      this.buttonText = 'Change Photo';
    }
  },
  methods: {
    onFileChange( e ) {
      const files = e.target.files || e.dataTransfer.files;
      if ( !files.length ) {
        return;
      }
      this.imageUrl = URL.createObjectURL( files[0] );
      this.buttonText = 'Change Photo';
      this.createImage( files[0] );
    },
    createImage( file ) {
      const reader = new FileReader();
      const _this = this;
      reader.onload = ( e ) => {
        _this.image = e.target.result;
      };
      reader.readAsDataURL( file );
    },
    clickFileInput: function() {
      $( '#photo_input' ).click();
    },
  },
  watch: {
    image() {
      this.$emit( 'update', this.image );
    },
  },
};
</script>
