<template>
    <div>

        <div v-if="error" class="alert alert-error">
            {{ error }}
        </div>

        <div v-if="success" class="alert alert-success">
            {{ success }}
        </div>

        <div class="form-skin">
            <div class="form-group">
                <label for="new_password">New Password</label>

                <div class="form-group__input">
                    <input v-model="newPassword" id="new_password" type="password" name="new_password" required>
                </div>
            </div>

            <div class="form-group">
                <label for="new_password_confirm">Confirm New Password</label>

                <div class="form-group__input">
                    <input v-model="newPasswordConfirm" id="new_password_confirm" type="password" name="new_password_confirm" required>
                </div>
            </div>

            <div class="row mt-12">
                <div class="col w-full sm:w-1/2 mb-5 sm:mb-0 ml-auto">
                    <button @click="changePassword" class="btn-green-sm w-full" type="button">
                        Change Password
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      newPassword: '',
      newPasswordConfirm: '',
      error: false,
      success: false,
      isRunning: false,
    };
  },
  methods: {
    changePassword: function() {
      const _this = this;
      _this.error = false;
      _this.success = false;
      if ( ! _this.newPassword || _this.newPassword === '' ) {
        _this.error = 'Password cannot be empty.';
      } else if ( _this.newPassword !== _this.newPasswordConfirm ) {
        _this.error = 'Passwords do not match.';
      } else {
        if ( _this.isRunning === false ) {
          _this.isRunning = true;
          // Submit form
          axios.post( '/api/account/change-password', {
            new_password: _this.newPassword,
          } )
              .then( ( response ) => {
                _this.success = 'Password changed successfully!';
                _this.newPassword = '';
                _this.newPasswordConfirm = '';
                _this.isRunning = false;
              } ).catch( ( error ) => {
                console.log( error );
                _this.error = 'There was a problem changing your password.';
                _this.isRunning = false;
              } );
        }
      }
    },
  },
};
</script>
