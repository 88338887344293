<template>
    <div>
        <div class="row mb-4">
            <div class="col cover-letters__table-col-title">
                <span class="cover-letters__table-heading">Title</span>
            </div>
            <div class="col cover-letters__table-col-date">
                <span class="cover-letters__table-heading">Date</span>
            </div>
            <div class="col cover-letters__table-col-delete">
                <span class="cover-letters__table-heading">Delete</span>
            </div>
        </div>

        <div v-for="(coverLetter, coverLetterIndex) in coverLetters" :key="coverLetterIndex"  class="block bg-white rounded-sm mb-3 py-4 hover:shadow-xxl">
            <div class="row">
                <div class="col cover-letters__table-col-title">
                    <a :href="'/cover-letters/' + coverLetter.id + '/edit'" class="inline-block pl-5 text-xs text-gray-500 font-medium hover:text-blue w-full h-full">{{ coverLetter.title }}</a>
                </div>
                <div class="col cover-letters__table-col-date">
                    <span v-if="coverLetter.updated_at" class="text-xs font-medium text-gray-500">Updated</span>
                    <span v-else-if="coverLetter.created_at" class="text-xs font-medium text-gray-500">Created</span>
                    <span v-if="coverLetter.updated_at" class="text-xs text-gray-500 underline">{{ formatDate( coverLetter.updated_at ) }}</span>
                    <span v-else-if="coverLetter.created_at" class="text-xs text-gray-500 underline">{{ formatDate( coverLetter.created_at ) }}</span>
                </div>
                <div class="col cover-letters__table-col-delete">
                    <button @click="handleDelete( coverLetter.id )" type="button" class="pr-5 hover:text-error"><svg class="icon icon-trash"><use xlink:href="#icon-trash"></use></svg></button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as moment from 'moment-timezone';
export default {
  props: ['coverLetters'],
  data() {
    return {
      isRunning: false,
    };
  },
  mounted() {
  },
  methods: {
    formatDate: function( date ) {
      return moment( date ).format( 'MM/DD/YYYY' );
    },
    handleDelete( id ) {
      const _this = this;

      if ( this.isRunning === false ) {
        // Submit form
        axios.delete( '/cover-letters/' + id )
            .then( ( response ) => {
              window.location.href = '/cover-letters';
            } ).catch( ( error ) => {
              console.log( error );
              _this.isRunning = false;
            } );
      }
    },
  },
};
</script>
