<template>
    <div>
        <p class="hdg-6 mb-2">Video</p>

        <p class="paragraph-small mb-4 text-gray-500">YouTube and Vimeo links are accepted. <button class="js-init-popup text-blue hover:underline" data-modal="#video-popup">Need help getting your video URL?</button></p>

        <div class="mfp-hide popup" id="video-popup">
            <div class="container">
                <div class="row">
                    <div class="col w-full md:w-3/4 lg:w-2/3 mx-auto">
                        <div class="box box--lg h-auto relative">
                            <button class="js-close-popup close-popup-icon" type="button">
                                <svg class="icon icon-cross"><use xlink:href="#icon-cross"></use></svg>
                            </button>

                            <div class="max-w-lg mx-auto">
                                <div class="my-6">
                                    <div class="hdg-5 mb-5">YouTube:</div>
                                    <p class="paragraph-default mb-5">Go to the video's page and click "Share" to open a popup. Click "Embed", then copy the URL in the "src" of the Embed Video code.</p>
                                    <img class="mb-8 max-w-full" :src="assetPath + 'img/youtube.png'" alt="YouTube Share Instructions">
                                    <div class="hdg-5 mb-5">Vimeo:</div>
                                    <p class="paragraph-default mb-5">Go to the video's page and click "Share" to open a popup. In the "Embed" section, copy the URL in the "src" of the code.</p>
                                    <img class="mb-8 max-w-full" :src="assetPath + 'img/vimeo.png'" alt="Vimeo Share Instructions">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="videos.length > 0" class="mb-8">
            <div v-for="(video, videoIndex) in videos" :key="videoIndex" class="relative box bg-bgblue mb-8">
                <button class="absolute right-0 top-0 p-4 hover:text-error" @click="removeVideo( videoIndex )" type="button" :name="'remove_video_' + videoIndex"><svg class="block text-lg icon icon-minus"><use xlink:href="#icon-minus"></use></svg></button>

                <div class="form-group">
                    <label :for="'url_' + videoIndex">Video URL</label>

                    <div class="form-group__input">
                        <input :id="'url_' + videoIndex" type="text" class="" :name="'url_' + videoIndex" v-model="video.url">
                    </div>
                </div>

            </div>
        </div>

        <div v-if="jobListingVideos.length > 0" class="relative">
            <button type="button" name="add_video" class="popover__button btn-dashed" data-toggle-class="is-open" data-toggle-outside data-toggle-target="#add-video-popup"><svg class="pointer-events-none icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>Add Video</button>

            <div class="popover__popup hidden absolute left-0 bottom-100 w-full" id="add-video-popup" role="navigation">
                <div class="popover__popup-box">
                    <div>
                        <button @click="addVideo( false )" data-toggle-trigger-off class="flex w-full items-center text-left text-gray-500 text-sm font-medium hover:text-blue py-3" type="button">Add New Video<svg class="ml-auto pointer-events-none block text-lg icon mr-2 icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg></button>
                    </div>

                    <div v-for="(video, videoIndex) in jobListingVideos" :key="videoIndex">
                        <button v-if="!isInUse( video.id )" :data-id="videoIndex" @click="addVideo( videoIndex )" data-toggle-trigger-off class="flex w-full items-center text-left text-gray-500 text-sm font-medium hover:text-blue py-3 border-t" type="button">{{ video.url }}</button>
                    </div>
                </div>
            </div>
        </div>

        <button v-else @click="addVideo( false )" type="button" name="add_video" class="btn-dashed"><svg class="icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>Add Video</button>

    </div>
</template>

<script>
export default {
  props: ['jobListingVideos', 'assetPath'],
  data() {
    return {
      videos: [],
      year: new Date().getFullYear(),
    };
  },
  mounted() {
    this.setVideos();
  },
  methods: {
    setVideos: function() {
      if ( this.jobListingVideos.length > 0 ) {
        this.videos = Object.assign( [], this.jobListingVideos );
      } else {
        this.addVideo( false );
      }
    },
    addVideo: function( id ) {
      if ( id !== false ) {
        this.videos.push(
            Object.assign( {}, this.jobListingVideos[id] )
        );
      } else {
        this.videos.push(
            {
              id: false,
              url: '',
            }
        );
      }
    },
    removeVideo: function( index ) {
      this.videos.splice( index, 1 );
    },
    isInUse: function( id ) {
      let isInUse = false;
      if ( id ) {
        $.each( this.videos, function( index, video ) {
          if ( video.id === id ) {
            isInUse = true;
          }
        } );
      }
      return isInUse;
    },
  },
  watch: {
    videos() {
      this.$emit( 'update', this.videos );
    },
  },
};
</script>
