<template>
    <form class="" @submit.prevent="handleSubmit">
        <div class="saved-banner absolute right-0 w-20 h-8 bg-green text-white flex justify-center my-auto text-xl hidden">Saved!</div>
        <div class="w-full mb-6">
            <div class="flex justify-end">
                <div class="flex-initial px-2">
                    <a class="btn" href="/dashboard">Cancel</a>
                </div>
                <div class="flex-initial px-2">
                    <button class="btn-green" type="submit" name="button">Save Changes</button>
                </div>
            </div>
        </div>
        <div class="box box--lg h-auto">
            <div class="form-skin" >

                <div class="row">
                    <div class="col w-full lg:w-1/3 xl:w-3/10">

                        <div class="mb-12">
                            <photo :user-photo="formData.photo" :asset-path="assetPath" @update="updatePhoto"></photo>
                        </div>


                        <div class="mb-12" v-if="formData.user.user_role === 'applicant'">
                            <div class="form-check mb-5 mt-8 hidden">
                                <input type="checkbox" name="hide_profile" id="hide_profile" v-model="formData.user_open_to_work" @change="toggleOpenToWork">
                                <label for="hide_profile">Hide Profile</label>
                                <p class="text-4xs mb-4 hide-profile">Checking this box temporarily hides your profile from prospective employers on this site.</p>
                            </div>
                            <p class="hdg-6 mb-4">Name</p>

                            <div class="form-group form-group--small">
                                <label for="first_name">First Name</label>

                                <div class="form-group__input">
                                    <input v-model="formData.user.first_name" id="first_name" type="text" name="first_name" required>
                                </div>
                            </div>

                            <div class="form-group form-group--small">
                                <label for="last_name">Last Name</label>

                                <div class="form-group__input">
                                    <input v-model="formData.user.last_name" id="last_name" type="text" name="last_name" required>
                                </div>
                            </div>

                            <!-- I removed the required attribute here -->
                            <div class="form-group form-group--small">
                                <label for="job_title">Job Title</label>

                                <div class="form-group__input">
                                    <input v-model="formData.user.job_title" id="job_title" type="text" name="job_title">
                                </div>
                            </div>
                        </div>

                        <div class="mb-12" v-if="formData.user.user_role === 'employer'">
                            <p class="hdg-6 mb-4">Name</p>

                            <div class="form-group form-group--small">
                                <label for="first_name">Company Name</label>

                                <div class="form-group__input">
                                    <input v-model="formData.user.first_name" id="first_name" type="text" name="first_name" required>
                                </div>
                            </div>

                        </div>

                        <div class="mb-12">
                            <p class="hdg-6 mb-4">Contact</p>

                            <div class="form-group form-group--small">
                                <label for="phone">Phone</label>

                                <div class="form-group__input">
                                    <input v-model="formData.user.phone" id="phone" type="text" name="phone">
                                </div>
                            </div>

                            <div class="form-group form-group--small">
                                <label for="location">Location/Address</label>

                                <div class="form-group__input">
                                    <input v-model="formData.user.location" id="location" type="text" name="location">
                                </div>
                            </div>

                            <div class="form-group form-group--small">
                                <label for="website">Website</label>

                                <div class="form-group__input">
                                    <input v-model="formData.user.website" id="website" type="text" name="website">
                                </div>
                            </div>
                        </div>

                        <div class="mb-12">
                            <profile-social :user-socials="formData.socials" :social-options="formData.social_options" @update="updateSocials"></profile-social>
                        </div>
                    </div>

                    <div class="col w-full lg:w-2/3 xl:w-7/10">

                        <div class="mb-12" v-if="formData.user.user_role === 'applicant'">
                            <profile-work-experience :user-work-experiences="formData.work_experiences" @update="updateWorkExperiences"></profile-work-experience>
                        </div>

                        <div class="mb-12" v-if="formData.user.user_role === 'applicant'">
                            <profile-education :user-educations="formData.educations" @update="updateEducations"></profile-education>
                        </div>

                        <div class="mb-12" v-if="formData.user.user_role === 'employer'">
                            <profile-company-details v-bind:formData="formData" :user-company-details="formData.company_details" @update="updateCompanyDetails"></profile-company-details>
                            <profile-company-videos :user-company-videos="formData.company_videos" @update="updateCompanyVideos"></profile-company-videos>
                        </div>

                        <div class="mb-12">
                            <profile-values v-bind:formData="formData" :user-values="formData.personal_values" @update="updateValues"></profile-values>
                        </div>

                        <div class="mb-12" v-if="formData.user.user_role === 'applicant'">
                            <profile-job-preferences v-bind:formData="formData" :user-job-preferences="formData.job_preferences" @update="updateJobPreferences" @form-data="formDataJobPreferences"></profile-job-preferences>
                        </div>
                    </div>

                    <div class="col w-full">
                        <div class="flex -mx-2 justify-end">
                            <div class="flex-initial px-2">
                                <a class="btn" href="/dashboard">Cancel</a>
                            </div>
                            <div class="flex-initial px-2">
                                <button class="btn-green" type="submit" name="button">Save Changes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
export default {
  props: ['data', 'assetPath'],
  data() {
    return {
      errors: [],
      isRunning: false,
      image: false,
      imageChanged: false,
      formData: this.data,
    };
  },
  mounted() {
    const searchParams = new URLSearchParams( window.location.search );
    if ( searchParams.get( 'saved' ) === 'true' ) {
      $( '.saved-banner' ).removeClass( 'hidden' );
      setTimeout( () => {
        $( '.saved-banner' ).fadeOut();
      }, '5000' );
      window.history.pushState( {}, document.title, '/profile' );
    }

    if ( !$( 'input[name=hide_profile]:checked' ).is( ':checked' ) ) {
      $( '.hide-profile' ).hide();
    }

    $( 'input[name=hide_profile]' ).on( 'change', function() {
      if ( this.checked ) {
        $( '.hide-profile' ).show();
      } else {
        $( '.hide-profile' ).hide();
      }
    } );
  },
  methods: {
    toggleOpenToWork: function( index ) {
      if ( !this.formData.user_open_to_work ) {
        this.formData.job_preferences[0].open_to_work = 1;
      } else {
        this.formData.job_preferences[0].open_to_work = 0;
      }
    },
    handleSubmit() {
      const _this = this;

      if ( this.isRunning === false ) {
        // Validate inputs
        _this.validateInputs();

        if ( _this.errors.length === 0 ) {
          // Submit form
          axios.post( '/api/profile/update', {
            data: _this.formData,
          } )
              .then( ( response ) => {
                if ( _this.imageChanged ) {
                  axios.post( '/api/media/save-profile-image', {
                    image: _this.image,
                  } )
                      .then( ( response ) => {
                        window.location.href = '/profile?saved=true';
                      } );
                } else {
                  window.location.href = '/profile?saved=true';
                }
              } ).catch( ( error ) => {
                console.log( error );
                _this.isRunning = false;
              } );
        } else {
          _this.isRunning = false;
        }
      }
    },
    validateInputs() {
      //
    },
    updatePhoto( photo ) {
      this.image = photo;
      this.imageChanged = true;
    },
    updateSocials( socials ) {
      this.formData.socials = socials;
    },
    updateWorkExperiences( workExperiences ) {
      this.formData.work_experiences = workExperiences;
    },
    updateEducations( educations ) {
      this.formData.educations = educations;
    },
    updateValues( values ) {
      this.formData.values = values;
    },
    updateJobPreferences( jobPreferences ) {
      this.formData.job_preferences = jobPreferences;
    },
    updateCompanyDetails( formData ) {
      this.formData.company_details = formData;
    },
    updateCompanyVideos( companyVideos ) {
      this.formData.company_videos = companyVideos;
    },
    formDataJobPreferences( formData ) {
      this.formData.options = formData;
    },
  },
};
</script>
