<template>
    <div>
      <div class="find-jobs__filter_checkbox_group_label">Location</div>
      <div class="col mx-4"><input class="mb-4 pl-6 w-full border border-gray-300 rounded-xs h-12" v-model="userZip" placeholder="Enter ZIP code" type="text" name="search-filter-zip-code"/></div>
      <label for="search-filter-zip-code" class="sr-only">Zip Code</label>
      <div class="find-jobs__filter_checkbox_group">

        <div><input v-model="jobZipCode" id="find-jobs__filter_location-60" name="find-jobs__filter_location" value="10" type="radio"/><label for="find-jobs__filter_location-60">Within 10 miles</label></div>
        <div><input v-model="jobZipCode" id="find-jobs__filter_location-80" name="find-jobs__filter_location" value="25" type="radio"/><label for="find-jobs__filter_location-80">Within 25 miles</label></div>
        <div><input v-model="jobZipCode" id="find-jobs__filter_location-100" name="find-jobs__filter_location" value="50" type="radio"/><label for="find-jobs__filter_location-100">Within 50 miles</label></div>
        <div><input v-model="jobZipCode" id="find-jobs__filter_location-120" name="find-jobs__filter_location" value="100" type="radio"/><label for="find-jobs__filter_location-120">Within 100 miles</label></div>

      </div>
    </div>
</template>

<script>
export default {
  props: ['jobTypes'],
  data() {
    return {
      userZip: [],
      jobZipCode: [],
      year: new Date().getFullYear(),
    };
  },
  methods: {

  },
  watch: {
    jobZipCode( ) {
      this.$emit( 'update', this.jobZipCode );
    },
    userZip( ) {
      this.$emit( 'zipper', this.userZip );
    },
  },
};
</script>
