<template>
    <form method="POST" class="form-skin" @submit.prevent="handleSubmit">
        <div class="saved-banner absolute right-0 w-20 h-8 bg-green text-white flex justify-center my-auto text-xl hidden">Saved!</div>
        <div class="mb-5">
            <div class="row items-center">
                <div class="col w-full sm:w-auto mb-3 sm:mb-0">
                    <h1 v-if="formData.resume === false" class="hdg-4">New Resume</h1>
                    <h1 v-else class="hdg-4 mb-3 lg:mb-0">Edit Resume</h1>
                </div>
                <div class="col w-full sm:w-auto ml-auto">
                    <div class="flex -mx-2 flex-wrap">
                        <div v-if="formData.resume === false" class="flex-initial px-2 py-1">
                            <button type="submit" name="button" class="btn-save">Save Resume</button>
                        </div>
                        <div v-if="formData.resume !== false" class="flex-initial px-2 py-1">
                            <a :href="'/resume/' + formData.resume.id + '/preview'" class="btn-preview">Preview</a>
                        </div>
                        <div v-if="formData.resume !== false" class="flex-initial px-2 py-1">
                            <button type="button" name="button" class="btn-share js-init-popup" :data-modal="'#share-' + formData.resume.id">Share<svg class="icon icon-arrow-3"><use xlink:href="#icon-arrow-3"></use></svg></button>
                            <share-resume :popup-id="'share-' + formData.resume.id" :cover-letters="formData.cover_letters" :resume="formData.resume"></share-resume>
                        </div>
                        <div v-if="formData.resume !== false" class="flex-initial px-2 py-1">
                            <button type="submit" name="button" class="btn-save">Update</button>
                        </div>
                        <div v-if="formData.resume !== false" class="flex-initial px-2 py-1">
                            <button @click="handleDelete( formData.resume.id )" type="button" name="button" class="btn-delete"><svg class="icon icon-trash"><use xlink:href="#icon-trash"></use></svg>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group">
            <label for="title" class="sr-only">Resume Title</label>

            <div class="form-group__input">
                <input v-model="formData.title" id="title" type="text" class="" name="title" placeholder="Resume Title" required autofocus>
            </div>
        </div>

        <div class="row">
            <div class="col w-full">
                <div class="box box--lg">
                    <div class="row">
                        <div class="col w-full lg:w-1/3 xl:w-3/10">

                            <div class="mb-8">
                                <div class="form-photo">
                                    <div v-if="formData.photo" class="profile__image" :style="{ backgroundImage: 'url(\'' + assetPath + 'uploads/' + formData.photo.path + '\')' }"></div>
                                    <div v-else class="profile__image" :style="{ backgroundImage: 'url(\'' + assetPath + 'img/default_profile.png\')' }"></div>
                                </div>
                            </div>

                            <div class="mb-12">
                                <p class="hdg-6 mb-4">Contact Info</p>

                                <div class="form-group form-group--small">
                                    <label for="first_name">First Name</label>

                                    <div class="form-group__input">
                                        <input v-model="formData.first_name" id="first_name" type="text" class="" name="first_name" required disabled>
                                    </div>
                                </div>

                                <div class="form-group form-group--small">
                                    <label for="last_name">Last Name</label>

                                    <div class="form-group__input">
                                        <input v-model="formData.last_name" id="last_name" type="text" class="" name="last_name" required disabled>
                                    </div>
                                </div>

                                <div class="form-group form-group--small">
                                    <label for="job_title">Job Title</label>

                                    <div class="form-group__input">
                                        <input v-model="formData.job_title" id="job_title" type="text" class="" name="job_title">
                                    </div>
                                </div>

                                <div class="form-group form-group--small">
                                    <label for="email">Email</label>

                                    <div class="form-group__input">
                                        <input v-model="formData.email" id="email" type="email" class="" name="email" required>
                                    </div>
                                </div>

                                <div class="form-group form-group--small">
                                    <label for="location">Location/Address</label>

                                    <div class="form-group__input">
                                        <input v-model="formData.location" id="location" type="text" class="" name="location" required>
                                    </div>
                                </div>

                                <div class="form-group form-group--small">
                                    <label for="website">Website</label>

                                    <div class="form-group__input">
                                        <input v-model="formData.website" id="website" type="text" class="" name="website">
                                    </div>
                                </div>
                            </div>

                            <div class="mb-12">
                                <resume-social :user-socials="formData.socials" :social-options="formData.social_options" @update="updateSocials"></resume-social>
                            </div>

                            <div class="mb-12">
                                <resume-attachment :resume-attachments="formData.attachments" @update="updateAttachments"></resume-attachment>
                            </div>
                        </div>

                        <div class="col w-full lg:w-2/3 xl:w-7/10">
                            <div class="mb-12">
                                <resume-summary :resume-summary="formData.summary" @update="updateSummary"></resume-summary>
                            </div>

                            <div class="mb-12">
                                <p class="hdg-6 mb-4">What Job Details Are You Looking For?</p>

                                <div class="mb-8">
                                    <div class="relative box bg-bgblue mb-8">
                                        <div class="form-group">
                                            <label for="job_type">Job Type</label>

                                            <div class="form-group__input form-group__input--icon">
                                                <select v-model="formData.details.job_type" id="job_type" class="" name="job_type" required>
                                                    <option value=""></option>
                                                    <option v-for="(option, optionIndex) in formData.job_type_options" :key="optionIndex" :value="option.id">{{ option.name }}</option>
                                                </select>
                                                <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="job_level">Job Level</label>

                                            <div class="form-group__input form-group__input--icon">
                                                <select v-model="formData.details.job_level" id="job_level" class="" name="job_level" required>
                                                    <option value=""></option>
                                                    <option v-for="(option, optionIndex) in formData.job_level_options" :key="optionIndex" :value="option.id">{{ option.name }}</option>
                                                </select>
                                                <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="industry">Industry</label>

                                            <div class="form-group__input form-group__input--icon">
                                                <select v-model="formData.details.industry" id="industry" class="" name="industry" required>
                                                    <option value=""></option>
                                                    <option v-for="(option, optionIndex) in formData.industry_options" :key="optionIndex" :value="option.id">{{ option.name }}</option>
                                                </select>
                                                <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <label for="employment_type">Employment Type</label>

                                            <div class="form-group__input form-group__input--icon">
                                                <select v-model="formData.details.employment_type" id="employment_type" class="" name="employment_type" required>
                                                    <option value=""></option>
                                                    <option v-for="(option, optionIndex) in formData.employment_type_options" :key="optionIndex" :value="option.id">{{ option.name }}</option>
                                                </select>
                                                <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mb-12">
                                <p class="hdg-6 mb-4">Help Employers Find You</p>

                                <div class="mb-8">
                                    <div class="relative box bg-bgblue mb-8">

                                        <div class="form-group">
                                            <label for="minimum_salary">Minimum Salary</label>

                                            <div class="form-group__input form-group__input--icon">
                                                <select v-model="formData.minimum_salary" id="minimum_salary" class="" name="minimum_salary" required>
                                                    <option value=""></option>
                                                    <option value="0">$0</option>
                                                    <option value="5000">$5,000</option>
                                                    <option value="10000">$10,000</option>
                                                    <option value="15000">$15,000</option>
                                                    <option value="20000">$20,000</option>
                                                    <option value="25000">$25,000</option>
                                                    <option value="30000">$30,000</option>
                                                    <option value="35000">$35,000</option>
                                                    <option value="40000">$40,000</option>
                                                    <option value="45000">$45,000</option>
                                                    <option value="50000">$50,000</option>
                                                    <option value="55000">$55,000</option>
                                                    <option value="60000">$60,000</option>
                                                    <option value="65000">$65,000</option>
                                                    <option value="70000">$70,000</option>
                                                    <option value="75000">$75,000</option>
                                                    <option value="80000">$80,000</option>
                                                    <option value="85000">$85,000</option>
                                                    <option value="90000">$90,000</option>
                                                    <option value="95000">$95,000</option>
                                                    <option value="100000">$100,000</option>
                                                    <option value="110000">$110,000</option>
                                                    <option value="120000">$120,000</option>
                                                    <option value="130000">$130,000</option>
                                                    <option value="140000">$140,000</option>
                                                    <option value="150000">$150,000</option>
                                                    <option value="160000">$160,000</option>
                                                    <option value="170000">$170,000</option>
                                                    <option value="180000">$180,000</option>
                                                    <option value="190000">$190,000</option>
                                                    <option value="200000">$200,000+</option>
                                                </select>
                                                <svg class="icon icon-arrow"><use xlink:href="#icon-arrow"></use></svg>
                                            </div>
                                        </div>

                                        <div class="form-check mb-5 mt-8">
                                            <input type="checkbox" name="willing_to_relocate" id="willing_to_relocate" v-model="formData.willing_to_relocate">

                                            <label for="willing_to_relocate">Willing to relocate</label>
                                        </div>

                                        <div v-if="! formData.willing_to_relocate" class="flex flex-wrap -mx-2">
                                            <div class="flex-initial w-full xl:w-1/2 px-2">

                                                <div class="form-group">
                                                    <label for="zip_code">Zip Code</label>

                                                    <div class="form-group__input">
                                                        <input id="zip_code" type="text" name="zip_code" v-model="formData.zip_code" required>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="flex-initial w-full xl:w-1/2 px-2">

                                                <div class="form-group">
                                                    <label for="commute_radius">Commute Radius (miles)</label>

                                                    <div class="form-group__input">
                                                        <input id="commute_radius" type="number" step="1" min="0" name="commute_radius" v-model="formData.commute_radius" required>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="mb-12">
                                <resume-work-experience :user-work-experiences="formData.work_experiences" :work-experience-options="formData.work_experience_options" @update="updateWorkExperiences"></resume-work-experience>
                            </div>

                            <div class="mb-12">
                                <resume-education :user-educations="formData.educations" :education-options="formData.education_options" @update="updateEducations"></resume-education>
                            </div>

                            <div class="mb-12">
                                <resume-portfolio :resume-portfolios="formData.portfolios" :asset-path="assetPath" @update="updatePortfolios"></resume-portfolio>
                            </div>

                            <div class="mb-12">
                                <resume-additional :resume-additionals="formData.additionals" :asset-path="assetPath" @update="updateAdditionals"></resume-additional>
                            </div>

                            <div class="mb-12">
                                <resume-video :resume-videos="formData.videos" :asset-path="assetPath" @update="updateVideos"></resume-video>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <div class="row items-center">
                <div class="col w-full sm:w-auto mb-3 sm:mb-0">

                </div>
                <div class="col w-full sm:w-auto ml-auto">
                    <div class="flex -mx-2 flex-wrap">
                        <div v-if="formData.resume === false" class="flex-initial px-2 py-1">
                            <button type="submit" name="button" class="btn-save">Save Resume</button>
                        </div>
                        <div v-if="formData.resume !== false" class="flex-initial px-2 py-1">
                            <a :href="'/resume/' + formData.resume.id + '/preview'" class="btn-preview">Preview</a>
                        </div>
                        <div v-if="formData.resume !== false" class="flex-initial px-2 py-1">
                            <button type="button" name="button" class="btn-share js-init-popup" :data-modal="'#share-' + formData.resume.id">Share<svg class="icon icon-arrow-3"><use xlink:href="#icon-arrow-3"></use></svg></button>
                            <share-resume :popup-id="'share-' + formData.resume.id" :cover-letters="formData.cover_letters" :resume="formData.resume"></share-resume>
                        </div>
                        <div v-if="formData.resume !== false" class="flex-initial px-2 py-1">
                            <button type="submit" name="button" class="btn-save">Update</button>
                        </div>
                        <div v-if="formData.resume !== false" class="flex-initial px-2 py-1">
                            <button @click="handleDelete( formData.resume.id )" type="button" name="button" class="btn-delete"><svg class="icon icon-trash"><use xlink:href="#icon-trash"></use></svg>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </form>
</template>

<script>
export default {
  props: ['data', 'assetPath'],
  data() {
    return {
      action: '',
      errors: [],
      isRunning: false,
      formData: this.data,
    };
  },
  mounted() {
    const searchParams = new URLSearchParams( window.location.search );
    if ( searchParams.get( 'saved' ) === 'true' ) {
      $( '.saved-banner' ).removeClass( 'hidden' );
      setTimeout( () => {
        $( '.saved-banner' ).fadeOut();
      }, '5000' );
      window.history.pushState( {}, document.title, '/profile' );
    }
    if ( this.formData.resume ) {
      this.action = '/api/resume/update';
    } else {
      this.action = '/api/resume/create';
    }
  },
  methods: {
    handleSubmit() {
      const _this = this;

      if ( this.isRunning === false ) {
        // Validate inputs
        _this.validateInputs();

        if ( _this.errors.length === 0 ) {
          // Submit form
          axios.post( _this.action, {
            data: _this.formData,
          } )
              .then( ( response ) => {
                // console.log( response );
                window.location.href = '/resumes/' + response.data.id + '/edit?saved=true';
              } ).catch( ( error ) => {
                console.log( error );
                _this.isRunning = false;
              } );
        } else {
          _this.isRunning = false;
        }
      }
    },
    validateInputs() {
      //
    },
    handleDelete( id ) {
      const _this = this;

      if ( this.isRunning === false ) {
        // Validate inputs
        _this.validateInputs();

        if ( _this.errors.length === 0 ) {
          // Submit form
          axios.delete( '/resumes/' + id )
              .then( ( response ) => {
                window.location.href = '/resumes';
              } ).catch( ( error ) => {
                console.log( error );
                _this.isRunning = false;
              } );
        } else {
          _this.isRunning = false;
        }
      }
    },
    updateSocials( socials ) {
      this.formData.socials = socials;
    },
    updateAttachments( attachments ) {
      this.formData.attachments = attachments;
    },
    updateSummary( summary ) {
      this.formData.summary = summary;
    },
    updateWorkExperiences( workExperiences ) {
      this.formData.work_experiences = workExperiences;
    },
    updateEducations( educations ) {
      this.formData.educations = educations;
    },
    updatePortfolios( portfolios ) {
      this.formData.portfolios = portfolios;
    },
    updateAdditionals( additionals ) {
      this.formData.additionals = additionals;
    },
    updateVideos( videos ) {
      this.formData.videos = videos;
    },
    updateValues( values ) {
      this.formData.values = values;
    },
  },
};
</script>
