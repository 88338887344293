<template>
    <div>
        <p class="hdg-6 mb-4">Attachments</p>

        <div v-if="attachments.length > 0" class="mb-8">
            <div v-for="(attachment, attachmentIndex) in attachments" :key="attachmentIndex">

                <div class="form-group form-group--small">
                    <!-- <label :for="'attachment_url_' + attachmentIndex">{{ attachment.path.replace(/^.*[\\\/]/, '') }}</label> -->

                    <div class="form-group__input form-group__input--icon">
                        <!-- <input :id="'attachment_url_' + attachmentIndex" type="text" class="" :name="'attachment_url_' + attachmentIndex" v-model="attachment.path" required> -->
                        <button class="form-group__file-name" type="button" @click="clickFileInput( attachmentIndex )">
                            <span v-if="!attachment.media_title">Upload a File</span>
                            <span>{{ attachment.media_title.replace(/^.*[\\\/]/, '') }}</span>
                        </button>
                        <svg @click="removeAttachment( attachmentIndex )" class="block icon icon-minus"><use xlink:href="#icon-minus"></use></svg>
                    </div>

                    <div class="hidden">
                        <input :id="'attachment_url_' + attachmentIndex" :name="'attachment_url_' + attachmentIndex" type="file" @change="onFileChange( $event, attachmentIndex )" />
                    </div>
                </div>

            </div>
        </div>

        <div v-if="resumeAttachments.length > 0" class="relative">
            <button type="button" name="add_attachment" class="popover__button btn-dashed" data-toggle-class="is-open" data-toggle-outside data-toggle-target="#add-attachment-popup"><svg class="pointer-events-none icon icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg>Add Attachment</button>

            <div class="popover__popup hidden absolute left-0 bottom-100 w-full" id="add-attachment-popup" role="navigation">
                <div class="popover__popup-box">
                    <div>
                        <button @click="addAttachment( false )" data-toggle-trigger-off class="flex w-full items-center text-left text-gray-500 text-sm font-medium hover:text-blue py-3" type="button">Add New Attachment<svg class="ml-auto pointer-events-none block text-lg icon mr-2 icon-plus-1"><use xlink:href="#icon-plus-1"></use></svg></button>
                    </div>

                    <div v-for="(attachment, attachmentIndex) in resumeAttachments" :key="attachmentIndex">
                        <button v-if="!isInUse( attachment.id )" :data-id="attachmentIndex" @click="addAttachment( attachmentIndex )" data-toggle-trigger-off class="flex w-full items-center text-left text-gray-500 text-sm font-medium hover:text-blue py-3 border-t" type="button">{{ attachment.media_title }}</button>
                    </div>
                </div>
            </div>
        </div>

        <button v-else @click="addAttachment( false )" type="button" name="add_attachment" class="btn-dashed"><svg class="icon icon-paperclip"><use xlink:href="#icon-paperclip"></use></svg>Add Attachment</button>

    </div>
</template>

<script>
export default {
  props: ['resumeAttachments'],
  data() {
    return {
      attachments: [],
      year: new Date().getFullYear(),
    };
  },
  mounted() {
    this.setAttachments();
  },
  methods: {
    setAttachments: function() {
      if ( this.resumeAttachments.length > 0 ) {
        this.attachments = Object.assign( [], this.resumeAttachments );
      } else {
        // this.addAttachment( false );
      }
    },
    addAttachment: function( id ) {
      if ( id !== false ) {
        this.attachments.push(
            Object.assign( {}, this.resumeAttachments[id] )
        );
      } else {
        this.attachments.push(
            {
              id: false,
              media_title: '',
              media_path: '',
            }
        );
      }
    },
    removeAttachment: function( index ) {
      this.attachments.splice( index, 1 );
    },
    isInUse: function( id ) {
      let isInUse = false;
      if ( id ) {
        $.each( this.attachments, function( index, attachment ) {
          if ( attachment.id === id ) {
            isInUse = true;
          }
        } );
      }
      return isInUse;
    },
    onFileChange( e, attachmentIndex ) {
      const files = e.target.files || e.dataTransfer.files;

      if ( !files.length ) {
        return;
      }
      this.createFile( files[0], attachmentIndex );
    },
    createFile( file, attachmentIndex ) {
      const reader = new FileReader();
      const _this = this;
      reader.onload = ( e ) => {
        _this.attachments[attachmentIndex].media_path = e.target.result;
        _this.attachments[attachmentIndex].media_title = file.name;
      };
      reader.readAsDataURL( file );
    },
    clickFileInput: function( attachmentIndex ) {
      $( '#attachment_url_' + attachmentIndex ).click();
    },
  },
  watch: {
    attachments() {
      this.$emit( 'update', this.attachments );
    },
  },
};
</script>
